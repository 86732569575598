import { Component, OnInit, ViewChild } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SubmissionsService } from '../../../services/submissions.service';

import 'fabric';
import { AuthService } from 'services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from "@angular/material";
// import { document } from 'ngx-bootstrap/utils/facade/browser';
// declare const fabric: any;

@Component({
  selector: 'admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})

export class AdminHomeComponent {
  private loading: boolean = false;
  displayedColumns: string[] = ['Quotes', 'Date', 'Total Cost','Status'];
  displayedColumns2: string[] = ['key', 'name', 'date'];
  quotesList: any;
  stats:any;
  submissions:any;
  constructor(private subService: SubmissionsService,
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {
    this.getAllDashbord();
  }

  getAllDashbord(){
    this.loading = true;
    this.subService.getDashbordData().subscribe(res => {
      console.log("res",res.json());
      this.quotesList = [];
      this.stats = res.json().stats;
      this.quotesList = res.json().quotes;
      this.submissions = res.json().designSubmissions;
      this.quotesList = this.quotesList.sort((a, b) => Date.parse(b.data.date) - Date.parse(a.data.date));
      // this.toast.success('Quantity Updated Succesfully', '', { positionClass: 'toast-top-right' });
      this.loading = false;
    });
  }

  goToQuotesDetails(element){
    // alert("Order details see goToOrderDetails function call")
    // TODO Vishal pass order id as param 
    this.router.navigate(['/admin/my-quotes-details'],{ queryParams: { quotesId: element._id} })
  }
  
}
