import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { EditorComponent } from "../editor/editor.component";
import { Ilayer, Icolorselected } from "app/models/jersey";
// import { Color } from "fabric";
declare var Snap: any;
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import $ from "jquery";
import { element } from "protractor";
const rgbHex = require('rgb-hex');
import { Ng5SliderModule, Options } from 'ng5-slider';


@Component({
  selector: 'color-selector',
  templateUrl: '../../app/color-implementation/color.component.html',
  styleUrls: ['../../app/editor/editor.component.css', '../../app/color-implementation/color.component.css']
})
export class colorComponent {
  @Input() colorsList: any[];
  @Input() patternsList: any[]

  @Input() layers: any;
  @Input() selected_slide: any;
  @Input('appliedColors') selectedColorsList: Icolorselected[];
  @Output() onselectcolor: EventEmitter<any> = new EventEmitter();
  @Output() onhovercolor: EventEmitter<any> = new EventEmitter();
  @Output() onleavecolor: EventEmitter<any> = new EventEmitter();
  @Output() handelLoading: EventEmitter<any> = new EventEmitter();

  isExpaned: boolean;
  selectedLayer: any;

  public isSelectedcolor: any;
  public isSelectedcolorGrad1: any;
  public isSelectedcolorGrad2: any;

  public colorselected: any;
  public colorselectedGrad1: any;
  public colorselectedGrad2: any;
  public gradSelected: any;


  public colornameselected: any;
  public color1nameselectedforGrad: any;
  public color2nameselectedforGrad: any;
  public patternnameselected: undefined;
  public patternImageselected: undefined;
  public patternImageselectedForPre: undefined;


  changeTab: boolean = true;
  changeTabForGrad: boolean = false;
  editpatterFlag: boolean = true;
  patternLength: any;
  slidervalue: number = 50;
  heightPattern:number = 1;
  simpleOptions: Options = {
    floor: 0,
    ceil: 100
  };
  simplePatternOptions:Options = {
    floor: 1,
    ceil: 100
  };
  private loading: boolean = false;
  constructor(
    private toastr: ToastrService,
  ) {
  }

  ngAfterViewInit() {
    this.isExpaned = true;
    try {
      if (this.layers) {
        var arr = [];
        if (this.selected_slide == 1 && this.layers.color1) {
          // arr = this.getSelectedColor('color1');
        } else if (this.selected_slide == 2 && this.layers.color2) {
          // arr = this.getSelectedColor('color2');
        }
        if (arr[0]) {
          setTimeout(() => {
            this.sendcolordatatoEditor(event, this.layers, arr[0]);
          }, 10);
        }
      }
    } catch (error) {

    }
  }

  ngOnInit() {

  }

  getSelectedColor(key) {
    return this.colorsList.filter(d => {
      // d["is_show"]=false;
      return d.colorCode == this.layers[key].colorCode || d.colorName == this.layers[key].colorName;
    });
  }

  sendcolordatatoEditor(event, layer, color) {
    this.remove(layer.layerID);
    this.removGradient(layer.layerID);
    layer['color' + this.selected_slide] = color;
    this.isSelectedcolor = layer;
    this.colorselected = color;

    this.colornameselected = color.colorName;
    this.onselectcolor.emit(event);

    this.onselectcolor.emit(layer);

    this.onselectcolor.emit(color);
    this.patternnameselected = undefined;
    this.patternImageselected = undefined;
    this.patternImageselectedForPre = undefined;

    let id, idPreview;
    if (this.selected_slide == 1) {
      id = layer.layerID + 'homepatSvg';
      idPreview = layer.layerID + 'homepatSvgForPre';
    }
    else {
      id = layer.layerID + 'awaypatSvg';
      idPreview = layer.layerID + 'awaypatSvgForPre';

    }
    this.removeLocalStorage(id);
    this.removeLocalStorageForPre(idPreview);
  }
  jerseycolorapplyonhover(e, layer) {
    this.onhovercolor.emit(e);
    this.onhovercolor.emit(layer);
  }

  displayColorNameonHover(color) {
    this.colornameselected = color.colorName;
  }

  onMouseOut(colorleft, layer: Ilayer) {
    for (let i = 0; i < this.selectedColorsList.length; i++) {
      if (this.selectedColorsList[i].layer === layer.layerID) {
        this.colornameselected = this.selectedColorsList[i].colorName;
        break;
      }
      else
        this.colornameselected = "";
    }
    this.onleavecolor.emit({
      old: colorleft,
      layer: layer
    });
    this.onleavecolor.emit(layer);
  }

  toggleSection(l) {
    this.editpatterFlag = true;
    this.changeTab = true;
    this.isExpaned = !this.isExpaned;
    if (this.selectedLayer == l.layerID && document.getElementById('ColorPattern-' + l.layerID).style.display != "none") {
      document.getElementById('ColorPattern-' + l.layerID).style.display = "none";
      document.getElementById(l.layerID + 'up').style.display = "none";
      document.getElementById(l.layerID + 'down').style.display = "block";
      return;
    } else {
      this.selectedLayer = l.layerID;
      let ids = <any>document.querySelectorAll('*[id^="ColorPattern-"]');
      ids.forEach(element => {
        if (element.id.includes(l.layerID)) {
          element.style.display = "";
          document.getElementById(l.layerID + 'up').style.display = "block";
          document.getElementById(l.layerID + 'down').style.display = "none";
        }
        else {
          element.style.display = "none";
          let temp = element.id.split('ColorPattern-')[1]
          document.getElementById(temp + 'up').style.display = "none";
          document.getElementById(temp + 'down').style.display = "block";
        }

      });
    }
  }


  backgroundImage(path) {
    return `url(${path.colorCodeForPre})`;
  }

  remove(id) {
    if (this.selected_slide == 1)
      id = 'Pattern-Home-' + id;
    else
      id = 'Pattern-Away-' + id;
    let ele = document.getElementById(id);
    if (ele)
      ele.remove();
  }


  sendpatterndatatoEditor(event, layer, pattern) {
    if (layer)
      // this.checkCondition(layer, pattern);
      this.addingDefTag(layer.layerID, pattern);
    this.removGradient(layer.layerID);

    let tmpPattern = { ...pattern };
    if (this.selected_slide == 1)
      tmpPattern.colorCode = `url(#Pattern-Home-${layer.layerID})`;
    else
      tmpPattern.colorCode = `url(#Pattern-Away-${layer.layerID})`;
    layer['color' + this.selected_slide] = tmpPattern;

    this.isSelectedcolor = layer;
    this.colorselected = tmpPattern;

    this.patternnameselected = tmpPattern.patternName;
    let id, idForPre;
    if (this.selected_slide == 1) {
      id = layer.layerID + 'homepatSvg';
      idForPre = layer.layerID + 'homepatSvgForPre';
    }
    else {
      id = layer.layerID + 'awaypatSvg';
      idForPre = layer.layerID + 'awaypatSvgForPre';
    }

    this.patternImageselected = Snap(`#${id}`).toDataURL();
    this.patternImageselectedForPre = Snap(`#${idForPre}`).toDataURL();

    this.onselectcolor.emit(event);
    this.colornameselected = undefined;

    this.onselectcolor.emit(layer);

    this.onselectcolor.emit(tmpPattern);
    this.editpatterFlag = false;
    let patid, patidForPre;
    if (this.selected_slide == 1) {
      patid = layer.layerID + 'homepatSvg';
      patidForPre = layer.layerID + 'homepatSvgForPre';
    }
    else {
      patid = layer.layerID + 'awaypatSvg';
      patidForPre = layer.layerID + 'awaypatSvgForPre';
    }
    localStorage.setItem(patid, pattern.colorCode);
    localStorage.setItem(patidForPre, pattern.colorCodeForPre);
    this.loadSvg(layer, pattern.colorCode);
    this.loadSvgForPre(layer, pattern.colorCodeForPre);

  }
  hidePattern(layer, flag) {
  }

  patternColoreSelected(number, color, layer, id) {
    if (this.selected_slide == 1) {
      id = id + 'homepatSvg';
    }
    else {
      id = id + 'awaypatSvg';
    }
    let elementID = this.patternLength[number];
    let addColor = <any>document.querySelectorAll('#' + elementID.id)
    addColor.forEach(element => {
      element.style.fill = color;
    });

    let path = Snap(`#${id}`).toDataURL();
    let pattern = {
      colorCode: path
    }
    this.handelLoading.emit({ 'flag': false });
  }

  loadSvgBefor(layer) {
    this.changeTab = false;
    let num = <any>document.querySelectorAll('*[id^="Color-"]');
    let id, idForPre;
    if (this.selected_slide == 1) {
      id = <any>document.getElementById(layer.layerID + 'homepatSvg');
      idForPre = <any>document.getElementById(layer.layerID + 'homepatSvgForPre');
    }
    else {
      id = <any>document.getElementById(layer.layerID + 'awaypatSvg');
      idForPre = <any>document.getElementById(layer.layerID + 'awaypatSvgForPre');
    }
    if ((num.length == 0 && id && !id.hasChildNodes())) {
      id.innerHTML = "";
      idForPre.innerHTML = "";
      let idtocheck, idtocheckForPre;
      if (this.selected_slide == 1) {
        idtocheck = layer.layerID + 'homepatSvg';
        idtocheckForPre = layer.layerID + 'homepatSvgForPre';

      }
      else {
        idtocheck = layer.layerID + 'awaypatSvg';
        idtocheckForPre = layer.layerID + 'awaypatSvgForPre';
      }
      this.loadSvg(layer, localStorage.getItem(idtocheck));
      this.loadSvgForPre(layer, localStorage.getItem(idtocheckForPre));
    }
  }

  loadSvg(layer, path) {

    let displayName;
    if (this.selected_slide == 1)
      displayName = layer.layerID + 'homepatSvg';
    else
      displayName = layer.layerID + 'awaypatSvg'
    let me = this;
    let snapclip = Snap(`#${displayName}`);
    if (snapclip)
      snapclip.clear();
    function onSVGLoadedClipArt(data) {
      snapclip.append(data);
      let code = document.getElementById(displayName).innerHTML;
      code=code.replace(/st0/g,'st-0');
      code=code.replace(/st1/g,'st-1');
      code=code.replace(/st2/g,'st-2');
      code=code.replace(/st3/g,'st-3');
      code=code.replace(/st4/g,'st-4');
      code=code.replace(/st5/g,'st-5');
      document.getElementById(displayName).innerHTML=code;
      // me.patternLength = <any>document.querySelectorAll('*[id^="Color-"]');
      me.patternLength = [];
      let tempArray = [];
      let temp = <any>document.querySelectorAll('*[id^="Color-"]');
      temp.forEach((element, index) => {
        tempArray.push(element);
      })
      me.patternLength = tempArray;
      if (temp[0] && temp[0].id) {
        let checkLength = document.querySelectorAll('#' + temp[0].id);
        if (checkLength.length > 1)
          me.patternLength = tempArray.splice(0, (tempArray.length) / 2);
      }
    }
    Snap.load(path, onSVGLoadedClipArt);

  }


  loadSvgForPre(layer, path) {

    let displayName;
    if (this.selected_slide == 1)
      displayName = layer.layerID + 'homepatSvgForPre';
    else
      displayName = layer.layerID + 'awaypatSvgForPre'
    let me = this;
    let snapclip = Snap(`#${displayName}`);
    if (snapclip)
      snapclip.clear();
    function onSVGLoadedClipArt(data) {
      snapclip.append(data);
      let code = document.getElementById(displayName).innerHTML;
      code=code.replace(/st0/g,'st-0');
      code=code.replace(/st1/g,'st-1');
      code=code.replace(/st2/g,'st-2');
      code=code.replace(/st3/g,'st-3');
      code=code.replace(/st4/g,'st-4');
      code=code.replace(/st5/g,'st-5');
      document.getElementById(displayName).innerHTML=code;
      // me.patternLength = <any>document.querySelectorAll('*[id^="Color-"]');
    }
    Snap.load(path, onSVGLoadedClipArt);
  }

  displayPatternNameonHover(pattern, layers) {
    this.addingDefTag(layers.layerID, pattern);
    this.patternnameselected = pattern.colorName;
  }

  changePatternSize(id,heightPattern){
    if (this.selected_slide == 1)
      id = 'Pattern-Home-' + id;
    else
      id = 'Pattern-Away-' + id;
    var a =  heightPattern + 215;
    var b =  heightPattern + 215;
    document.getElementById(id).children[0].setAttribute('width',a);
    document.getElementById(id).children[0].setAttribute('height',b);
  }
  addingDefTag(id, patterncode) {
    let width, height;
    width = this.heightPattern + 215;
    height = this.heightPattern + 215;
    let parentId = id;
    if (this.selected_slide == 1)
      id = 'Pattern-Home-' + id;
    else
      id = 'Pattern-Away-' + id;
    let tag = `<pattern id="${id}" patternUnits = "userSpaceOnUse" width = "${width}" height = "${height}" >
      <image href = "" x = "0" y = "0" width = "${width}" height = "${height}" />
        </pattern>`;
    let pattern;
    if (document.getElementById('svg') && document.getElementById('svg').children && document.getElementById('svg').children[1]) {
      pattern = <any>document.getElementById('svg').children[1].children;
      for (let item of pattern) {
        if (item.tagName == 'defs') {
          let conatin = <any>item.innerHTML;
          if (conatin.includes(id)) {
            let img = <any>document.getElementById(id).children[0];
            // console.log("patterncode.colorCode",patterncode.colorCode);
            // img.setAttribute("href", patterncode.colorCode);
            this.toDataURL(patterncode.colorCode, function(dataUrl) {
							img.setAttribute("href", dataUrl);
						});
            
          } else {
            item.innerHTML = item.innerHTML + tag;
            let img = <any>document.getElementById(id).children[0];
            // console.log("patterncode.colorCode",patterncode.colorCode);
            // img.setAttribute("href", patterncode.colorCode);
            this.toDataURL(patterncode.colorCode, function(dataUrl) {
							img.setAttribute("href", dataUrl);
						});
          }
        }
      }
    }
    setTimeout(() => {
      this.handelLoading.emit({ 'flag': false });
    }, 3000)

  }

  toDataURL(url, callback) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function() {
			var reader = new FileReader();
			reader.onloadend = function() {
				callback(reader.result);
			}
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}


  showPatterns(id, layer) {
    let snapclip = Snap(`#${id}`);
    if (snapclip)
      snapclip.clear();
    this.editpatterFlag = true;
  }

  remvePattern(layer) {
    this.remove(layer.layerID);
    this.patternnameselected = undefined;
    this.patternImageselected = undefined;
    this.patternImageselectedForPre = undefined;

  }
  addPatternColor(id, layer) {
    let idForPre;
    if (this.selected_slide == 1) {
      idForPre = id + 'homepatSvgForPre';
      id = id + 'homepatSvg';
    }
    else {
      idForPre = id + 'awaypatSvgForPre';
      id = id + 'awaypatSvg';
    }
    let path = Snap(`#${id}`).toDataURL();
    let pathForPre = Snap(`#${idForPre}`).toDataURL();
    this.handelLoading.emit({ 'flag': true });

    let displayNameFP;
    if (this.selected_slide == 1)
      displayNameFP = layer.layerID + 'homepatSvgForPre';
    else
      displayNameFP = layer.layerID + 'awaypatSvgForPre';
    localStorage.setItem(displayNameFP, pathForPre);
    // this.blobToFileForPre(pathForPre, layer, path);
    this.blobToFile(path, layer);
  }


  async	handleReaderLoaded(file, layer) {
    // this.toastr.warning("Plase wait while uploading pattern", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
    let storageRef = firebase.storage().ref();
    let uniqlogo = 'uploadedlogo' + (new Date().getTime());

    const fileData = await storageRef.child(`${'/uploaded-patterns'}/${uniqlogo}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        let pattern = {
          colorCode: downloadURL
        }

        let displayName;
        if (this.selected_slide == 1)
          displayName = layer.layerID + 'homepatSvg';
        else
          displayName = layer.layerID + 'awaypatSvg'
        localStorage.setItem(displayName, downloadURL);
        this.addingDefTag(layer.layerID, pattern);
        this.toastr.success('Pattern sucessfully uploaded', '', { positionClass: 'toast-top-right' });
      }).catch(error => {

        this.toastr.error("Faild to upload pattern. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
      });
  }



  async	handleReaderLoadedForPre(file, layer, path) {
    let storageRef = firebase.storage().ref();
    let uniqlogo = 'uploadedlogoforPre' + (new Date().getTime());

    const fileData = await storageRef.child(`${'/uploaded-patterns'}/${uniqlogo}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        let displayName;
        if (this.selected_slide == 1)
          displayName = layer.layerID + 'homepatSvgForPre';
        else
          displayName = layer.layerID + 'awaypatSvgForPre'
        localStorage.setItem(displayName, downloadURL);
        this.blobToFile(path, layer);

      }).catch(error => {
        this.toastr.error("Faild to upload pattern. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
      });
  }


  blobToFile(url, layer) {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        let uniqlogo = 'pattern' + (new Date().getTime());
        let file = new File([blob], uniqlogo, { type: blob.type });
        this.handleReaderLoaded(file, layer);
      });
  }

  blobToFileForPre(url, layer, path) {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        let uniqlogo = 'patternforPre' + (new Date().getTime());
        let file = new File([blob], uniqlogo, { type: blob.type });
        this.handleReaderLoadedForPre(file, layer, path);
      });
  }

  checkCondition(id) {
  }

  check(id, layer) {
    let num = <any>document.querySelectorAll('*[id^="Color-"]');
    let displayName, displayNameForPre;
    if (this.selected_slide == 1) {
      displayName = document.getElementById(layer.layerID + 'homepatSvg');
      displayNameForPre = document.getElementById(layer.layerID + 'homepatSvgForPre');
    }
    else {

      displayName = document.getElementById(layer.layerID + 'awaypatSvg');
      displayNameForPre = document.getElementById(layer.layerID + 'awaypatSvgForPre');

    }
    if ((num.length == 0 && displayName && !displayName.hasChildNodes())) {
      displayName.innerHTML = "";
      displayNameForPre.innerHTML = "";
      let sid, idForPre;
      if (this.selected_slide == 1) {
        sid = layer.layerID + 'homepatSvg';
        idForPre = layer.layerID + 'homepatSvgForPre';
      }
      else {
        sid = layer.layerID + 'awaypatSvg';
        idForPre = layer.layerID + 'awaypatSvgForPre';

      }

      this.loadSvg(layer, localStorage.getItem(sid));
      this.loadSvgForPre(layer, localStorage.getItem(idForPre));
    }
    if (document.getElementById(id) && document.getElementById(id).style.display == "none")
      return false;
    return true;

  }
  checkdisplayStatus(layer) {
    let id;
    if (this.selected_slide == 1)
      id = layer.layerID + 'homepatSvg';
    else
      id = layer.layerID + 'awaypatSvg';
    if (localStorage.getItem(id) && !this.changeTab) {
      return false
    }
    else if (this.changeTab || !this.patternnameselected)
      return true;
    else
      return false;
  }


  removeLocalStorage(id) {
    if (localStorage.getItem(id))
      localStorage.removeItem(id)
  }
  removeLocalStorageForPre(id) {
    if (localStorage.getItem(id))
      localStorage.removeItem(id)
  }


  homeAwayId(displayid) {
    let id;
    if (this.selected_slide == 1)
      id = displayid + 'homepatSvg';
    else
      id = displayid + 'awaypatSvg';
    return id;
  }

  homeAwayIdForPreview(displayid) {
    let id;
    if (this.selected_slide == 1)
      id = displayid + 'homepatSvgForPre';
    else
      id = displayid + 'awaypatSvgForPre';
    return id;
  }

  cnvrtRGBClrToHex(rgbClr) {
    let hex = rgbHex(rgbClr);
    return '#' + hex;
    // var rgbClr = rgbClr.split(',');
    // var r = rgbClr[0];
    // var g = rgbClr[1];
    // var b = rgbClr[2];
    // let temp = (r << 16 | g << 8 | b).toString(16).toLowerCase();
    // return '#' + temp;
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  checkStyle(value1, index1, name1, indexPattern, ddID) {
    let returnname = 'Select a color';
    this.colorsList.forEach((value, index) => {
      let fill;
      let code;
      if (this.patternLength[indexPattern]) {
        if (this.patternLength[indexPattern].style.fill) {
          fill = this.patternLength[indexPattern].style.fill;
          if (fill && fill.includes('rgb'))
            code = this.cnvrtRGBClrToHex(fill);
          // code = this.cnvrtRGBClrToHex(fill.split('rgb(')[1].split(')')[0])
        } else {
          let name = this.patternLength[indexPattern].getAttribute("class");
          fill = $(`.${name}`).css("fill");
          if (fill && fill.includes('rgb'))
            code = this.cnvrtRGBClrToHex(fill)
          // code = this.cnvrtRGBClrToHex(fill.split('rgb(')[1].split(')')[0])
        }
      }
      let ind;
      this.colorsList.forEach((element, index) => {
        if (code != undefined && code != 'undefined') {
          // if (element.colorCode.toUpperCase() == code.toUpperCase()) {
          if(element.colorCode){
            let stng = element.colorCode.toUpperCase().split('#')[1];
          
          let substrng = code.toUpperCase().split('#')[1];
          if (element.colorCode.toUpperCase() == code.toUpperCase()) {
            returnname = element.colorName;
            ind = index;
            if (document.getElementById(ddID))
              document.getElementById(ddID).style.backgroundColor = element.colorCode;
          }
        }
        }
      });
    });
    return returnname;
  }




  openUL(id) {
    let allDD = <any>document.querySelectorAll('*[id^="opencolorDropdown-"]');
    allDD.forEach(element => {
      if (element.id == id) {
        if (element.style.display == "" || element.style.display == 'none')
          element.style.display = "block";
        else
          element.style.display = "none";
      }
      else {
        element.style.display = "none";
      }
    });
  }

  openUlClose(id) {
    document.getElementById(id).style.display = "none";
  }


  displayColorNameonHoverForGrad(color, index) {
    if (index == 0)
      this.color1nameselectedforGrad = color.colorName;
    else
      this.color2nameselectedforGrad = color.colorName;

  }



  addingDefDradientTag(id, color, index, condition) {
    let width, height;
    width = 315;
    height = 315;
    let parentId = id;
    if (this.selected_slide == 1)
      id = 'Gradient-Home-' + id;
    else
      id = 'Gradient-Away-' + id;
    let tag;

    if (condition.includes('toptobottom')) {
      tag = `<linearGradient id="${id}" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" style="stop-opacity:1" />
      <stop offset="50%" style="stop-opacity:1" /></linearGradient>`;

    } else if (condition.includes('bottomtotop')) {
      tag = `<linearGradient id="${id}" x1="0%" y1="100%" x2="0%" y2="0%">
      <stop offset="0%" style="stop-opacity:1" />
      <stop offset="50%" style="stop-opacity:1" /></linearGradient>`;
    }

    else if (condition.includes('diagonal')) {
      tag = `<linearGradient id="${id}" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-opacity:1" />
        <stop offset="50%" style="stop-opacity:1" /></linearGradient>`;
    }
    // else if (condition.includes('radial')) {
    //   tag = `<radialGradient id="${id}" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
    //   <stop offset="0%" style="stop-color:rgb(255,255,255);
    //   stop-opacity:0" />
    //   <stop offset="50%" style="stop-color:rgb(0,0,255);stop-opacity:1" />
    // </radialGradient>`;
    // }

    let pattern;
    if (document.getElementById('svg') && document.getElementById('svg').children && document.getElementById('svg').children[1]) {
      pattern = <any>document.getElementById('svg').children[1].children;
      for (let item of pattern) {
        if (item.tagName == 'defs') {
          let conatin = <any>item.innerHTML;
          if (conatin.includes(id)) {
            let child = <any>document.getElementById(id).children[index];
            child.style.stopColor = color.colorCode;
          } else {
            item.innerHTML = item.innerHTML + tag;
            let child = <any>document.getElementById(id).children[index];
            child.style.stopColor = color.colorCode;
          }
        }
      }
    }
    setTimeout(() => {
      this.handelLoading.emit({ 'flag': false });
    }, 3000)

  }

  removGradient(id) {
    if (this.selected_slide == 1) {
      id = 'Gradient-Home-' + id;
    }
    else {
      id = 'Gradient-Away-' + id;
    }
    this.color1nameselectedforGrad = undefined;
    this.color2nameselectedforGrad = undefined;
    this.isSelectedcolorGrad1 = undefined;
    this.isSelectedcolorGrad2 = undefined;
    this.colorselectedGrad1 = undefined;
    this.colorselectedGrad2 = undefined;
    let ele = document.getElementById(id);
    if (ele)
      ele.remove();
  }

  removeGradientByID(id) {
    if (this.selected_slide == 1) {
      id = 'Gradient-Home-' + id;
    }
    else {
      id = 'Gradient-Away-' + id;
    }
    let ele = document.getElementById(id);
    if (ele)
      ele.remove();
  }

  sendGradcolordatatoEditor($event, layer, color, index) {
    if (index == 0) {
      this.colorselectedGrad1 = color;
      this.isSelectedcolorGrad1 = layer;
      this.color1nameselectedforGrad = color.colorName;
    }
    else {
      this.colorselectedGrad2 = color;
      this.isSelectedcolorGrad2 = layer;
      this.color2nameselectedforGrad = color.colorName;
    }
    let ids = <any>document.querySelectorAll('*[id^="grad-to"]');
    let condition;
    ids.forEach(element => {
      if (element.style.border == "3px solid") {
        condition = element.id;
      }
    });

    if (layer)
      this.addingDefDradientTag(layer.layerID, color, index, condition);
    let tmpPattern = { ...color };
    if (this.selected_slide == 1)
      tmpPattern.colorCode = `url(#Gradient-Home-${layer.layerID})`;
    else
      tmpPattern.colorCode = `url(#Gradient-Away-${layer.layerID})`;
    layer['color' + this.selected_slide] = tmpPattern;

    this.onselectcolor.emit(event);
    this.onselectcolor.emit(layer);
    this.onselectcolor.emit(tmpPattern);
    this.colornameselected = undefined;
    this.isSelectedcolor = undefined;
    this.remove(layer.layerID);
  }


  selectGrad(id, layer) {
    this.removeGradientByID(layer.layerID)
    this.slidervalue = 50;
    let ids = <any>document.querySelectorAll('*[id^="grad-to"]');
    ids.forEach(element => {
      if (element.id.includes(id)) {
        element.style.border = "3px solid";
      } else {
        element.style.border = "1px solid";
      }
    });

    let color1 = this.colorsList.find(element => element.colorName == this.color1nameselectedforGrad);
    let color2 = this.colorsList.find(element => element.colorName == this.color2nameselectedforGrad);
    let ids_toP_check = <any>document.querySelectorAll('*[id^="grad-to"]');
    let condition;
    this.slidervalue=50;
    ids_toP_check.forEach(element => {
      if (element.style.border == "3px solid") {
        condition = element.id;
      }
    });
    if (color1)
      this.addingDefDradientTag(layer.layerID, color1, 0, condition);
    if (color2)
      this.addingDefDradientTag(layer.layerID, color2, 1, condition);

  }


  changeSlideValue(slidervalue, layer) {
    let id = layer.layerID;
    if (this.selected_slide == 1)
      id = 'Gradient-Home-' + id;
    else
      id = 'Gradient-Away-' + id;

    let child;
    if (document.getElementById(id))
      child = <any>document.getElementById(id).children[1];
    if (child)
      child.setAttribute('offset', slidervalue + '%');

  }

}