// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// DEV 
// export const environment = {
//   production: false,
//   apiKey: "AIzaSyAEGhTb8CemJNIdK4V44CyCfN37VCG18tk",
//   authDomain: "uniformlab-dev-26158.firebaseapp.com",
//   databaseURL: "https://uniformlab-dev-26158.firebaseio.com",
//   projectId: "uniformlab-dev-26158",
//   storageBucket: "uniformlab-dev-26158.appspot.com",
//   messagingSenderId: "42353734265"
// };

// PROD 
export const environment = {
  production: true,
  apiKey: "AIzaSyCoTTh0KLj8XTHKU1XA7uZAUBnP1CIAQ0Y",
  authDomain: "uniform-e640f.firebaseapp.com", // not in use 
  databaseURL: "https://lv-lumber-db.firebaseio.com/",
  projectId: "uniform-e640f",
  storageBucket: "uniform-e640f-lvlumber",
  // storageBucket: "uniform-e640f.appspot.com",
  messagingSenderId: "913811954434",
  NODE_URL:"https://uniform-lab-lv-lumber.herokuapp.com/",
  backgroundImgName:"lv-lumber.JPG",
  subdomain: "lvlumber"
  // backgroundImgName:"str8sports.png"
};