import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';


@Component({
  selector: 'app-customer-my-design-requests',
  templateUrl: './my-design-requests.component.html',
  styleUrls: ['./my-design-requests.component.scss']
})
export class CustomerMyDesignRequestsComponent implements OnInit {
  requestdesignList = [];
  displayedColumns: string[] = ['Design', 'Date', 'Actions'];
  private loading:boolean = false;
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private nav: CustomerNavComponent,
    private router: Router,
    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    this.getOrderData(); 

  }

  getOrderData(){
    
    this.submissionService.getCustomerRequestDesign().subscribe(res => {
      this.requestdesignList = [];
      this.requestdesignList = res.json();
      this.requestdesignList = this.requestdesignList.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    },error =>{
      console.log("error is ",error);
    });
   
  }

  goToOrderDetails(element){
    this.router.navigate(['/locker-room/my-design-details'],{ queryParams: { designId: element._id} })
  }

  onOpenMenu(menu: any): void {
  }

  actionOrder(action,element){

  }
}
