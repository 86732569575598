export const STATUS = {
  INCOMPLETE: {
    color: "red"
  },
  ORDER_RECIEVED:{
    color: " yellow"
  },
  Ready_TO_SHIP: {
    color: "pink"
  },
  COMPLETE: {
    color: "green"
  },
  IN_PROCESS:{
    color: "blue"
  }
};
