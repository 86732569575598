import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material';
import { SubmissionsService } from '../../../services/submissions.service'; 
import { DatePipe }  from '@angular/common';

@Component({
  selector: 'app-select-design',
  templateUrl: './select-design.component.html',
  styleUrls: ['./select-design.component.css']
})
export class SelectDesignComponent implements OnInit {
  orderName : any;
  updateProduct:any;
  designFile:any;
  emailForCustomer:any;
  userRole:any;
  todayDate : Date = new Date();
  private loading123: boolean = false;
  allDesigns = [
	  {
	  	"id" : 1,
	  	"design" : 'assets/img/select_design_mock.png'
	  },
	  {
	  	"id" : 2,
	  	"design" : 'assets/img/select_design_mock.png'
	  }
	];
	selectedDesign : any;
  noDesign:boolean = true;
  private loading: boolean = false;
  loggedEmail: any;
  allDesignByEmail: any = [];
  allDesignRequest: any = [];
  allDesignByEmailForAdmin:any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private submissionService: SubmissionsService,public dialogRef: MatDialogRef<SelectDesignComponent>) { 
     console.log(data);
      if(data[0]){
            this.orderName = data[0].data.quotesName;
            console.log("data[0]",data[0]);
            this.emailForCustomer = data[0].data.productList[0].email;
            // this.emailForCustomer = 
            }else if(data.orderName && data.productList.length!=0){
              this.orderName = data.orderName;
              this.emailForCustomer = data.productList[0].email;
              // this.emailForCustomer = 
            }else{
              this.orderName = data.data.quotesName
              if(data.data.productList.length!=0){
                this.emailForCustomer = data.data.productList[0].email;
              }
            }
  }

  ngOnInit() {
    this.getAllDesign();
    this.getAllDesignRequest();
    this.getAllDesignAdmin();
    
   if(window.localStorage.getItem('userrole')=='Admin'){
    this.userRole = window.localStorage.getItem('userrole')
   }
  }

  getAllDesign(){
    this.loggedEmail = window.localStorage.getItem('emailForSignIn');
    if(this.data.data.email){
      this.emailForCustomer = this.data.data.email;
    }
        this.submissionService.getAllCustomers(this.emailForCustomer.trim()).subscribe(res => {
            this.allDesignByEmail = [];
            this.allDesignByEmail = res.json();
            this.allDesignByEmail = this.allDesignByEmail.sort((a, b) => b.dateToShow - a.dateToShow);
            for(let item of this.allDesignByEmail){
                if(item._id){
                    item.sortby =+ (item._id);
                }  
            }
            this.allDesignByEmail=this.allDesignByEmail.sort((a,b) => b.sortby - a.sortby);
          });
  }

  getAllDesignAdmin(){
    this.loggedEmail = window.localStorage.getItem('emailForSignIn');
        this.submissionService.getAllCustomers(this.loggedEmail.trim()).subscribe(res => {
            this.allDesignByEmailForAdmin = [];
            this.allDesignByEmailForAdmin = res.json();
            this.allDesignByEmailForAdmin = this.allDesignByEmailForAdmin.sort((a, b) => b.dateToShow - a.dateToShow);
            for(let item of this.allDesignByEmailForAdmin){
                if(item._id){
                    item.sortby =+ (item._id);
                }  
            }
            this.allDesignByEmailForAdmin=this.allDesignByEmailForAdmin.sort((a,b) => b.sortby - a.sortby);
          });
  }

  getAllDesignRequest(){
    this.loggedEmail = window.localStorage.getItem('emailForSignIn');
    if(this.data.data.email){
      this.emailForCustomer = this.data.data.email;
    }
        this.submissionService.getAllDesignRequest( this.emailForCustomer.trim()).subscribe(res => {
            this.allDesignRequest = [];
            this.allDesignRequest = res.json();
            // this.allDesignRequest= this.allDesignRequest.sort((a, b) => b.dateToShow - a.dateToShow);
            // for(let item of this.allDesignRequest){
            //     if(item._id){
            //         item.sortby =+ (item._id);
            //     }  
            // }
            // this.allDesignRequest=this.allDesignRequest.sort((a,b) => b.sortby - a.sortby);
            console.log("this.allDesignRequest",this.allDesignRequest);
          });
  }

  selectItem(item,select){
    if(select=='designrequest'){
      var designRequest = {
        svgFile:'',
        pngFile:item.fileUrl
      }
      this.selectedDesign = item._id;
      this.designFile = designRequest;
    }else{
      this.selectedDesign = item._id;
      this.designFile = item;
  }
}

  uploadDesign(){
  	this.allDesigns.push({id : this.allDesigns.length+1, "design" : 'assets/img/select_design_mock.png'});
  }

  selecteDesign(){
    this.loading123 = true;
    this.loading = true;
    this.updateProduct = null;
    this.updateProduct  = this.data;
    // var productId = this.data.productId;
    // for(var i=0;i<this.updateProduct.data.productList.length;i++){
      // if(this.updateProduct.data.productList[i].key==productId){
        let date = this.datePipe.transform(this.todayDate, 'medium')
        var design =  {
          type:this.designFile.svgFile ? 'Builder Design' : 'Design Request',
          name:'',
          date:date,
          svgFile:  this.designFile.svgFile ? this.designFile.svgFile : '',
          pngFile:  this.designFile.pngFile
        }
        this.updateProduct.data['LinkedDesigns'].push(design);
      // }
    // }
    var obj = null;
    obj = {
      quoteId: this.updateProduct._id,
      quoteData:this.updateProduct
    }

    console.log("this.updateProduct",this.updateProduct);
    this.submissionService.updateQuotes(obj).subscribe(res => {
      // console.log(res.json);
      var obj={
        submissionId:this.updateProduct.data.productList[0].submissionId,
        quoteId:res.json()._id
      }
      this.submissionService.updateQuoteWithSubmission(obj).subscribe(res => {
        this.loading = false;
        this.loading123 =false;
        this.dialogRef.close(res);
      },error =>{
        this.loading123 =false;
        this.loading = false;
        this.dialogRef.close(res);
      });
     
     
      
     
    });
  }
  closeModal(){
    this.updateProduct = null;
  }


}
