
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe }  from '@angular/common';
import { SubmissionsService } from 'services/submissions.service';
declare var Snap: any;

@Component({
    selector: 'sign-in-dialog',
    templateUrl: 'sign-in.component.html',
    styleUrls: ['sign-in.component.scss'],
})
export class SignInModalComponent {
    private loading: boolean = false;
    private match: boolean = false;
    private email: boolean = false;
    private phoneNumber: boolean = false;
    private abc:any;
    private allTemplates:any;
    private categoryName:any;
    todayDate : Date = new Date();
    myCartUser:any;
    errorMessage:any;
    errorMessage1:any;
    orderProduct = [];


    // 
    isExistingCustomer = true; 

    constructor(
        private router: Router,
        private toast: ToastrService,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<SignInModalComponent>,
        private datePipe: DatePipe,
        private submissionService: SubmissionsService,
        @Inject(MAT_DIALOG_DATA) public data: any

        
    ) {
        // this.af.list('/products').snapshotChanges().subscribe(styles => { 
        //     this.allTemplates = [];
        //     styles.forEach(element => {
        //      this.allTemplates.push(element.payload.val());
        
        //  });
        //  let current = this;
        //  this.categoryName = this.data.category;
        //  this.abc = this.allTemplates.filter(element => element.category==current.data.category); 
        // });
        
    }
    ngOnInit(){
        // this.data.productList.forEach(element => {
        //     var orderProduct = {
        //         productForeignKey: element.$key,
        //         quantity:element.quantity,
        //         productName:element.name,
        //         costPerProduct:element.price
        //     }
        //     this.orderProduct.push(orderProduct);
        // })
    }

    // addCardProduct(productName){
    //     this.dialogRef.close();
    //     productName['email'] = localStorage.getItem('emailForSignIn');
    //     this.af.list("/mycart").push(productName);
    //     this.router.navigate(['/locker-room/my-cart']); 
    // }
    
    close() {
        this.dialogRef.close();
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

    // createOrder(){
    //     let current = this;
    //     var OrderName = <any>document.getElementById('orderName');
    //     let todayData1 = this.datePipe.transform(this.todayDate, 'medium');
    //     var newOrder = {
    //         orderName: OrderName.value,
    //         orderNumber: Math.floor(100000 + Math.random() * 900000),
    //         orderTotal: current.data.totalPrice,
    //         dateCreated : todayData1,
    //         products:this.orderProduct
    //       }
    //     this.af.list("/order").push(newOrder);
    //     this.dialogRef.close();
    //     this.router.navigate(['/locker-room/order']);
        
    // }

    // new funcions
    login(){
      localStorage.clear();
      let loginEmail  = <any>document.getElementById('orderName');
      let loginPassword = <any>document.getElementById('orderName1');
      var loginObject = {
        email:loginEmail.value,
        password:loginPassword.value
    }
    let me= this;
    this.loading = true;
    // window.localStorage.clear();
    this.submissionService.login(loginObject).subscribe(res => {
        let user = JSON.parse((<any>res)._body);
        window.localStorage.setItem('name',user.user.name);
        window.localStorage.setItem('emailForSignIn',user.user.email);
        window.localStorage.setItem('token', user.token);
        this.dialogRef.close();
        if(user.user.role){
            if(user.user.role=='customer'){
                window.localStorage.setItem('userrole','User');
                if(this.data.redirected=='submission'){
                    me.router.navigate(['/locker-room/submissions']);
                }else if(this.data.redirected=='newdesign'){
                    me.router.navigate(['/locker-room/request-design']);
                }
            }else if(user.user.role=='admin'){
                window.localStorage.setItem('userrole','Admin');
                if(this.data.redirected=='submission'){
                    me.router.navigate(['/admin/home-admin']);
                }else if(this.data.redirected=='newdesign'){
                    me.router.navigate(['/admin/home-admin']);
                }
            }
        }
        this.loading = false;
          
    },error => {
        let err = JSON.parse((<any>error)._body);
        this.errorMessage1 = err.errorMessage;
        this.loading = false;
     });
    }

    createNewAccount(){
        this.loading = true;
        let email  = <any>document.getElementById('email');
        let password  = <any>document.getElementById('password');
        let fullName  = <any>document.getElementById('fullName');
        let phoneNumber = <any>document.getElementById('phNumber');
        // if(email.value && password && phoneNumber){
            var abc = {
                email:email.value,
                password:password.value,
                fullName:fullName.value,
                phoneNumber:phoneNumber.value
            }
            let me= this;
            this.submissionService.registerUser(abc).subscribe(res => {
                    let user = JSON.parse((<any>res)._body);
                    console.log(user.user.email);
                    window.localStorage.setItem('emailForSignIn',user.user.emailAddress);
                    window.localStorage.setItem('token', user.token);
                    this.dialogRef.close();
                    if(user.user.role){
                    if(user.user.role=='customer'){
                        window.localStorage.setItem('userrole','User');
                        if(this.data.redirected=='submission'){
                            me.router.navigate(['/locker-room/submissions']);
                        }else if(this.data.redirected=='newdesign'){
                            me.router.navigate(['/locker-room/request-design']);
                        }
                    }else if(user.user.role=='admin'){
                       window.localStorage.setItem('userrole','Admin');
                       me.router.navigate(['/admin/submissions']);
                     }
                    }
                    this.loading = false;
                 
                },error => {
                    let err = JSON.parse((<any>error)._body);
                    this.errorMessage = err.errorMessage;
                    this.loading = false;
                });
        // }else{

        // }
        
    }

    validatEmail(){
        let email  = <any>document.getElementById('email');
        var reg = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)([\w-]+\.)+[\w-]{2,4})?$/
        console.log("email ",email.value)
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)){  
          this.email = false;
        }else{
            this.email = true;
        }
    }

    phonenumber()
    {
        let inputtxt = <any>document.getElementById('phNumber');
      var phoneno = /^\d{10}$/;
      if(inputtxt.value.match(phoneno)){
        this.phoneNumber = false;
      }else{
            this.phoneNumber = true;
            }
    }


    check (){
       let pass=<any>document.getElementById('password');
       let cpass = <any>document.getElementById('c-password');
        if (pass.value ==
            cpass.value) {
          document.getElementById('message').style.color = 'green';
          document.getElementById('message').innerHTML = 'Password match';
          this.match=true;
        } else {
          document.getElementById('message').style.color = 'red';
          document.getElementById('message').innerHTML = 'Passwords do not match';
        }
      }
}


