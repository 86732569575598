import { Component, OnInit } from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-import-roster',
  templateUrl: './import-roster.component.html',
  styleUrls: ['./import-roster.component.css']
})
export class ImportRosterComponent implements OnInit {
  //uploader:FileUploader;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;

  response:string;
  constructor() {
  	
 }

  public uploader: FileUploader = new FileUploader({
	    url: URL,
	    disableMultipart : false,
	    autoUpload: true,
	    method: 'post',
	    itemAlias: 'attachment',
	    allowedFileType: ['image', 'pdf']
    });



  public onFileSelected() {
    const file: File = event[0];
    console.log(file);

  }

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  ngOnInit() {
  }

}
