import { Component, OnInit } from '@angular/core';
// import { ColorPickerService } from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
// import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
// import { FirebaseApp } from 'angularfire2';
import { Jersey, Ifile, UniformStyle, sport, FileObj, gender, reversible, uniformtypes } from '../models/upload';
import { cuts } from '../../models/jersey';
import { UploadService } from '../../../services/upload.service';
import * as _ from 'lodash';
// import * as firebase from 'firebase';
import 'fabric';
import { ButtonsModule } from 'ngx-bootstrap';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { ViewChild } from '@angular/core'
import { AuthService } from 'services/auth.service';
import { Router } from '@angular/router';

declare const fabric: any;

@Component({
	selector: 'add-style',
	templateUrl: './add-style.component.html',
	styleUrls: ['./add-style.component.css']
})


export class AddStyleComponent implements OnInit {
	@ViewChild(AngularMultiSelect, { static: false }) varietalDropdown: AngularMultiSelect;
	@ViewChild('genderdropdownRef', { static: false }) genderdropdownRef: AngularMultiSelect;
	@ViewChild('cutdropdownRef', { static: false }) cutdropdownRef: AngularMultiSelect;
	@ViewChild('typedropdownRef', { static: false }) typedropdownRef: AngularMultiSelect;
	@ViewChild('revcutdropdownRef', { static: false }) revcutdropdownRef: AngularMultiSelect;


	dropdownList = [];
	selectedItems = [];
	selectedsport = [];
	dropdownSettings = {};
	simpledropdownSettings = {};
	cutdropdownSettings = {};
	genderdropdownSettings = {};
	styleMode: any;
	selectedThumbnails: any;
	thumbnails: any = [];
	thumbnailFiles: any = [];
	UniformTypeModel: any;
	hacutarry: any[];
	modesel: any;
	ngOnInit() {
		this.auth.currentUser();
		let email = localStorage.getItem('emailForSignIn');
		// let emailtocheck = localStorage.getItem('emailtocheck');
		// if (email != emailtocheck) {
		if (!email) {
			if (localStorage.getItem('userrole') == 'Admin')
				this.router.navigate(['/admin/login']);
			else
				this.router.navigate(['/customer/login']);
			localStorage.clear();
		}

		// }

		this.UniformTypeModel = { reversible: false, homeaway: false, gameday: true };
		this.dropdownList = [
			{ "id": 1, "itemName": "Reversible" },
			{ "id": 2, "itemName": "Home and Away" },
			{ "id": 3, "itemName": "Game Day" }
		];
		this.selectedItems = [
			{ "id": 3, "itemName": "Game Day" }
		];
		this.simpledropdownSettings = {
			singleSelection: true,
			text: "Select Sport",
			searchPlaceholderText: "Select Sport",
			maxHeight: 200,
			showCheckbox: false,
			classes: "myclass custom-class"
		}
		this.cutdropdownSettings = {
			singleSelection: true,
			text: "Select Cuts",
			searchPlaceholderText: "Select Cuts",
		}
		this.genderdropdownSettings = {
			singleSelection: true,
			text: "Select",
			searchPlaceholderText: "Select",
			classes: "simpleselectbox multiselect"
			// labelKey: 'itemName'
		}
		this.dropdownSettings = {
			singleSelection: false,
			text: "Select Uniform Type",
			searchPlaceholderText: "Select Uniform Type",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: false,
			// enableCheckAll: true,
			showCheckbox: true,
			// enableFilterSelectAll: false,
			classes: "myclass multiselect"
		};
	}
	onItemSelect(item: any, type) {
		var that = this;
		if (type == "sport") {
			this.varietalDropdown.closeDropdown();
		} else if (type == "uniformtypes") {
			this.selectedItems.forEach(function (d) {
				if (d.itemName == "Reversible") {
					that.UniformTypeModel.reversible = true;
				} else if (d.itemName == "Home and Away") {
					that.UniformTypeModel.homeaway = true;
				} else {
					that.UniformTypeModel.gameday = true;
				}
			});
		} else if (type == "gender") {
			this.genderdropdownRef.closeDropdown();
		}
	}

	onTypeSelect(item: any, type, reverse) {
		if (type == "styletype") {
			if (item.itemName == "Jersey/Short") {
				this.styleMode = false;
			} else {
				this.styleMode = true;
			}
			this.typedropdownRef.closeDropdown();
		}
	}
	OnItemDeSelect(item: any, type) {
		var that = this;
		if (type == "sport") {
			this.varietalDropdown.closeDropdown();
		} else if (type == "uniformtypes") {
			if (item.itemName == "Reversible") {
				that.UniformTypeModel.reversible = false;
			} else if (item.itemName == "Home and Away") {
				that.UniformTypeModel.homeaway = false;
			} else if (item.itemName == "Game Day") {
				that.UniformTypeModel.gameday = false;
			}
		} else if (type == "gender") {
			if (this.gendermodel && this.gendermodel['gender'] && this.gendermodel['gender'].length == 0) {
				this.gendermodel['gender'].push(item);
			}
			this.genderdropdownRef.closeDropdown();
		} if (type == "styletype") {
			this.styleMode = undefined;
			this.typedropdownRef.closeDropdown();
		}
	}
	onSelectAll(items: any, type) {
		this.UniformTypeModel = { reversible: true, homeaway: true, gameday: true };
	}
	onDeSelectAll(items: any, type) {
		this.UniformTypeModel = { reversible: false, homeaway: false, gameday: false };
	}

	submitted = false;
	hasSportsError = false;
	checkSprotsError() {
		if (this.selectedItems.length === 0)
			this.hasSportsError = true;
		else
			this.hasSportsError = false;
	}

	private basePath: string = "/jersey-styles";
	cutsCount: number;
	folderName: string;
	selectedFiles: FileList;
	files: any = [];
	filess: any = [];
	newJersey: UniformStyle;
	categoryArray: any = [];
	item: Ifile;
	sportmodel = new sport();
	cutsmodel = new cuts();
	gendermodel = new gender();
	reversiblemodel = new reversible();
	hasCutsError = false;
	private cutType: string;

	validateType(value, id) {
		if (value == true || value == 'true') {
			this.styleMode = false;
		} else {
			this.styleMode = true;
		}

	}

	validateCuts(value, revId) {
		if (value === 'default') {
			this.hasCutsError = true;
			this.onSelect(0, revId, "gameday");
		} else {
			this.hasCutsError = false;
			this.onSelect(value, revId, "gameday");
		}
	}
	validateRevCuts(value, revId) {
		if (value === 'default') {
			this.hasCutsError = true;
			this.onSelect(0, revId, "reversible");
		} else {
			this.hasCutsError = false;
			this.onSelect(value, revId, "reversible");
		}
	}
	validateHACuts(value, revId) {
		if (value === 'default') {
			this.hasCutsError = true;
			this.onSelect(0, revId, "homeaway");
		} else {
			this.hasCutsError = false;
			this.onSelect(value, revId, "homeaway");
		}
	}
	validateGameDayHomeAwayCuts(value, revId) {
		if (value === 'default') {
			this.hasCutsError = true;
			this.onSelect(0, revId, "homeaway");
		} else {
			this.hasCutsError = false;
			this.onSelect(value, revId, "homeaway");
		}
	}
	onCutSelect(item: any, value, revId) {
		this.cutdropdownRef.closeDropdown();
		if (this.UniformTypeModel.gameday == true && this.UniformTypeModel.homeaway == false) {
			this.validateCuts(item.itemName, revId);
		} else if (this.UniformTypeModel.gameday == false && this.UniformTypeModel.homeaway == true) {
			this.validateHACuts(item.itemName, revId);
		} else {
			this.validateGameDayHomeAwayCuts(item.itemName, revId);
		}
	}

	onCutDeSelect(item: any, value, revId) {
		if (this.cutsmodel && this.cutsmodel['numberofcuts'] && this.cutsmodel['numberofcuts'].length == 0) {
			this.cutsmodel['numberofcuts'].push(item);
		}
		this.cutdropdownRef.closeDropdown();
		if (this.UniformTypeModel.gameday == true && this.UniformTypeModel.homeaway == false) {
			this.validateCuts(item.itemName, revId);
		} else if (this.UniformTypeModel.gameday == false && this.UniformTypeModel.homeaway == true) {
			this.validateHACuts(item.itemName, revId);
		} else {
			this.validateGameDayHomeAwayCuts(item.itemName, revId);
		}
	}
	onRevCutSelect(item: any, value, revId) {
		this.validateRevCuts(item.itemName, revId);
		this.revcutdropdownRef.closeDropdown();
	}

	onRevCutDeSelect(item: any, value, revId) {
		if (this.cutsmodel && this.cutsmodel['numberofreversecuts'] && this.cutsmodel['numberofreversecuts'].length == 0) {
			this.cutsmodel['numberofreversecuts'].push(item);
		}
		this.revcutdropdownRef.closeDropdown();
		this.validateRevCuts(item.itemName, revId);
	}

	validateGender(gender, revId) {
		var ID = 0;
		if (gender == "Male") {
			ID = 3
		}
		if (gender == "Female") {
			ID = 1
		}
		this.onSelect(ID, revId, "gameday");
	}

	public sport: sport[] = [
		{ "id": 1, "itemName": "Basketball" },
		{ "id": 2, "itemName": "Football" },
		{ "id": 3, "itemName": "Baseball" },
		{ "id": 4, "itemName": "Soccer" },
		{ "id": 5, "itemName": "Volleyball" },
		{ "id": 6, "itemName": "Sideline Gear" },
		{ "id": 7, "itemName": "Spirit Wear" },
		{ "id": 8, "itemName": "Backpacks" },
		{ "id": 8, "itemName": "Shooting Shirts" }
	];

	public cuts: cuts[] = [
		{ "id": 1, "name": "1" },
		{ "id": 2, "name": "2" },
		{ "id": 3, "name": "3" },
		{ "id": 4, "name": "4" },
		{ "id": 5, "name": "5" },
		{ "id": 6, "name": "6" }
	];
	public cutTypes = [
		{ "id": 1, "name": "1", "itemName": "1" },
		{ "id": 2, "name": "2", "itemName": "2" },
		{ "id": 3, "name": "3", "itemName": "3" },
		{ "id": 4, "name": "4", "itemName": "4" },
		{ "id": 5, "name": "5", "itemName": "5" },
		{ "id": 6, "name": "6", "itemName": "6" }
	];
	public genderList = [
		{ "id": 1, "name": "Male", "itemName": "Male" },
		{ "id": 2, "name": "Female", "itemName": "Female" }
	];
	public typeList = [
		{ "id": 1, "name": "Jersey/Short", "itemName": "Jersey/Short" },
		{ "id": 2, "name": "Other", "itemName": "Other" }
	];
	public gender: gender[] = [
		{ "id": 1, "name": "Male" },
		{ "id": 2, "name": "Female" }
	];
	public reversible: reversible[] = [
		{ "id": 1, "name": "Yes" },
		{ "id": 2, "name": "No" }
	];
	public uniformtypes: uniformtypes[] = [
		{ "id": 1, "name": "Reversible" },
		{ "id": 2, "name": "Home and Away" },
		{ "id": 2, "name": "Game Day" }
	];
	public cutarry = [];
	public reversecutarry = [];
	public selectedCuts: cuts = this.cuts[0];
	public isReversible: boolean;
	onSelect(CutsId, revId, type) {

		this.selectedCuts = null;

		if (type == "reversible") {
			this.reversecutarry = [];
		} else if (type == "homeaway") {
			this.hacutarry = [];
			if (this.UniformTypeModel.gameday == true) {
				this.cutarry = [];
			}
		} else if (type == "gameday") {
			this.cutarry = [];
		}

		if (revId.valueAccessor.value == undefined || revId.valueAccessor.value == "No") {
			this.isReversible = false;
		} else {
			this.isReversible = true;
		}
		if (this.UniformTypeModel && this.UniformTypeModel.reversible == true) {
			this.isReversible = true;
		}
		for (var i = 0; i < this.cuts.length; i++) {
			if (this.cuts[i].id == CutsId) {
				this.selectedCuts = this.cuts[i];
				for (var j = 0; j < this.cuts[i].id; j++) {
					if (type == "reversible") {
						this.reversecutarry.push(j);
					} else if (type == "homeaway") {
						this.hacutarry.push(j);
						if (this.UniformTypeModel.gameday == true) {
							this.cutarry.push(j);
						}
					} else {
						this.cutarry.push(j);
					}
				};
			}
		}

	}

	constructor(
		private us: UploadService,
		private auth: AuthService,
		public router: Router
	) {
	}

	changeCutCount() {
	}

	detectFiles(event, layoutView, uniformtype) {
		console.log("HERE")
		this.selectedFiles = event.target.files;
		console.log(event);
		if (this.selectedFiles && this.selectedFiles.length > 0)
			this.files.push({
				file: event.target.files,
				LayoutView: layoutView,
				UniformType: uniformtype
			});
	}

	detectFile(event, layoutView, uniformtype) {
		console.log(event)
		this.selectedFiles = event.target.files;
		if (this.selectedFiles && this.selectedFiles.length > 0)
			this.filess.push({
				file: event.target.files,
				LayoutView: layoutView,
				UniformType: uniformtype
			});
	}

	detectThumbnail(event, layoutView) {
		this.selectedThumbnails = event.target.files;
		this.thumbnails.push({
			file: event.target.files,
			LayoutView: layoutView
		});
	}

	uploadSingle() {
		let file = this.selectedFiles.item(0);
	}

	addJersey(addJerseyForm: NgForm) {
		// gets the folder name
		this.folderName = addJerseyForm.value.name.replace(/\s+/g, '-').toLowerCase();
		this.newJersey = new UniformStyle();
		this.newJersey.files = [];
		this.newJersey.display_Name = addJerseyForm.value.name;
		this.newJersey.price = addJerseyForm.value.price;
		this.newJersey.gender = addJerseyForm.value.gender;
		if (addJerseyForm.value.gender && addJerseyForm.value.gender[0]) {
			this.newJersey.gender = addJerseyForm.value.gender[0].itemName;
		}
		this.newJersey.files = [];
		this.newJersey.reversible = this.isReversible;
		if (addJerseyForm.value.sku) {
			this.newJersey.sku_number = addJerseyForm.value.sku;
		} else {
			this.newJersey.sku_number = "";
			// this.newJersey.sku_number = (this.folderName).replace(/\s/g, "") + Math.floor(Math.random() * 10000) + 1;          
		}
		if (addJerseyForm.value.jersey_sku) {
			this.newJersey.jersey_sku = addJerseyForm.value.jersey_sku;
		} else {
			this.newJersey.jersey_sku = "";
		}
		if (addJerseyForm.value.shorts_sku) {
			this.newJersey.shorts_sku = addJerseyForm.value.shorts_sku;
		} else {
			this.newJersey.shorts_sku = "";
		}
		let filesArray = this.files //array of files
		let filesArrayNew = this.filess;
		this.newJersey.thumbnail_url = this.thumbnails;
		try {
			var cFile = this.thumbnails[0].file.item(0);
			var lFile = this.thumbnails[0].LayoutView;
			let item1: Ifile = new FileObj('GameDay', 'thumbnail', lFile, cFile);
			this.thumbnailFiles.push(item1);
		} catch (error) {
			console.log(error);
		}
		// this.newJersey.category = addJerseyForm.value.sport;
		if (addJerseyForm.value.sport && addJerseyForm.value.sport[0]) {
			this.newJersey.category = addJerseyForm.value.sport[0].itemName;
			if (addJerseyForm.value.sport[0].itemName == "Shooting Shirts") {
				this.newJersey.category = "ShootingShirts";
			}
		}

		let filesIndex = _.range(filesArray.length) //get length of array
		let filessIndex = _.range(filesArrayNew.length) //get length of array
		var loopCount = 0;
		var reversibleLoopCount = 0;
		var homeawayloopcount = 0;
		var loop = 0;
		var reversibleloop = 0;
		var homeawayloop = 0;
		for (var index = 0; index < filesArray.length; index++) {
			var curFile = this.files[index].file.item(0);
			var layoutFile = this.files[index].LayoutView;
			var uniformType = this.files[index].UniformType;
			if (uniformType == 'Reversible') {
				if (reversibleLoopCount == 0) {
					this.cutType = addJerseyForm.value.revCutTypeOutside0;
				}
				if (reversibleLoopCount == 1) {
					this.cutType = addJerseyForm.value.revCutTypeOutside1;
				}
				if (reversibleLoopCount == 2) {
					this.cutType = addJerseyForm.value.revCutTypeOutside2;
				}
				if (reversibleLoopCount == 3) {
					this.cutType = addJerseyForm.value.revCutTypeOutside3;
				}
				if (reversibleLoopCount == 4) {
					this.cutType = addJerseyForm.value.revCutTypeOutside4;
				}
				if (reversibleLoopCount == 5) {
					this.cutType = addJerseyForm.value.revCutTypeOutside5;
				}
				reversibleLoopCount += 1;
			} else if (uniformType == 'HomeAway') {
				if (homeawayloopcount == 0) {
					this.cutType = addJerseyForm.value.haCutTypeOutside0;
				}
				if (homeawayloopcount == 1) {
					this.cutType = addJerseyForm.value.haCutTypeOutside1;
				}
				if (homeawayloopcount == 2) {
					this.cutType = addJerseyForm.value.haCutTypeOutside2;
				}
				if (homeawayloopcount == 3) {
					this.cutType = addJerseyForm.value.haCutTypeOutside3;
				}
				if (homeawayloopcount == 4) {
					this.cutType = addJerseyForm.value.haCutTypeOutside4;
				}
				if (homeawayloopcount == 5) {
					this.cutType = addJerseyForm.value.haCutTypeOutside5;
				}
				homeawayloopcount += 1;
			} else {
				if (loopCount == 0) {
					this.cutType = addJerseyForm.value.cutTypeOutside0;
				}
				if (loopCount == 1) {
					this.cutType = addJerseyForm.value.cutTypeOutside1;
				}
				if (loopCount == 2) {
					this.cutType = addJerseyForm.value.cutTypeOutside2;
				}
				if (loopCount == 3) {
					this.cutType = addJerseyForm.value.cutTypeOutside3;
				}
				if (loopCount == 4) {
					this.cutType = addJerseyForm.value.cutTypeOutside4;
				}
				if (loopCount == 5) {
					this.cutType = addJerseyForm.value.cutTypeOutside5;
				}
				loopCount += 1;
			}

			let item: Ifile = new FileObj(uniformType, this.cutType, layoutFile, curFile);
			if (uniformType == 'HomeAway') {
				if (this.UniformTypeModel.gameday == true && this.UniformTypeModel.homeaway == true) {
					let item2: Ifile = new FileObj('GameDay', this.cutType, layoutFile, curFile);
					this.newJersey.files.push(item2);
				}
			}
			this.newJersey.files.push(item);
			this.newJersey.name = this.folderName;
		}


		for (var index = 0; index < filesArrayNew.length; index++) {
			var curFile = this.filess[index].file.item(0);
			var layoutFile = this.filess[index].LayoutView;
			var uniformType = this.filess[index].UniformType;
			if (uniformType == 'Reversible') {
				if (reversibleloop == 0) {
					this.cutType = addJerseyForm.value.revCutTypeInside0;
				}
				if (reversibleloop == 1) {
					this.cutType = addJerseyForm.value.revCutTypeInside1;
				}
				if (reversibleloop == 2) {
					this.cutType = addJerseyForm.value.revCutTypeInside2;
				}
				if (reversibleloop == 3) {
					this.cutType = addJerseyForm.value.revCutTypeInside3;
				}
				if (reversibleloop == 4) {
					this.cutType = addJerseyForm.value.revCutTypeInside4;
				}
				if (reversibleloop == 5) {
					this.cutType = addJerseyForm.value.revCutTypeInside5;
				}
				reversibleloop += 1;
			} else if (uniformType == 'HomeAway') {
				if (homeawayloop == 0) {
					this.cutType = addJerseyForm.value.haCutTypeInside0;
				}
				if (homeawayloop == 1) {
					this.cutType = addJerseyForm.value.haCutTypeInside1;
				}
				if (homeawayloop == 2) {
					this.cutType = addJerseyForm.value.haCutTypeInside2;
				}
				if (homeawayloop == 3) {
					this.cutType = addJerseyForm.value.haCutTypeInside3;
				}
				if (homeawayloop == 4) {
					this.cutType = addJerseyForm.value.haCutTypeInside4;
				}
				if (homeawayloop == 5) {
					this.cutType = addJerseyForm.value.haCutTypeInside5;
				}
				homeawayloop += 1;
			} else {
				if (loop == 0) {
					this.cutType = addJerseyForm.value.cutTypeInside0;
				}
				if (loop == 1) {
					this.cutType = addJerseyForm.value.cutTypeInside1;
				}
				if (loop == 2) {
					this.cutType = addJerseyForm.value.cutTypeInside2;
				}
				if (loop == 3) {
					this.cutType = addJerseyForm.value.cutTypeInside3;
				}
				if (loop == 4) {
					this.cutType = addJerseyForm.value.cutTypeInside4;
				}
				if (loop == 5) {
					this.cutType = addJerseyForm.value.cutTypeInside5;
				}
			}

			let item: Ifile = new FileObj(uniformType, this.cutType, layoutFile, curFile);
			this.newJersey.files.push(item);
			this.newJersey.name = this.folderName;
		}
		//will not save uniform
		// return;
		this.us.saveNewStyle(this.newJersey, this.thumbnailFiles);
		addJerseyForm.reset();
		this.thumbnailFiles = [];
		alert("Uniform added");
	}

	checkuniformtype(k, val) {
		if (this.UniformTypeModel && this.UniformTypeModel.reversible == true) {
			this.isReversible = true;
		}
	}

	checkreveribleModel(val) {
		if (val == "Yes") {
			this.UniformTypeModel.reversible = true;
		} else {
			this.UniformTypeModel.reversible = false;
		}
	}
}
