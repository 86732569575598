import { Pipe, PipeTransform } from '@angular/core';

@Pipe ({
  name: 'filter'
})

//Filter pipe for users Dashboard
//Search by Submission ID, Name, Email, Organization
export class FilterPipe implements PipeTransform {
  transform(submissions: any, searchTerm: any): any {
    //check if search term is undefined
    if (searchTerm === undefined)
      return submissions;
    //return updated users array
    return submissions.filter(function(submission){
      if (submission.fullName === undefined){
        console.log("hit");
      } else {
        return ((submission.FullName.includes(searchTerm.toLowerCase())) | (submission.ID.includes(searchTerm.toLowerCase())));
      }
    });
  }
}
