import { Component, OnInit, ViewChild } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SubmissionsService } from '../../../services/submissions.service';

import 'fabric';
import { AuthService } from 'services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
declare const fabric: any;

@Component({
  selector: 'bulk-pricing',
  templateUrl: './bulk-pricing.component.html',
  styleUrls: ['./bulk-pricing.component.scss']
})

export class BulkPricingOptionsComponent {
  private quantityList = [];
  private editing: boolean = false;
  private loading: boolean = false;
  newName1 = '';
  newName2 = '';
  newDiscount = '';
  constructor(private subService: SubmissionsService,
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {
    this.auth.currentUser();
    this.getQuantity();
    }
  startLoadingSpinner() {
      this.loading = true;
      setTimeout(function () {
      }.bind(this), 1500);
    }
  getQuantity() {
    this.quantityList = [];
    this.startLoadingSpinner();
      this.loading = true;
    this.af.list('/quantity').snapshotChanges()
      .subscribe(data => {
        this.quantityList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.quantityList.push({ $key, ...value });
        });
        this.loading = false;
      });
  }

  
  addBreakPoint(newName1,newName2,newDiscount){
   var newQuantity = {
     quantity1: newName1,
     quantity2: newName2,
     discount : newDiscount
   }
   this.af.list("/quantity").push(newQuantity);
  }

  removeQuantity(quantity) {
    var a = confirm("Are you sure you want to delete this Quantity?")
    if (a) {
      this.af.list("/quantity").remove(quantity.$key).then(_ => {
        this.getQuantity();
      });
    }
  }
  toggleEditing(color) {

    if (this.editing) {
      this.editing = false;
    } else {
      this.editing = true;
    }
  }
  saveChanges(quantity) {
    this.editing = false;
    let key = quantity.$key;
    delete quantity.$key;
    let update = this.af.object(`/quantity/${key}`).update(quantity);
    this.getQuantity();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.quantityList, event.previousIndex, event.currentIndex);
  }
}
