import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { SubmissionsService } from '../../../services/submissions.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private submService : SubmissionsService
  ) { }

  companyLogo$: AngularFireList<any[]>;

  accountSetings = {
    organization: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    logoPath: '',
    urlHandle: '',
    csPhoneNumber: '',
    csEmail: '',
    builderSettings: {
      canCustomerDownloadPDF: false,
      requireRosterBeforeSubmit: false
    },
    shopifyApiKey:''
  }
  private loading: boolean = false;

  

  ngOnInit() {
    this.getImage();
  }
  companyData = []

  getImage() {
    this.submService.getConfiguration()
    .subscribe(data => {
      console.log("data *********************",data.json());
      var object = data.json()[0];
      // let object = data.payload.val()
      this.uploadedLogoPath = object.companyLogoPath;
      this.accountSetings.logoPath = object.companyLogoPath;
      this.accountSetings.firstName = object.firstName;
      this.accountSetings.lastName = object.lastName;
      this.accountSetings.email = object.businessEmail;
      this.accountSetings.phoneNumber = object.businessPhoneNumber;
      this.accountSetings.organization = object.businessName;
      this.accountSetings.urlHandle = object.urlSlug;
      this.accountSetings.csEmail = object.customerSupportEmail;
      this.accountSetings.csPhoneNumber = object.customerSupportPhone;
      this.accountSetings.shopifyApiKey = object.shopifyApiKey;
      this.accountSetings['builderSettings'].canCustomerDownloadPDF = object.canCustomerExportPDF; 
      this.accountSetings['builderSettings'].requireRosterBeforeSubmit = object.rosterFrontEnd; 

      // let object = data.payload.val()
      // this.uploadedLogoPath = object['logoPath'];
      // this.accountSetings.logoPath = object['logoPath'];
      // this.accountSetings.firstName = object['firstName'];
      // this.accountSetings.lastName = object['lastName'];
      // this.accountSetings.email = object['email'];
      // this.accountSetings.phoneNumber = object['phoneNumber'];
      // this.accountSetings.organization = object['organization'];
      // this.accountSetings.urlHandle = object['urlHandle'];
      // this.accountSetings.csEmail = object['csEmail'];
      // this.accountSetings.csPhoneNumber = object['csPhoneNumber'];
      // this.accountSetings['builderSettings'].canCustomerDownloadPDF = object['builderSettings']['canCustomerDownloadPDF']; 
      // this.accountSetings['builderSettings'].requireRosterBeforeSubmit = object['builderSettings']['requireRosterBeforeSubmit']; 
      
     });
    // this.db.object('/company-info/myInfo').snapshotChanges().subscribe(data => {


    // "firstName": "",
    // "lastName": "",
    // "businessEmail": "",
    // "businessPhoneNumber": "",
    // "companyLogoPath": "",
    // "urlSlug": "",
    // "businessPhone": "",
    // "customerSupportEmail": "",
    // "customerSupportPhone": "",
    // "_id": "5f06a8cfa4d51e137028aec2",
    // "businessName": "Str8Sports",
    // "designUniform": true,
    // "requestDesign": true,
    // "provideDesign": true,
    // "rosterFrontEnd": true,
    // "isOrderSystemEnabled": false,
    // "isQuoteSystemEnabled": true,
    // "canCustomerExportPDF": true,
      
    // });
  }

  //File Select & Upload
  uploadImage(event) {
    if (event.target.files.length != 0) {
      let file = event.target.files[0];
      this.handleReaderLoaded(file)
    }
  }

  uploadLogo() {
    document.getElementById("uploadFile").click();
  }

  saveProfileChanges() {
  this.loading = true;
    var obj = {
    firstName: this.accountSetings.firstName,
    lastName: this.accountSetings.lastName,
    businessEmail: this.accountSetings.email,
    businessPhoneNumber: this.accountSetings.phoneNumber,
    companyLogoPath: this.accountSetings.logoPath,
    urlSlug: this.accountSetings.urlHandle,
    businessPhone: this.accountSetings.phoneNumber,
    customerSupportEmail: this.accountSetings.csEmail,
    customerSupportPhone:  this.accountSetings.csPhoneNumber,
    businessName: this.accountSetings.organization,
    designUniform: true,
    requestDesign: true,
    provideDesign: true,
    rosterFrontEnd: this.accountSetings['builderSettings'].requireRosterBeforeSubmit ,
    isOrderSystemEnabled: false,
    isQuoteSystemEnabled: true,
    canCustomerExportPDF: this.accountSetings['builderSettings'].canCustomerDownloadPDF,
    _id: "5f06a8cfa4d51e137028aec2",
    shopifyApiKey:this.accountSetings.shopifyApiKey
    }
    this.submService.UpdateAccountSetting(obj)
    .subscribe(data => {
      this.loading = false;  
      console.log(data.json());
       this.toastr.success('Company info updated', '', { positionClass: 'toast-top-right' });
     });
    // this.db.object('/company-info/myInfo').update(this.accountSetings)
   
  }

  uploadedLogoPath = null;
  base64textString;
  async	handleReaderLoaded(file) {
    this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
    let storageRef = firebase.storage().ref();
    let extendo = file.name.substring(file.name.indexOf("."));
    const fileData = await storageRef.child(`${'/uploaded-logos'}/COMPANY-LOGO${extendo}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        this.uploadedLogoPath = downloadURL;
        this.base64textString = downloadURL;
        this.accountSetings.logoPath = downloadURL;
        this.db.object('/company-info/myInfo').update(this.accountSetings)
        this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
      }).catch(error => {
        this.uploadedLogoPath = null;
        this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
      });


  }

}
