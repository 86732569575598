import { Injectable } from '@angular/core';
import { BehaviorSubject  }    from 'rxjs/BehaviorSubject';
import { UniformStyle } from '../app/admin/models/upload'


@Injectable()
export class styleService {
    
  // Observable string sources
  private style = new BehaviorSubject<UniformStyle>(null);
 
  // Observable string streamsn
  selStyle$ = this.style.asObservable();
  

  // Service message commands
  publishData(data: UniformStyle) {
    this.style.next(data);
    console.log()
  }

}