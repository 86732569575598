import { Component, OnInit, Inject } from '@angular/core';
import {MatInputModule} from '@angular/material/input'; 
import {NgForm} from '@angular/forms';
import { SubmissionsService } from '../../../services/submissions.service';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MaterialFileInputModule } from 'ngx-material-file-input';

@Component({
  selector: 'app-create-new-purchase-order',
  templateUrl: './create-new-purchase-order.component.html',
  styleUrls: ['./create-new-purchase-order.component.css']
})
export class CreateNewPurchaseOrderComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private submService : SubmissionsService,public dialogRef: MatDialogRef<CreateNewPurchaseOrderComponent>) { }

  ngOnInit() {
  }

  addOrderPO(orderPOForm: NgForm){
  	console.log(orderPOForm.value);
    this.submService.addPO(orderPOForm.value)
       .subscribe(data => {
          this.dialogRef.close(JSON.parse((<any>data)._body));
        });
  }


}
