import { Component, OnInit } from '@angular/core';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { MatTableDataSource } from "@angular/material";
import { runInThisContext } from 'vm';
import * as firebase from 'firebase/app';
import { SetColorReqDesignModalComponent } from './set-color-redesign/set-color-redesign.component';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { DatePipe }  from '@angular/common';
import { MatDialog } from '@angular/material';
import { SubmissionsService } from 'services/submissions.service';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-customer-request-design',
  templateUrl: './request-design.component.html',
  styleUrls: ['./request-design.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerRequestDesignComponent implements OnInit {
  columnsToDisplay = ['SKU', 'Name', 'Category', 'Created', 'Modified'];
  expandedElement: null;
  dataSource :any;
  dataSource1:any;
  templateCategory:any;
  selectedIndex:any;
  selectedIndex1:any;
  colorArray:any;
  // private allTemplates  = [];
  // private quantityList = [];
  private productList = [];
  private packageList = [];
  private abc = [];
  private abc1 = [];
  private loading: boolean = false;
  private disabledButtonContine: boolean = false;
  private disabledButtonContine1: boolean = false;
  categoryName:any;
  category:any;
  product:any;
  date:any;
  name:any;
  picker:any;
  details:any;
  angForm: FormGroup;
  constructor(private nav: CustomerNavComponent,
    private af: AngularFireDatabase,
    private fb: FormBuilder,
    private toast: ToastrService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private submissionService: SubmissionsService,
    private toastr: ToastrService,
    private router: Router,
    
    ) { 
      this.createForm();
    }
    createForm() {
      this.angForm = this.fb.group({
         name: ['', Validators.required],
         date:['', Validators.required],
         details:['']
      });

     this.angForm.valueChanges 
            .subscribe((changedObj: any) => {
                 this.disabledButtonContine1 = this.angForm.valid;
            });
     

    }
    startLoadingSpinner() {
      this.loading = true;
      setTimeout(function () {
      }.bind(this), 1500);
    }
  catList = [];
  ngOnInit() {
    // this.getProducts();
    // this.getQuantity();
    this.startLoadingSpinner();
    this.loading = true;
    this.af.list('/template-categories').snapshotChanges().subscribe(data => {
      this.catList = [];
      data.forEach(action => {
        const value = action.payload.val();
        let newCat = {
          key: action.key,
          name: value['name'],
          nameNoSpace: value['nameNoSpace'],
          image:value['image']
        }
        this.catList.push(newCat);
        this.loading = false;
      });
    })
    } 
  // getQuantity() {
  //     this.quantityList = [];
  //     this.startLoadingSpinner();
  //     this.loading = true;
  //     this.af.list('/quantity').snapshotChanges()
  //       .subscribe(data => {
  //         this.quantityList = [];
  //         data.forEach(action => {
  //           const value = action.payload.val();
  //           const $key = action.key;
  //           this.quantityList.push({ $key, ...value });
  //         });
  //         this.loading = false;
  //       });
  //   }

    // getProducts() {
    //   this.productList = [];
    //   this.packageList = [];
    //   this.startLoadingSpinner();
    //   this.loading = true;
    //   this.af.list('/products').snapshotChanges()
    //     .subscribe(data => {
    //       this.productList = [];
    //       this.packageList = [];
    //       data.forEach(action => {
    //         const value = action.payload.val();
    //         const $key = action.key;
    //         if(value['isPackage']==undefined || value['isPackage']=='undefined' || value['isPackage']=='false' || value['isPackage']==false){
    //           this.productList.push({ $key, ...value });
    //         }else{
    //           this.packageList.push({ $key, ...value });
    //         }
    //         this.loading = false;
    //       });
    //     });
    // }


    // applyFilter(filterValue: string) {
    //   filterValue = filterValue.trim(); // Remove whitespace
    //   filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    //   this.dataSource.filter = filterValue;
    //   this.productList = this.dataSource;
    // }
    // applyFilterForPakage(filterValue: string) {
    //   filterValue = filterValue.trim(); // Remove whitespace
    //   filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    //   this.dataSource1.filter = filterValue;
    //   this.packageList = this.dataSource1;
    // }

    onSelect(index){
      this.selectedIndex = index;
    }
    onSelect1(index){
      this.selectedIndex1 = index;
    }

    change(){
    }

    onFileChange(event){
      if (event.target.files.length != 0) {
        for(var i=0;i<event.target.files.length;i++){
        let file = event.target.files[i];
        this.handleReaderLoaded(file)
        }
    }
    }

    uploadedImage = [];
    // uploadedImage = null;
    async handleReaderLoaded(file) {
      this.toast.warning("Plase wait while uploading image.", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
      let uniqlogo1 = 'ReQuestImage' + (new Date().getTime());
          let storageRef = firebase.storage().ref();
      const fileData = await storageRef.child(`${'/request-design-images'}/${uniqlogo1}`).put(file)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL();
        }).then(async (downloadURL) => {
          this.uploadedImage.push(downloadURL);
          // this.uploadedImage=downloadURL;
          this.toast.success('Image. sucessfully upload/ed', '', { positionClass: 'toast-top-right' });
        }).catch(error => {
          this.toast.error("Faild to upload image. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
        });
      }
   
    onFileChange1(event){
    console.log("event.target.files",event.target.files);
      if (event.target.files.length != 0) {
        for(var i=0;i<event.target.files.length;i++){
        let file = event.target.files[i];
        this.handleReaderLoaded1(file)
        }
    }
  }
  uploadedImage1 = [];
  // uploadedImage1 = null;
  async handleReaderLoaded1(file) {
    this.toast.warning("Plase wait while uploading image.", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
    let uniqlogo1 = 'ReQuestImage1' + (new Date().getTime());
        let storageRef = firebase.storage().ref();
    const fileData = await storageRef.child(`${'/request-design-images-1'}/${uniqlogo1}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        this.uploadedImage1.push(downloadURL);
        // this.uploadedImage1 = downloadURL;
        this.toast.success('Image. sucessfully upload/ed', '', { positionClass: 'toast-top-right' });
      }).catch(error => {
        this.toast.error("Faild to upload image. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
      });
    }

    saveCategory(template){
      this.category = template;
    }
    saveProduct(product){
      var saveProduct = {
        key:product.$key,
        category:product.category,
        image:product.image,
        modifiedDate:product.modifiedDate,
        name:product.name,
        price:product.price,
        shippingCost:product.shippingCost,
        sku:product.sku
      }
      this.product = [];
      console.log("saveProduct",saveProduct);
      this.product.push(saveProduct);
    }
    saveNewRequestDesign(){
      this.af.list('/products').snapshotChanges()
        .subscribe(data => {
          this.productList = [];
          this.packageList = [];
          data.forEach(action => {
            const value = action.payload.val();
            const $key = action.key;
            if(value['isPackage']==undefined || value['isPackage']=='undefined' || value['isPackage']=='false' || value['isPackage']==false){
              this.productList.push({ $key, ...value });
            }else{
              this.packageList.push({ $key, ...value });
            }

            this.abc = this.productList.filter(element => element.category==this.category);
            this.abc1  = this.packageList.filter(element => element.category==this.category);
            this.loading = false;
          });
        });
    }

    saveAllData(){
    this.loading = true;
     this.angForm.value['file1'] = this.uploadedImage;
     this.angForm.value['file2'] = this.uploadedImage1;
     let newDate=this.datePipe.transform(this.angForm.value.date, 'MM/dd/yyyy');
     var newObj = {
      category:this.category,
      colors:this.colorArray,
      name:this.angForm.value.name,
      date:newDate,
      details:this.angForm.value.details,
      file1:this.angForm.value.file1,
      file2:this.angForm.value.file2,
      product:this.product,
     }
     let me = this;
     this.submissionService.saveRequestDesign(newObj).subscribe(res => {
       let user = JSON.parse((<any>res)._body);
          //  this.toastr.success('Successfully Submitted.', '', { positionClass: 'toast-top-right',timeOut: 7000 });
           this.loading = false;
           this.router.navigate(['/locker-room/my-design-details'],{ queryParams: { designId: user._id} })
          //  me.router.navigate(['/locker-room/my-design-requests']);
     },error =>{
       console.log("error is ",error);
     });
    }

    upload(){
      document.getElementById('av').click();
    }
    upload1(){
      document.getElementById('av1').click();
    }

    nextStep(nextStep) {
      console.log("next step is ");
      // show save name modal 
      let dialogRef = this.dialog.open(SetColorReqDesignModalComponent, {
           height: '711px',
            width: '900px',
            data: {
              color:nextStep
          }
            
        
      });
      dialogRef.afterClosed().subscribe(result => { // New line
        console.log("result is ",result);
        this.colorArray = [];
        
        result.forEach(element =>{
          var newColorObj = {
            key:element.$key,
            colorCode:element.colorCode,
            colorName:element.colorName,
            editing:element.editing,
            order:element.order
          }
          
          this.colorArray.push(newColorObj);
        })
        this.disabledButtonContine = true;
        // this.colorArray = result; // New line. Need to declare answerFromModel in class
      });
    }

    deleteColors(color){
      this.colorArray.splice(this.colorArray.findIndex(item => item.colorName === color.colorName), 1)  
  }

  svgLoad(item){
    if(item){
      if(item.includes('.svg')){
        console.log("yes");
        var oContentArea = document.getElementById("contentarea1");
        if(oContentArea != null ){
          oContentArea.setAttribute('data', item);
        }
        return true;
      }else{
        return false;
      }
    }
  }

   



   
  }

  


