import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-make-payments',
  templateUrl: './make-payments.component.html',
  styleUrls: ['./make-payments.component.css']
})
export class MakePaymentsComponent implements OnInit, AfterViewInit, OnDestroy {
  //@ViewChild('cardInfo') cardInfo: ElementRef;
     //@ViewChild(ChildDirective, {static: false}) Component
//@ViewChild('cardInfo', {static: false}) component;
  // @ViewChild('cardInfo', {static: false}) ElementRef;
   @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  _totalAmount: number;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  constructor(
  	public dialogRef: MatDialogRef<MakePaymentsComponent>,
	public dialog: MatDialog,
	private toastr: ToastrService,
  	private cd: ChangeDetectorRef,
  	 @Inject(MAT_DIALOG_DATA) private data: any) {
  	this._totalAmount = data['totalAmount'];
  	 }

  ngOnInit() {
  }

  close(){
		this.dialogRef.close();
	}

  ngAfterViewInit() {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);  
  }
    ngOnDestroy() {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
    onChange({ error }) {
      if (error) {
        this.error = error.message;
      } else {
        this.error = null;
      }
      this.cd.detectChanges();
    }
    async onSubmit(form: NgForm) {
          const { token, error } = await stripe.createToken(this.card);
      if (error) {
        console.log('Something is wrong:', error);
      } else {
          console.log('Success!', token);
          this.dialogRef.close(token);
      }
    }

}
