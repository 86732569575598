import { Component, OnInit, ViewChild } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SubmissionsService } from '../../../services/submissions.service';

import 'fabric';
import { AuthService } from 'services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from "@angular/material";
// import { document } from 'ngx-bootstrap/utils/facade/browser';
// declare const fabric: any;

@Component({
  selector: 'create-admin-users',
  templateUrl: './create-admin-users.component.html',
  styleUrls: ['./create-admin-users.component.scss']
})

export class CreateAdminUsersComponent {
  private loading: boolean = false;
  private email: boolean = false;
  adminList: any;
  errorMessage1: any;
  displayedColumns: string[] = ['name', 'email', 'actions'];
  // customerTable: Customer[] = [];
  // employeeInfoTableDataSource = new MatTableDataSource(this.customerTable);
  constructor(private subService: SubmissionsService,
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {
    this.getAllAdminList();
  }

  getAllAdminList() {
    this.subService.getAllAdminList().subscribe(res => {
      this.adminList = res.json();
      console.log("this.adminList", this.adminList);
      this.loading = false;
    }, error => {
      let err = error.json();
      this.loading = false;
    });

  }
  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }
  createAdminUser() {
    this.loading = true;
    let email = <any>document.getElementById('email');
    let password = <any>document.getElementById('password');
    let fullname = <any>document.getElementById('fullname');
    if (!email.value) {
      this.errorMessage1 = "please enter email";
      this.loading = false;
      return;
    } else if (!password.value) {
      this.errorMessage1 = "please enter password";
      this.loading = false;
      return;
    } else if (!fullname.value) {
      this.errorMessage1 = "please enter fullname";
      this.loading = false;
      return;
    }
    var obj = {
      email: email.value,
      password: password.value,
      fullName: fullname.value
    }
    this.subService.registerAdmin(obj).subscribe(res => {
      let user = res.json();
      this.getAllAdminList();
      email.value = '';
      password.value = '';
      fullname.value = '';
      this.errorMessage1 = ''
      this.loading = false;
    }, error => {
      let err = error.json();
      this.errorMessage1 = err.errorMessage;
      this.loading = false;
    });
  }

  validatEmail() {
    this.errorMessage1 = '';
    let email = <any>document.getElementById('email');
    var reg = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)([\w-]+\.)+[\w-]{2,4})?$/
    console.log("email ", email.value)
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
      this.email = false;
    } else {
      this.email = true;
    }
  }

  deleteAdminUser(element) {
    var a = confirm("Are you sure you want to delete this user?")
    if (a) {
      this.loading = true;
      var obj = {
        userId: element._id
      }
  
      this.subService.deletedAdminUser(obj).subscribe(res => {
        this.getAllAdminList();
        this.toast.success('Deleted User Succesfully', '', { positionClass: 'toast-top-right' });
        this.loading = false;
      });
  }
  }
}
