import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import { CustomerWalletComponent } from 'app/customer-admin/customer-wallet/customer-wallet.component';

import { RouterModule } from '@angular/router';
import { MatToolbarModule, MatIconModule, MatSidenavModule, MatGridListModule,MatListModule, MatCheckboxModule,MatButtonModule, MatCardModule, MatTabsModule,MatFormFieldModule, MatInputModule, MatTableModule, MatMenuModule} from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';
import { AdminComponent } from 'app/admin/dashboard/admin.component';
import { AdminContactComponent } from 'app/admin/admin-contact/admin-contact.component';
import { SubmissionDetailComponent } from 'app/admin/submission-detail/submission-detail.component';
import { CustomerAccountSettingsComponent } from 'app/customer-admin/account-setttings/account-settings.component';
import { CustomerSupportComponent } from 'app/customer-admin/support/support.component';
import { CustomerOrderComponent } from 'app/customer-admin/order/order.component';
import { CustomerMyQuotesComponent } from 'app/customer-admin/myquotes/myquotes.component';
import { CustomerMyDesignRequestsComponent } from 'app/customer-admin/my-design-requests/my-design-requests.component';
import { CustomerMyDesignRequestsDetailsComponent } from 'app/customer-admin/my-design-requests/my-design-details/my-design-details.component';
import { CustomerCatalogComponent } from 'app/customer-admin/catalog/catalog.component';
import { MyCartComponent } from 'app/customer-admin/my-cart/my-cart.component';
import { CustomerRequestDesignComponent } from 'app/customer-admin/request-design/request-design.component';
import { CustomerDashboardComponent } from 'app/customer-admin/customer-dashboard/customer-dashboard.component';
import { CustomerOrderDetailsComponent } from './order/order-details/order-details.component';
import { CustomerMyQuotesDetailsComponent } from './myquotes/my-quotes-details/my-quotes-details.component';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatFormFieldModule,
        MatTabsModule,
        MatCheckboxModule,
        MatInputModule,
        MatMenuModule,
        MatExpansionModule,
        MatCardModule,
        RouterModule.forRoot([
            {
                path: 'locker-room', component: CustomerNavComponent,
                data: {
                    permissions: {
                        only: ['User'],
                        redirectTo: '/',
                        pathMatch: 'full'
                    }
                },
                children: [
                    {
                        path: 'submissions',
                        component: AdminComponent
                    },
                    {
                        path: 'support',
                        component: CustomerSupportComponent
                    },
                    {
                        path: 'submission-detail/:id',
                        component: SubmissionDetailComponent
                    },
                    {
                        path: 'wallet',
                        component: CustomerWalletComponent
                    },
                    {
                        path: 'account-settings',
                        component: CustomerAccountSettingsComponent
                    },
                    {
                        path: 'order',
                        component: CustomerOrderComponent
                    },
                    {
                        path: 'order-details',
                        component: CustomerOrderDetailsComponent
                    },
                    {
                        path: 'dashboard',
                        component: CustomerDashboardComponent
                    },
                    {
                        path: 'my-cart',
                        component: MyCartComponent
                    },
                    {
                        path: 'catalog',
                        component: CustomerCatalogComponent
                    },
                    {
                        path: 'request-design',
                        component: CustomerRequestDesignComponent
                    },
                    {
                        path: 'my-quotes',
                        component: CustomerMyQuotesComponent
                    },
                    {
                        path: 'my-design-requests',
                        component: CustomerMyDesignRequestsComponent
                    },
                    {
                        path: 'my-quotes-details',
                        component: CustomerMyQuotesDetailsComponent
                    },
                    {
                        path: 'my-design-details',
                        component: CustomerMyDesignRequestsDetailsComponent
                    },
                ]
            }
        ])
    ],
    declarations: [CustomerNavComponent],
    exports: [

    ],
})
export class CustomerAdminModule { }