
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe }  from '@angular/common';
import { SubmissionsService } from '../../../../../services/submissions.service';
declare var Snap: any;

@Component({
    selector: 'set-design',
    templateUrl: 'set-design.component.html',
    styleUrls: ['set-design.component.scss'],
})
export class SetDesignNameModalComponent {
    private loading: boolean = false;
    private loadingWhell: boolean = false;
    private abc:any;
    private allTemplates:any;
    private categoryName:any;
    todayDate : Date = new Date();
    myCartUser:any;
    orderProduct = [];
    total_order_cost_before_discount:any = 0 ;
    bulk_discount:any = 0;
    shipping_cost:any = 0;
    priceObject:any;

    constructor(
        private router: Router,
        private toast: ToastrService,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<SetDesignNameModalComponent>,
        private datePipe: DatePipe,
        private submissionService: SubmissionsService,
        @Inject(MAT_DIALOG_DATA) public data: any

        
    ) {
        
        
    }
    ngOnInit(){
        
    }

    
    
    close() {
        this.dialogRef.close();
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

    sendDesign(){
        var designName = <any>document.getElementById('designName');
        var obj = {
            requestId:this.data.requestID,
            imageUrl:this.uploadedImage,
            designName:designName.value
        }
        this.submissionService.sendDesignUpload(obj).subscribe(res => {
            document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
            this.toast.success('Design Upload Successfully', '', { positionClass: 'toast-top-right' });
            this.dialogRef.close(obj);

          },error =>{
            this.toast.error('Error uploading design', '', { positionClass: 'toast-top-right' });
              console.log("error is ",error);
            });
    }

    uploadFiles(){
        <any>document.getElementById('uploadFile').click();
    }
    
    onFileChange(event){
    this.loadingWhell = true;
    if (event.target.files.length != 0) {
        for(var i=0;i<event.target.files.length;i++){
        let file = event.target.files[i];
        this.handleReaderLoaded(file)
        }
    }
    }
   
    uploadedImage = null;
    async handleReaderLoaded(file) {
    this.toast.warning("Plase wait while uploading image.", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
    let uniqlogo1 = 'DesignImage' + (new Date().getTime());
        let storageRef = firebase.storage().ref();
    const fileData = await storageRef.child(`${'/upload-design-chat'}/${uniqlogo1}`).put(file)
        .then(snapshot => {
        return snapshot.ref.getDownloadURL();
        }).then(async (downloadURL) => {
        this.uploadedImage=downloadURL;
        this.loadingWhell = false;
        this.toast.success('Image. sucessfully upload/ed', '', { positionClass: 'toast-top-right' });
        }).catch(error => {
        this.toast.error("Faild to upload image. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
        });
    }

}


