import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.css']
})



export class ManageCategoriesComponent implements OnInit {



  constructor(private db: AngularFireDatabase,
    private toast: ToastrService) {

  }
  newCat = ''
  catList = [];
  updateCategory: any;
  ngOnInit() {
    this.getAllCategory();
  }
  getAllCategory() {
    this.db.list('/template-categories').snapshotChanges().subscribe(data => {
      this.catList = [];
      data.forEach(action => {
        const value = action.payload.val();
        let newCat = {
          key: action.key,
          name: value['name'],
          nameNoSpace: value['nameNoSpace'],
          image: value['image'],
          order: value['order']
        }
        this.catList.push(newCat);
      });
      this.catList.sort((a, b) => a.order - b.order);
    })
  }

  add() {
    let noSpace = this.newCat.replace(' ', '');

    let newCat = {
      name: this.newCat,
      nameNoSpace: noSpace
    }
    this.db.list('/template-categories').push(newCat).then(res => {
      this.newCat = ''
      this.toast.success("New category added", "Success")
    })
  }

  remove(cat) {
    let a = confirm("Are you sure you want to delete this category?")
    if (a) {
      let key = cat.key;
      this.db.list("/template-categories").remove(key).then(_ => {
      });
    }

  }

  addThumbnail(cat) {
    this.updateCategory = cat;
    document.getElementById('addThumb').click();
  }
  onFileChange(event) {
    if (event.target.files.length != 0) {
      let file = event.target.files[0];
      this.handleReaderLoaded(file, this.updateCategory)
    }
  }

  thumbnailImage = null;
  async handleReaderLoaded(file, cat) {
    this.toast.warning("Plase wait while uploading image.", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
    let uniqlogo1 = 'categorythumbnails' + (new Date().getTime());
    let storageRef = firebase.storage().ref();
    const fileData = await storageRef.child(`${'/category-thumbnails-images'}/${uniqlogo1}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        let key = cat.key;
        delete cat.key;
        cat['image'] = downloadURL;
        let update = this.db.object(`/template-categories/${key}`).update(cat);
        this.getAllCategory();
        this.thumbnailImage = downloadURL;
        this.toast.success('Image. sucessfully upload/ed', '', { positionClass: 'toast-top-right' });
      }).catch(error => {
        this.toast.error("Faild to upload image. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
      });
  }


  drop(event: CdkDragDrop<string[]>) {
    let category = this.catList[event.previousIndex];
    moveItemInArray(this.catList, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.catList.forEach((category, index) => {
        category.order = index;
        let key = category.key;
        delete category.key;
        let update = this.db.object(`/template-categories/${key}`).update(category);
      });
    }
  }


}
