import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AngularFireDatabase } from 'angularfire2/database';
import { SubmissionsService } from 'services/submissions.service';
import { SignInModalComponent } from "app/modals/sign-in/sign-in.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: 'admin-nav-bar',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent implements OnInit {
  isAdmin: boolean;
  role: any;
  email:any;
  isMenuOpen:boolean = true;
  builderConfiguration:any;
  constructor(public router: Router, private dialog: MatDialog,private auth: AuthService, private permissionsService: NgxPermissionsService, private db: AngularFireDatabase,private submissionService: SubmissionsService) {
    this.isAdmin = true;
    this.role = localStorage.getItem('userrole');
    this.email=localStorage.getItem('emailForSignIn');
    if (localStorage.getItem('ulCompanyLogo') == null)
      this.getMyLogo();
    else  
      this.myLogo = localStorage.getItem('ulCompanyLogo');

  }

  ngOnInit() {
    console.log()
    // this.auth.menuSub.subscribe(res => {
    //   alert(res)
    // })

    if(window.location.href.split('/admin')[1]){

    }else{
      this.router.navigate(['/']);
      this.signInForUpload('submission');
    }
    this.submissionService.getConfiguration().subscribe(res => {
      this.builderConfiguration = res.json(); 
     });
    
  }

  signInForUpload(redirected) {
    this.dialog.open(SignInModalComponent, {
        data: {
            redirected: redirected
        }
    })
  }
  collpaseSideBar(){
    this.isMenuOpen = !this.isMenuOpen;
    if(!this.isMenuOpen){
      document.getElementById("menu1").style.width = "60px";   
      document.getElementById("sideMenu1").style.margin = "0px 0px 0px 60px";
    }else{
      document.getElementById("menu1").style.width = "280px";   
      document.getElementById("sideMenu1").style.margin = "0px 0px 0px 280px";
    }
  }
  logout() {
    if (localStorage.getItem('userrole') == 'Admin')
      this.router.navigate(['/admin/login']);
    else
      this.router.navigate(['/customer/login']);
    localStorage.clear();
    // this.permissionsService.flushPermissions();

  }

  myLogo = '';
  myInfo: any; 
  getMyLogo() {
    this.db.object('/company-info/myInfo').snapshotChanges().subscribe(data => {
      let object = data.payload.val()
      this.myInfo = object;
      this.myLogo = object['logoPath'];
      window.localStorage.setItem('ulCompanyLogo', this.myLogo);
      window.localStorage.setItem('ul', this.myLogo);
    }, err => {
      this.myLogo = null;
    })
  }

  getEmail(){
    let a = this.auth.currentUser();
    console.log("CURRENT USER")
    console.log(a)
  }

  gotoBuilder(){
    console.log("***********************************");
    
    this.router.navigate(['/']);
    setTimeout(() => {
      document.getElementById('backtobuilder').click();
    }, 1000);
    
}

}
