
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
declare var Snap: any;

@Component({
    selector: 'send-email-dialog',
    templateUrl: 'send-email.component.html',
    styleUrls: ['send-email.component.scss'],
})
export class SendEmailModalComponent {
    private loading: boolean = false;

    constructor(
        private toast: ToastrService,
        private fb: FormBuilder,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<SendEmailModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.form = this.fb.group({
            fileType: ['', Validators.required],
            email: ['', Validators.required],
        });
    }
    form: FormGroup;

    close() {
        this.dialogRef.close();
    }





    async sendEmail() {
        if (this.form.value.fileType == 1) {
            this.sendPdf();
        }
        else {
            this.sendDesing();
        }

    }


    async sendPdf() {
        let storageRef = firebase.storage().ref();
        let uniqPdf = 'pdf' + new Date().getTime();
        this.startLoadingSpinner();

        const fileData = await storageRef.child(`/pdfs/${uniqPdf}`).put(this.data.pdf)
            .then(snapshot => {
                return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
            }).then(async (downloadURL) => {
                var newCategori = {
                    fileType: this.form.value.fileType,
                    email: this.form.value.email,
                    pdfUrl: downloadURL
                }
                this.af.list("/emails").push(newCategori);
                this.loading = false;
                this.close();
                this.toast.success('Email sent', '', { positionClass: 'toast-top-right' });

            })
            .catch(error => {
                this.loading = false;
                this.close();
                this.toast.error("Error while sending email", '', { timeOut: 10000, positionClass: 'toast-top-right' });

            });
    }


    async   sendDesing() {
        let storageRef = firebase.storage().ref();
        let uniqPdfHome = 'imageHome' + new Date().getTime();
        this.startLoadingSpinner();

        const fileData = await storageRef.child(`/pdfs/${uniqPdfHome}`).putString(this.data.home,'data_url')
            .then(snapshot => {
                return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
            }).then(async (home) => {
                if (this.data.away == "" || this.data.away == undefined) {
                    var newCategori = {
                        fileType: this.form.value.fileType,
                        email: this.form.value.email,
                        homeUrl: home
                    }
                    this.af.list("/emails").push(newCategori);
                    this.loading = false;
                    this.close();
                    this.toast.success('Email sent', '', { positionClass: 'toast-top-right' });

                } else {
                    let uniqPdfAwy = 'imageAway' + new Date().getTime();
                    await storageRef.child(`/pdfs/${uniqPdfAwy}`).putString(this.data.home,'data_url')
                        .then(snapshot => {
                            return snapshot.ref.getDownloadURL();
                        }).then(async (downloadURL) => {
                            var newCategori = {
                                    fileType: this.form.value.fileType,
                                email: this.form.value.email,
                                homeUrl: home,
                                awayUrl: downloadURL
                            }
                            this.af.list("/emails").push(newCategori);
                            this.loading = false;
                            this.close();
                            this.toast.success('Email sent', '', { positionClass: 'toast-top-right' });

                        })
                        .catch(error => {
                            this.loading = false;
                            this.close();
                            this.toast.error("Error while sending email", '', { timeOut: 10000, positionClass: 'toast-top-right' });

                        });
                }
                this.af.list("/emails").push(newCategori);
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                this.toast.error("Error while sending email", '', { timeOut: 10000, positionClass: 'toast-top-right' });

            });
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

}


