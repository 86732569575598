import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import { MatTableDataSource } from "@angular/material";
import { EditShippingComponent } from '../../../dialogs/edit-shipping/edit-shipping.component';
import { ImportRosterComponent } from '../../../dialogs/import-roster/import-roster.component';
import { SelectDesignComponent } from '../../../dialogs/select-design/select-design.component';

import {MatListModule} from '@angular/material/list'; 
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {MatTableModule} from '@angular/material/table'; 
import {NgForm} from '@angular/forms';
import {MatInputModule} from '@angular/material/input'; 
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AddCardComponent } from '../../account-setttings/add-card/add-card.component';
import { MakePaymentsComponent } from '../../../dialogs/make-payments/make-payments.component';

import { SubmissionsService } from '../../../../services/submissions.service';

import { CreateNewPurchaseOrderComponent } from '../../../dialogs/create-new-purchase-order/create-new-purchase-order.component';


@Component({
  selector: 'app-customer-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class CustomerOrderDetailsComponent implements OnInit {
  orderDetails:any;
  orderId : any;
  displayedColumns: string[] = ['Order', 'Status', 'Price', 'Actions'];
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;

  rosterTableDATA = [
  {Item: {img:'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name : 'Duffle Bags'}, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit : 'Edit Roster'},
  {Item: {img:'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name : 'Duffle Bags'}, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit : 'Edit Roster'},
  {Item: {img:'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name : 'Duffle Bags'}, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit : 'Edit Roster'},
  {Item: {img:'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name : 'Duffle Bags'}, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit : 'Edit Roster'}
];
 rosterTableColumns: string[] = ['Item', 'Quantity', 'Size', 'Roster'];
  rosterTableSource:any;




  editRoster:boolean = false;
  editRosterItemData : any;
  enableName : boolean = true;
  enableNumber : boolean = true;
  enableSize : boolean = true;
  enableUppercase : boolean = true;
 

  orderForm: FormGroup;
  rostersData: FormArray;

  totalQuantities : number = 0;

  selectedAddress:any;
  orderNotes = [];
  notesToAdd:any;
  shippingAddress:any;
  billingAddress:any;
  userRole : any;
  order_status :any;
  allOrderStatus : any;
  private loading: boolean = false;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    // private nav: CustomerNavComponent,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private submService : SubmissionsService
  ) {

   }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    this.orderId = window.location.href.split('?orderId=')[1];
    this.getOrderData(); 
    this.userRole = window.localStorage.getItem('userrole');
    console.log(this.userRole);
   this.orderForm = this.formBuilder.group({
      rostersData: this.formBuilder.array([ this.createItem() ])
    });
   this.orderStatus();

 

  }

   get f() { return this.orderForm.controls; }
   get t() { return this.f.rostersData as FormArray; }
    
  
    onSubmit() {
        // stop here if form is invalid
        if (this.orderForm.invalid) {
            return;
        }
        let rosterDataToSave = {
          'orderId' : this.orderId,
          'productKey' : this.editRosterItemData.productForeignKey,
          'rosterArray' : this.orderForm.value.rostersData
        };
        
        this.startLoadingSpinner();
        this.loading = true;
        this.submService.saveRoster(rosterDataToSave)
           .subscribe(data => {
             console.log(data);
             this.toastr.success('Success!', 'Roster Added Successfully!')
              this.loading = false;
            });

        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.orderForm.value, null, 4));
        // console.log(JSON.stringify(this.orderForm.value, null, 4));
    }


  createItem(): FormGroup {
    return this.formBuilder.group({
      playerName: '',
      playerNumber: '',
      topSize: '',
      shortSize : ''
    });
  }
 addOneRow = () : void => {

  //addOneRow(): void {
      this.t.push(this.formBuilder.group({
          playerName: '',
          playerNumber: '',
          topSize: '',
          shortSize : ''
      }));
      for(let i in this.orderDetails.data.products){
        if(this.orderDetails.data.products[i].productForeignKey == this.editRosterItemData.productForeignKey){
          let old_qty = this.orderDetails.data.products[i].quantity;
          this.orderDetails.data.products[i].quantity = old_qty+1; 
        }
      }

  }

  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  getOrderData(){
    this.startLoadingSpinner();
    this.loading = true;
    this.submService.getOrderListById(this.orderId).subscribe(res => {
      this.orderDetails = JSON.parse((<any>res)._body);
      console.log('a',this.orderDetails);
      this.orderNotes = this.orderDetails.notes;
      this.shippingAddress = this.orderDetails.shippingAddress;
      this.billingAddress = this.orderDetails.billingAddress;
      this.rosterTableDATA = this.orderDetails.data.products;
      this.order_status = this.orderDetails.status;
      console.log(this.rosterTableDATA,this.order_status);
         this.loading = false;
     });
    }


  goToOrderDetails(){
    alert("Order details")
  }

  editShipping(type): void {
    const dialogRef = this.dialog.open(EditShippingComponent, {
      width: '450px',
      data: {addr : type == 'shipping' ? this.shippingAddress : this.billingAddress, type : type}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        let storeSelectedAddress;
        if(type == 'shipping'){
          storeSelectedAddress = {
            'orderId' : this.orderId,
            'shippingAddress' : result
          };
          this.shippingAddress = result;
        }else{
          storeSelectedAddress = {
            'orderId' : this.orderId,
            'billingAddress' : result
          };
          this.billingAddress = result;
        }
        this.submService.addShippingOrBillingAddress(storeSelectedAddress,type)
           .subscribe(data => {
             console.log(JSON.parse((<any>data)._body));
        });
        console.log(result);
       
      }
        console.log('The dialog was closed');
    });
  }

  importRosters(): void {
    const dialogRef = this.dialog.open(ImportRosterComponent, {
      width: '650px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  removeRow(delItem){
    this.t.removeAt(delItem);
    for(let i in this.orderDetails.data.products){
        if(this.orderDetails.data.products[i].productForeignKey == this.editRosterItemData.productForeignKey){
          if(this.orderDetails.data.products[i].quantity != 0){
            let old_qty = this.orderDetails.data.products[i].quantity;
          this.orderDetails.data.products[i].quantity = old_qty-1; 
          }else{
            this.orderDetails.data.products[i].quantity = 0;
          }
        }
      }
      console.log(this.orderDetails.data.products);
  }

  selectDesign(): void {
    const dialogRef = this.dialog.open(SelectDesignComponent, {
      width: '650px',
      data: this.orderDetails
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  updateQuantity(){
    let total = 0;
    this.orderForm.value.rostersData.forEach(x => {
      
          total += +x.shortSize;
    });
    this.totalQuantities = total;
    console.log(total);
  }

  addNewCard(): void {
    const dialogRef = this.dialog.open(MakePaymentsComponent, {
      width: '600px',
      data: {totalAmount: this.orderDetails.data.priceData.final_total},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.id){
        this.startLoadingSpinner();
        this.loading = true;
        this.submService.orderPayments(this.orderId,result.id).subscribe(res => {
           console.log(JSON.parse((<any>res)._body));
           this.toastr.success('Success!', 'Payment Added Successfully!');
           this.order_status = 'ORDER RECIEVED';
           this.loading = false;
         });
      }
      console.log(result);
    });
  }

  

  addNotes(notesForm: NgForm){
    console.log(notesForm.value.notesToAdd);
    this.orderNotes.push(notesForm.value.notesToAdd);
    if(notesForm.value.notesToAdd != ''){
      this.startLoadingSpinner();
      this.loading = true;
      let noteToStore = {
        "orderId" : this.orderId,
        "note" : notesForm.value.notesToAdd
      };
      this.submService.addorderNote(noteToStore).subscribe(res => {
        console.log('n',JSON.parse((<any>res)._body));
           this.loading = false;
           notesForm.reset();
           this.toastr.success('Success!', 'Note Added Successfully!');
       });
    }else{
      this.toastr.error('Error!', 'Please write note first!');
      return false;
    }
  }

  editRosterItem(item){
    //console.log(this.t);
    this.t.clear();
    let p_key = item.productForeignKey;
    if(this.orderDetails.rosterData[p_key]){
      for(let i in this.orderDetails.rosterData[p_key]){
        this.t.push(this.formBuilder.group({
            playerName:this.orderDetails.rosterData[p_key][i].playerName,
            playerNumber:this.orderDetails.rosterData[p_key][i].playerNumber,
            topSize:this.orderDetails.rosterData[p_key][i].topSize,
            shortSize :this.orderDetails.rosterData[p_key][i].shortSize
        }));
      }
    }else{
      for(let i = 0; i  < item.quantity; i++){
        this.t.push(this.formBuilder.group({
            playerName:null,
            playerNumber:null,
            topSize:null,
            shortSize :null
        }));
      }
    }

    this.updateQuantity();
    this.editRosterItemData = item;
    this.editRoster = !this.editRoster;
  }

  changeOrderStatus(ev){
    this.startLoadingSpinner();
    this.loading = true;
    let status = ev.value;
    this.submService.changeOrderStatus(this.orderId,status).subscribe(res => {
      this.order_status = JSON.parse((<any>res)._body).status;
       console.log(JSON.parse((<any>res)._body).status);
       this.loading = false;
     });
  }

  orderStatus(){
    this.startLoadingSpinner();
    this.loading = true;
    this.submService.getOrderStatus().subscribe(res => {
      this.allOrderStatus = JSON.parse((<any>res)._body);
       console.log(JSON.parse((<any>res)._body));
       this.loading = false;
     });
  }

  createPO(){
    const dialogRef = this.dialog.open(CreateNewPurchaseOrderComponent, {
      width: '600px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  

}
