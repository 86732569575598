
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe }  from '@angular/common';
import { SubmissionsService } from 'services/submissions.service';
declare var Snap: any;

@Component({
    selector: 'set-order-name-dialog',
    templateUrl: 'set-order-name.component.html',
    styleUrls: ['set-order-name.component.scss'],
})
export class SetOrderNameModalComponent {
    private loading: boolean = false;
    private abc:any;
    private allTemplates:any;
    private categoryName:any;
    todayDate : Date = new Date();
    myCartUser:any;
    orderProduct = [];
    total_order_cost_before_discount:any = 0 ;
    bulk_discount:any = 0;
    shipping_cost:any = 0;
    priceObject:any;

    constructor(
        private router: Router,
        private toast: ToastrService,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<SetOrderNameModalComponent>,
        private datePipe: DatePipe,
        private submissionService: SubmissionsService,
        @Inject(MAT_DIALOG_DATA) public data: any

        
    ) {
        this.af.list('/products').snapshotChanges().subscribe(styles => { 
            this.allTemplates = [];
            styles.forEach(element => {
             this.allTemplates.push(element.payload.val());
        
         });
         let current = this;
         this.categoryName = this.data.category;
         this.abc = this.allTemplates.filter(element => element.category==current.data.category); 
        });
        
    }
    ngOnInit(){
       
        this.data.productList.forEach(element => {
            if(this.data.isQuotes){
                var orderProduct = {
                    productForeignKey: element.key,
                    quantity:element.quantity,
                    productName:element.productName,
                    costPerProduct:element.costPerProduct,
                    category:element.category,
                    email:element.email,
                    image:element.productImg,
                    sku:element.sku,
                    tbulkDiscount:element.bulkdiscount,
                    totalPrice:element.totalPrice,
                    submissionId:element.submission_id,
                    shippingCost:element.shippingCost
                }
                this.total_order_cost_before_discount = parseFloat(this.total_order_cost_before_discount) + parseFloat(element.totalPrice);
                this.bulk_discount = parseFloat(this.bulk_discount) + parseFloat(element.bulkdiscount);
                this.shipping_cost = parseFloat(this.shipping_cost) + (parseFloat(element.shippingCost) * parseFloat(element.quantity)); 
                this.orderProduct.push(orderProduct);
            }else{
                var orderProduct = {
                    productForeignKey: element.$key,
                    quantity:element.quantity,
                    productName:element.name,
                    costPerProduct:element.price,
                    category:element.category,
                    email:element.email,
                    image:element.image,
                    sku:element.sku,
                    tbulkDiscount:element.tbulkDiscount,
                    totalPrice:element.totalPrice,
                    submissionId:element.submission_id,
                    shippingCost:element.shippingCost
                }
                this.total_order_cost_before_discount = parseFloat(this.total_order_cost_before_discount) + parseFloat(element.totalPrice);
                this.bulk_discount = parseFloat(this.bulk_discount) + parseFloat(element.tbulkDiscount);
                this.shipping_cost = parseFloat(this.shipping_cost) + (parseFloat(element.shippingCost) * parseFloat(element.quantity)); 
                this.orderProduct.push(orderProduct);
            }
            

        })
        let finalOrder=this.total_order_cost_before_discount - this.bulk_discount + this.shipping_cost;
        this.priceObject = {
            total_order_cost_before_discount:this.total_order_cost_before_discount,
            bulk_discount: this.bulk_discount.toFixed(2),
            shipping_cost:this.shipping_cost.toFixed(2),
            final_total: finalOrder.toFixed(2)
        }

        console.log("this.priceObject",this.priceObject);
    }

    addCardProduct(productName){
        this.dialogRef.close();
        productName['email'] = localStorage.getItem('emailForSignIn');
        this.af.list("/mycart").push(productName);
        this.router.navigate(['/locker-room/my-cart']); 
    }
    
    close() {
        this.dialogRef.close();
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

    createOrder(){
        let current = this;
        var OrderName = <any>document.getElementById('orderName');
        let todayData1 = this.datePipe.transform(this.todayDate, 'medium');
        var newOrder = {
            orderName: OrderName.value,
            orderNumber: Math.floor(100000 + Math.random() * 900000),
            orderTotal: current.data.totalPrice,
            dateCreated : todayData1,
            products:this.orderProduct,
            priceData:this.priceObject,
            quoteID:current.data.quoteID
          }
          this.loading = true;
          this.submissionService.saveOrder(newOrder).subscribe(res => {
            let user = JSON.parse((<any>res)._body);
            this.dialogRef.close();
            this.router.navigate(['/locker-room/order-details'],{ queryParams: { orderId: user._id} })
          });
    }
    

}


