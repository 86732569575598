import { Component, OnInit, Inject } from '@angular/core';
import {MatInputModule} from '@angular/material/input'; 
import {NgForm} from '@angular/forms';
import { SubmissionsService } from '../../../services/submissions.service';
import { FormBuilder, FormGroup ,FormControl,Validators} from '@angular/forms';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-shipping-address',
  templateUrl: './add-shipping-address.component.html',
  styleUrls: ['./add-shipping-address.component.css']
})
export class AddShippingAddressComponent implements OnInit {
	private loading: boolean = false;
  addressData : any;
  editAddressData:any;
  addressForm:any;
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private submService : SubmissionsService,public dialogRef: MatDialogRef<AddShippingAddressComponent>) {
          this.form = this.fb.group({
          name: ['', Validators.required],
          address1: ['', Validators.required],
          address2: ['', Validators.required],
          city: ['', Validators.required],
          country: ['',Validators.required],
          state:['',Validators.required],
          zip:['',Validators.required],
          });
         if(data.edit=='edit'){
           this.editAddressData = data;
           console.log('data',data);
           this.form.controls['name'].setValue(data.name)
            this.form.controls['address1'].setValue(data.address1)
            this.form.controls['address2'].setValue(data.address2)
            this.form.controls['city'].setValue(data.city)
            this.form.controls['country'].setValue(data.country)
            this.form.controls['state'].setValue(data.state)
            this.form.controls['zip'].setValue(data.zip)
         } 
       }

  ngOnInit() {
  }

  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  
  addAddress(addressForm: NgForm) {
    this.addressData = addressForm.value;
  	this.startLoadingSpinner();
    this.loading = true;
    this.submService.addAddress(this.addressData)
       .subscribe(data => {
          this.loading = false;
          this.dialogRef.close(JSON.parse((<any>data)._body));
        });
  }

  editAddress(){
    var obj = {
      addressId: this.data._id,
      name: this.form.value.name,
      address1: this.form.value.address1,
      address2: this.form.value.address2,
      city: this.form.value.city,
      zip:this.form.value.zip,
      state:this.form.value.state,
      country: this.form.value.country
    }
    this.loading = true;
    this.submService.updateAddress(obj)
       .subscribe(res => {
        this.toastr.success('Added Update Successfully', '', { positionClass: 'toast-top-right' });
        // setTimeout(() => {
          this.loading = false;
        // }, 2000);
        this.dialogRef.close('update');
      });
  }
}
