import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor.component';
import { AdminComponent } from '../admin/dashboard/admin.component';
import { AddStyleComponent } from '../admin/add-style/add-style.component';
import { SubmissionDetailComponent } from '../admin/submission-detail/submission-detail.component';
import { SelectUniformComponent } from '../select-uniform/select-uniform.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
// import { ColorPickerModule } from 'angular2-color-picker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { colorComponent } from '../color-implementation/color.component';
import { patternComponent } from '../pattern-implementation/pattern.component';

import { graphicoptionComponent } from '../graphicoption/graphicoption.component';
import { ImageUploadModule } from "angular2-image-upload";
import { AlertComponent } from 'app/alert-component/alert.component';
import { SendEmailComponent} from 'app/email/send-email.component';
import { HomePageComponent } from '../home-page/home-page.component';
import { HomeComponent } from '../dashboard/dashboard.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { EditorRoutes } from './editor.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingComponent } from 'app/Loader/loader.component';
import { Ng5SliderModule } from 'ng5-slider';
import { MatToolbarModule, MatTabsModule } from '@angular/material';
// import {  MatSelectModule } from '@angular/material';

// const routes: Routes = [
//   {
//     path: '',
//     component: EditorComponent
//   }
// ];
console.log('sssss111sssssss',EditorRoutes);
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMultiSelectModule,
    MatToolbarModule,
    // ColorPickerModule,
    // RouterModule.forChild(routes),
    RouterModule.forChild(EditorRoutes),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ImageUploadModule.forRoot(),
    BrowserAnimationsModule,
    MatTabsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    // MatSelectModule
  ],
  declarations: [
      EditorComponent,
      SelectUniformComponent, 
      colorComponent,
      patternComponent,
      graphicoptionComponent,
      AlertComponent,
      SendEmailComponent,
      HomeComponent,
      // LoadingComponent
    ]
    

})
export class EditorModule { 
  
}
