import { Component, Input } from '@angular/core';
import {AlertService} from "./alert.service";
@Component({
   selector: 'alert',
   templateUrl: 'alert.component.html',
})

export class AlertComponent {
   message: any; 
   isHide:boolean = false;
   isShow:boolean = false;   
   isSubmitted:boolean = false;
   constructor(private alertService: AlertService) {
   }
  
  ngOnInit() {
   //this function waits for a message from alert service, it gets 
   //triggered when we call this from any other component
   this.alertService.getMessage().subscribe(message => {
       this.message = message; 
       if(this.message!=undefined)
       {
       if(this.message.text=='Thank you')
       {
        this.isHide=true;
        this.isShow=false;
        this.isSubmitted=false;
       }else if(this.message.text.includes('Thank you')){
        this.isSubmitted=true;
        this.isHide=false;
        this.isShow=true;
       }else{
        this.isHide=false;
        this.isShow=true;
        this.isSubmitted=false;
       }
     }
   });
 }
}