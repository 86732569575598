import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs/Observable';
import { MatDialog } from "@angular/material";
// import { error } from 'protractor';
import { async, ComponentFixture, TestBed } from '@angular/core/testing';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import * as firebase from 'firebase/app'; 
import { SignInModalComponent } from "app/modals/sign-in/sign-in.component";


@Component({
    selector: 'admin-login',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.scss']
})

export class AdminLoginComponent {

    adminLoginForm: FormGroup;
    // submissions: FirebaseListObservable<any[]>;
    private loading: boolean = false;
    user: Observable<any>;
    email: string;
    emailSent = false;
    emailflag = false;
    errorMessage: string;
    emailnotexist: boolean = false;
    constructor(private formBuilder: FormBuilder,
        private db: AngularFireDatabase,
        private route: ActivatedRoute,
        private router: Router,
        private afAuth: AngularFireAuth,
        private as: AuthService,
        private toastr: ToastrService,
        private permissionsService: NgxPermissionsService,
        private dialog: MatDialog

    ) {

    }

    ngOnInit() {
        this.user = this.afAuth.authState;
        const url = this.router.url;
        // this.signInForUpload('submission');
        this.createAdminLoginForm();
        this.confirmSignIn(url);
        document.getElementsByTagName('body')[0].setAttribute('style','overflow: hidden');
        
    }

    createAdminLoginForm() {
        this.adminLoginForm = this.formBuilder.group({
            email: ''
        });
    }

    async sendEmailLink() {
        // const actionCodeSettings = {
        //     // Your redirect URL
        //     url: 'https://localhost:4200/customer/login', 
        //     handleCodeInApp: true,
        //   };
        // try {
        //   await (<any>this.afAuth.auth).sendSignInLinkToEmail( 
        //     this.email,
        //     actionCodeSettings
        //   );
        //   window.localStorage.setItem('emailForSignIn', this.email);
        //   this.emailSent = true;
        // } catch (err) {
        //   this.errorMessage = err.message;
        //   console.log(err)
        // }
    }



    login(loginForm) {
        if (loginForm.email != "" && loginForm.email != null) {
            this.email = loginForm.email;
            this.sendEmailLink();
        }
        // this.as.login(loginForm.value.email, loginForm.value.pass);
    }


    async confirmSignIn(url) {
        try {
            if (this.afAuth.auth.isSignInWithEmailLink(url)) {
                let email = window.localStorage.getItem('emailForSignIn');
                // If missing email, prompt user for it
                if (!email) {
                    email = window.prompt('Please provide your email for confirmation');
                }
                // Signin user and remove the email localStorage
                const result = await this.afAuth.auth.signInWithEmailLink(email, url);
                // window.localStorage.removeItem('emailForSignIn');
            }
        } catch (err) {
            this.errorMessage = err.message;
            console.log(err)
        }
    }

    async adminLogin(value) {
        // this.emailflag = false;
        // const actionCodeSettings = {
        //     // Your redirect URL
        //    // url: 'https://localhost:4200/customer/submissions',
        //     //  url: 'https://uniform-e640f.firebaseapp.com/customer/submissions',
        //     url: 'https://str8sports.uniformlab.net/customer/submissions',
        //     handleCodeInApp: true,
        // };
        // try {
        //     let result = await this.afAuth.auth.sendSignInLinkToEmail(
        //         value.email,
        //         actionCodeSettings
        //     ).then(result => {
        //     });
        //     const perm = ['Admin'];
        //     this.permissionsService.loadPermissions(perm);
        //     this.emailSent = true;
        //     this.loading = false;
        //     this.emailflag = true;
        //     this.toastr.success('Please check your email for a link to sign in.', '', { timeOut: 7000 });
        // } catch (err) {
        //     localStorage.clear();
        //     this.errorMessage = err.message;
        //     this.emailflag = false;
        //     console.log(err);
        //     this.toastr.error(err.message, '', { timeOut: 4000 });
        // }

        if (value.email == undefined || value.email == "") return;
        this.startLoadingSpinner();
        this.emailflag = false;
        this.emailnotexist = false;
        let ref = firebase.database().ref('submissions');
        const me = this;
        await ref.orderByChild("email").equalTo(value.email).once("value", snapshot => {
            if (snapshot.exists()) {
                window.localStorage.setItem('emailForSignIn', value.email);
                window.localStorage.setItem('userrole', 'User');
               this.as.currentUser();
                const perm = ['Admin'];
                me.permissionsService.loadPermissions(perm);
                me.emailSent = true;
                me.loading = false;
                me.emailflag = true;
                this.emailnotexist = false;
                me.toastr.success('Logged in successfully.', '', { timeOut: 7000,positionClass : 'toast-top-right' });
                me.router.navigate(['/locker-room/submissions']);
            }
            else {
                me.errorMessage = 'Email does not match any submissions.';
                this.emailflag = false;
                me.toastr.error(me.errorMessage, null, { onActivateTick: true,timeOut: 7000 ,positionClass : 'toast-top-right'});
                me.router.navigate(['/customer/login']);
                this.emailnotexist = true;
                me.loading = false;
                localStorage.clear();
            }
        })
            .catch(error => {
                this.errorMessage = 'Something went wrong';
                this.emailflag = false;
                me.toastr.error('Something went wrong. Could not fetch submissions. Contact support.', null, {onActivateTick: true, timeOut: 7000,positionClass : 'toast-top-right' });
                me.loading = false;
                this.emailnotexist = true;
                localStorage.clear();
                me.router.navigate(['/customer/login']);
            });

    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d['toGMTString']();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    startLoadingSpinner() {

        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

    signInForUpload(redirected){
        this.dialog.open(SignInModalComponent, {
           data: {
             redirected:redirected
         }
      })
      }


}