import { Component, OnInit, Inject } from '@angular/core';
import {MatListModule} from '@angular/material/list'; 
import {MatRadioModule} from '@angular/material'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
import { AddShippingAddressComponent } from '../../dialogs/add-shipping-address/add-shipping-address.component';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SubmissionsService } from '../../../services/submissions.service';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { ShopifyService } from 'services/shopify.service';
import { DatePipe }  from '@angular/common';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
@Component({
  selector: 'app-shopify-products',
  templateUrl: './shopify-products.component.html',
  styleUrls: ['./shopify-products.component.css']
})
export class ShopifyProductsComponent implements OnInit {
  allAddresses = [
				 ];
 selectedIndex:any;
 selectedAddress:any;
 selectedAddressType:any;
 private loading: boolean = true;
 selected = 'none';
 private collectionList = [];
 private shopifyList = [];
 displayedColumns2:string[] = ['img', 'name', 'action'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  				public dialog: MatDialog,
  				private toastr: ToastrService,
          private submService : SubmissionsService,
          private shopifyService : ShopifyService,
          public dialogRef: MatDialogRef<ShopifyProductsComponent>,
          private route: ActivatedRoute, 
          private router: Router,) {
           }

  ngOnInit() {
    this.getAllCollections();
    this.getShopifyProduct();
  }

  getShopifyProduct(){
    this.loading = true;
    this.shopifyService.getAllShopifyProduct().subscribe(data => {
     this.shopifyList = [];
       this.shopifyList = data.json();
       console.log("this.shopifyList",this.shopifyList);
       this.loading = false;
    });
   }

  getAllCollections(){
    this.loading = true;
    this.shopifyService.getAllCollection().subscribe(data => {
      this.collectionList = [];
      this.collectionList = data.json();
      this.collectionList = this.collectionList.filter(element => {
        return element.isChecked == true;
      });
      setTimeout(() => {
        // this.loading = false;
      }, 2000);
      
    });
   }

   applyFilter(ev){
  this.loading = true;
   if(ev.value=="none"){
     this.getShopifyProduct();
   }else{
     var obj = {
       collectionId:ev.value
       }
       this.shopifyService.filterByTitleShopify(obj).subscribe(data => {
         this.shopifyList = data.json();
         this.loading = false;
       });
   }
  }
  

  redirectModal(id){
    this.dialogRef.close(id);
    setTimeout(() => {
      this.router.navigate(['/admin/add-new-product'],{queryParams:{shopifyId:id}});
      this.loading = false;
    }, 200);
    // [routerLink]="['/admin/add-new-product']" 
    // this.router.navigate(['/admin/my-quotes-details'],{ queryParams: { quotesId: element._id} })
  }
}
