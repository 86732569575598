import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CoreModule } from 'app/core/core.module';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { ShellComponent } from 'app/core/shell/shell.component';
import { AdminComponent } from 'app/admin/dashboard/admin.component';
import { AdminNavComponent } from 'app/admin/admin-nav/admin-nav.component';
import { ManageStylesComponent } from 'app/admin/manage-styles/manage-styles.component';
import { StyleDetailComponent } from 'app/admin/style-detail/style-detail.component';
import { ManageColorsComponent } from 'app/admin/manage-colors/manage-colors.component';
import { AddStyleComponent } from 'app/admin/add-style/add-style.component';
import { AddNewStyleComponent } from 'app/admin/add-new-style/add-new-style.component';
import { AddNewStyleV2Component } from 'app/admin/add-new-style-v2/add-new-style-v2.component';
import { AddNewProductComponent } from 'app/admin/add-new-product/add-new-product.component';

import { MatToolbarModule, MatIconModule, MatSidenavModule, MatGridListModule, MatListModule, MatCheckboxModule, MatButtonModule, MatCardModule, MatTabsModule, MatFormFieldModule, MatInputModule, MatTableModule, MatMenuModule, MatExpansionModule, MatDialogModule, MatSelectModule } from '@angular/material';

import { SubmissionDetailComponent } from 'app/admin/submission-detail/submission-detail.component';
import { LoginComponent } from 'app/login/login.component';
import { RouterModule } from '@angular/router';
import { SubmissionsService } from '../services/submissions.service';
import { AuthService } from '../services/auth.service';
import { LayersService } from '../services/layers.service';
import { UploadService } from '../services/upload.service';
import { ShopifyService } from '../services/shopify.service';
import 'firebase/storage';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { EditorComponent } from "../app/editor/editor.component";
import { EditorModule } from "../app/editor/editor.module";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { LoadingComponent } from "../../src/app/Loader/loader.component";
import { ImageUploadModule } from 'angular2-image-upload';
import { AlertService } from 'app/alert-component/alert.service';
import { styleService } from 'services/style.service';
import { SortArrayOfSubmissionsPipe } from '../../src/app/admin/dashboard/submissions.pipe';
import { FilterPipe } from './admin/filter.pipe';
import { ViewdesignComponent } from './viewdesign/viewdesign.component';
import { HomePageComponent } from '../app/home-page/home-page.component';
import { HomePageModule } from '../app/home-page/home-page.module';
import { AdminLoginComponent } from '../app/admin-login/admin-login.component';
import { AdminLoginModule } from '../app/admin-login/admin-login.module';
import { ToastrModule } from 'ngx-toastr';
import { MainContentComponent } from './core/shell/main-content/main-content.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CustomersComponent } from 'app/admin/customers/customers.component';
import { ManageClipArtComponent } from './manage-clip-art/manage-clip-art.component';
import { ManagePatternsComponent } from './admin/manage-patterns/manage-patterns.component';
import { AddCategoriesComponent } from './add-categories/add-categories.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccountSettingsComponent } from 'app/admin/account-settings/account-settings.component';
import { ManageProductComponent } from 'app/admin/manage-product/manage-product.component';
import { AdminContactComponent } from 'app/admin/admin-contact/admin-contact.component';
import { CustomerAdminModule } from 'app/customer-admin/customer-admin.module';
import { CustomerWalletComponent } from './customer-admin/customer-wallet/customer-wallet.component';
import { MyCartComponent } from './customer-admin/my-cart/my-cart.component';
import { CustomerCatalogComponent } from 'app/customer-admin/catalog/catalog.component';

import { CustomerRequestDesignComponent } from 'app/customer-admin/request-design/request-design.component';

import { AddProductModalComponent } from 'app/admin/manage-product/add-product/add-product-component';
import { CustomerAccountSettingsComponent } from 'app/customer-admin/account-setttings/account-settings.component';
import { CustomerOrderComponent } from 'app/customer-admin/order/order.component';
import { CustomerMyQuotesComponent } from 'app/customer-admin/myquotes/myquotes.component';
import { CustomerMyDesignRequestsComponent } from 'app/customer-admin/my-design-requests/my-design-requests.component';
import { AdminMyDesignRequestsComponent } from './admin//my-design-requests/my-design-requests.component';
import { AdminMyDesignRequestsDetailsComponent } from './admin/my-design-requests/my-design-details/my-design-details.component';
import { CustomerMyDesignRequestsDetailsComponent } from 'app/customer-admin/my-design-requests/my-design-details/my-design-details.component';
import { CustomerDashboardComponent } from 'app/customer-admin/customer-dashboard/customer-dashboard.component';
import { CustomerSupportComponent } from 'app/customer-admin/support/support.component';
import { SendEmailModalComponent } from 'app/admin/dashboard/send-email/send-email.component';
import { NewQuotesModalComponent } from 'app/admin/dashboard/new-quotes/new-quotes.component';

import { CustomerDetailsComponent } from 'app/admin/customers/customer-details/customer-details.component';

import { ManageCategoriesComponent } from 'app/admin/manage-categories/manage-categories.component';
import { environment } from 'environments/environment';
import { SetOrderNameModalComponent } from './customer-admin/my-cart/set-order-name/set-order-name.component';
import { SetDesignNameModalComponent } from './admin/my-design-requests/my-design-details/set-design/set-design.component';
import { SetQuotesModalComponent } from './customer-admin/my-cart/set-quotes/set-quotes.component';
import { SetColorReqDesignModalComponent } from './customer-admin/request-design/set-color-redesign/set-color-redesign.component';
import { AddCardComponent } from './customer-admin/account-setttings/add-card/add-card.component';
import { CustomerOrderDetailsComponent } from './customer-admin/order/order-details/order-details.component';
import { CustomerMyQuotesDetailsComponent } from './customer-admin/myquotes/my-quotes-details/my-quotes-details.component';
import { DatePipe }  from '@angular/common';
import { BulkPricingOptionsComponent } from './admin/bulk-pricing/bulk-pricing.component';
import { CreateAdminUsersComponent } from './admin/create-admin-users/create-admin-users.component';
import { ShopifySettingsComponent } from './admin/shopify-settings/shopify-settings.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminMyQuotesComponent } from './admin/my-quotes/my-quotes.component';
import { AdminMyQuotesDetailsComponent } from './admin/my-quotes/my-quotes-details/my-quotes-details.component';
import { SignInModalComponent } from './modals/sign-in/sign-in.component';
import { ImageViewerModule } from "ngx-image-viewer";
import { EditShippingComponent } from './dialogs/edit-shipping/edit-shipping.component';
import { ShopifyProductsComponent } from './dialogs/shopify-products/shopify-products.component';
import { LinkExistingQuoteModal } from './dialogs/link-existing-quote-modal/link-existing-quote-modal.component';
import { MatRadioModule } from '@angular/material';

import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { AddShippingAddressComponent } from './dialogs/add-shipping-address/add-shipping-address.component';
import { ImportRosterComponent } from './dialogs/import-roster/import-roster.component';
import { FileUploadModule } from 'ng2-file-upload';

import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SelectDesignComponent } from './dialogs/select-design/select-design.component'; 
import { AdminOrderComponent} from 'app/admin/order/order.component';
import { MakePaymentsComponent } from './dialogs/make-payments/make-payments.component';
import { CreateNewPurchaseOrderComponent } from './dialogs/create-new-purchase-order/create-new-purchase-order.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';


export const firebaseConfig = {
  apiKey: environment.apiKey,
  authDomain: environment.authDomain,
  databaseURL: environment.databaseURL,
  projectId: environment.projectId,
  storageBucket: environment.storageBucket,
  messagingSenderId: environment.messagingSenderId,
};

@NgModule({
  entryComponents: [
    AddProductModalComponent,
    SendEmailModalComponent,
    NewQuotesModalComponent,
    SetOrderNameModalComponent,
    SetDesignNameModalComponent,
    SetQuotesModalComponent,
    SetColorReqDesignModalComponent,
    SignInModalComponent,
    EditShippingComponent,
    ShopifyProductsComponent,
    LinkExistingQuoteModal,
    AddShippingAddressComponent,
    ImportRosterComponent,
    AddCardComponent,
    SelectDesignComponent,
    MakePaymentsComponent,
    CreateNewPurchaseOrderComponent
  ],

  declarations: [FilterPipe, LoadingComponent, SignInModalComponent,BulkPricingOptionsComponent, CustomerSupportComponent, AddProductModalComponent, SendEmailModalComponent, CustomerAccountSettingsComponent, AdminComponent, CustomersComponent, LoginComponent, AddStyleComponent, SubmissionDetailComponent, AdminNavComponent, ManageStylesComponent, StyleDetailComponent, ManageColorsComponent, SortArrayOfSubmissionsPipe, ViewdesignComponent, ManageClipArtComponent, ManagePatternsComponent, AddCategoriesComponent, AddNewStyleComponent, AccountSettingsComponent, AdminContactComponent, ManageProductComponent, CustomerWalletComponent, MyCartComponent, ManageCategoriesComponent,CustomerOrderComponent,CustomerOrderDetailsComponent,CustomerDashboardComponent,NewQuotesModalComponent, SetOrderNameModalComponent,CustomerCatalogComponent,CustomerRequestDesignComponent, EditShippingComponent,AddShippingAddressComponent,MakePaymentsComponent,SetColorReqDesignModalComponent,CustomerMyQuotesComponent,CustomerMyDesignRequestsComponent,AddCardComponent,ImportRosterComponent,SetQuotesModalComponent,CustomerMyQuotesDetailsComponent,AdminMyQuotesComponent,AdminMyQuotesDetailsComponent,CustomerMyDesignRequestsDetailsComponent,AdminMyDesignRequestsComponent,AdminMyDesignRequestsDetailsComponent, SelectDesignComponent,AdminOrderComponent,SetDesignNameModalComponent, CreateNewPurchaseOrderComponent,LinkExistingQuoteModal,CustomerDetailsComponent,CreateAdminUsersComponent,AdminHomeComponent,ShopifySettingsComponent,AddNewStyleV2Component,ShopifyProductsComponent,AddNewProductComponent],
  imports: [
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    FileUploadModule,
    NgxPermissionsModule.forRoot(),
    BrowserModule,
    DragDropModule,
    MatDialogModule,
    FormsModule,
    CoreModule,
    HttpModule,
    EditorModule,
    HomePageModule,
    AdminLoginModule,
    BrowserAnimationsModule,
    ImageViewerModule.forRoot(),
    MatGridListModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true
    }),
    MultiselectDropdownModule,
    AngularMultiSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CustomerAdminModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ImageUploadModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: ShellComponent },
      {
        path: 'admin', component: AdminNavComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: AdminComponent
          }
        },
        children: [
          {
            path: 'customers',
            component: CustomersComponent
          },
          {
            path: 'submissions',
            component: AdminComponent
          },
          {
            path: 'account-settings',
            component: AccountSettingsComponent
          },
          {
            path: 'manage-product',
            component: ManageProductComponent
          },
          {
            path: 'manage-pricing',
            component: BulkPricingOptionsComponent
          },
          {
            path: 'create-admin-users',
            component: CreateAdminUsersComponent
          },
          {
            path: 'shopify-settings',
            component: ShopifySettingsComponent
          },
          {
            path: 'home-admin',
            component: AdminHomeComponent
          },
          {
            path: 'manage-colors',
            component: ManageColorsComponent
          },
          {
            path: 'clip-art',
            component: ManageClipArtComponent
          },
          {
            path: 'manage-patterns', 
            component: ManagePatternsComponent,
          },
          {
            path: 'contact',
            component: AdminContactComponent
          },
          {
            path: 'wallet',
            component: CustomerWalletComponent
          },
          {
            path: 'my-cart',
            component: MyCartComponent
          },

          {
            path: 'customers/details/:id',
            component: SubmissionDetailComponent,
          },
          {
            path: 'manage-templates', component: ManageStylesComponent,
            data: {
              permissions: {
                only: ['Admin'],
              }
            }
          },
          {
            path: 'manage-categories', component: ManageCategoriesComponent,
            data: {
              permissions: {
                only: ['Admin'],
              }
            }
          },
          {
            path: 'customer-details', component: CustomerDetailsComponent,
            data: {
              permissions: {
                only: ['Admin'],
              }
            }
          },
          {
            path: 'my-quotes', component: AdminMyQuotesComponent,
            data: {
              permissions: {
                only: ['Admin'],
              }
            }
          },
          {
            path: 'order', component: AdminOrderComponent,
            data: {
              permissions: {
                only: ['Admin'],
              }
            }
          },
          {
            path: 'my-quotes-details', component: AdminMyQuotesDetailsComponent,
            data: {
              permissions: {
                only: ['Admin'],
                redirectTo: ''
              }
            }
          },
          {
            path: 'my-design-requests', component: AdminMyDesignRequestsComponent,
            data: {
              permissions: {
                only: ['Admin'],
              }
            }
          },
          {
            path: 'order-details', component: CustomerOrderDetailsComponent,
            data: {
              permissions: {
                only: ['Admin'],
               
              }
            }
          },
          {
            path: 'my-design-details', component: AdminMyDesignRequestsDetailsComponent,
            data: {
              permissions: {
                only: ['Admin'],
                redirectTo: ''
              }
            }
          },
          {
            path: 'template-detail/:id',
            component: StyleDetailComponent,
            data: {
              permissions: {
              }
            }
          },
          {
            path: 'add-template', component: AddNewStyleComponent,
            data: {
              permissions: {
                only: ['Admin'],
                redirectTo: ''
              }
            }
          },
          {
            path: 'add-template-v2', component: AddNewStyleV2Component,
            data: {
              permissions: {
                only: ['Admin'],
                redirectTo: ''
              }
            }
          },
          {
            path: 'add-new-product', component: AddNewProductComponent,
            data: {
              permissions: {
                only: ['Admin'],
                redirectTo: ''
              }
            }
          },
          {
            path: 'manage-patterns', component: ManagePatternsComponent,
            data: {
              permissions: {
                only: ['Admin'],
                redirectTo: ''
              }
            }
          },
        ]
      },
      {
        path: 'admin/add-style', component: AddStyleComponent,
        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: '/admin/login'

          }
        }
      },
      {
        path: 'admin/add-new-style', component: AddNewStyleComponent,
        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: '/admin/login'

          }
        }
      },
      {
        path: 'admin/customers', component: CustomersComponent,

        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: '/admin/login'
          }
        }
      },
      {
        path: 'admin/login', component: LoginComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'customer/submissions', component: AdminComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'customer/submission-detail/:id', component: SubmissionDetailComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'admin/submission-detail', component: SubmissionDetailComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'admin/submission-detail/:id', component: SubmissionDetailComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'admin/style-detail', component: StyleDetailComponent,
        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'admin/manage-colors', component: ManageColorsComponent,
        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'admin/manage-clip-art', component: ManageClipArtComponent,
        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'design/:id', component: EditorComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'submissions/:id', component: EditorComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'editor', component: EditorComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'homepage', component: HomePageComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      },
      {
        path: 'customer/login', component: AdminLoginComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }

      },
      {
        path: 'admin/add-categories', component: AddCategoriesComponent,
        data: {
          permissions: {
            only: ['Admin', 'User'],
            redirectTo: ''
          }
        }
      }
    ])
  ],
  providers: [SubmissionsService, AuthService, UploadService, LayersService, Ng4LoadingSpinnerService, AlertService, styleService,DatePipe,MatDatepickerModule,ShopifyService, { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }],
  bootstrap: [
    ShellComponent
  ],
  exports: [
    LoadingComponent,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatTabsModule,
    MatCheckboxModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
  ]
})
export class AppModule { }
