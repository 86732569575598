import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'home-page',
    styleUrls: ['./home-page.component.scss'],
    templateUrl: './home-page.component.html'
})

export class HomePageComponent implements OnInit {
    
    constructor(private router: Router) {
        //
    }

    ngOnInit() {
    } 
}