import { Component,OnInit,Input} from '@angular/core';
import {Http, Headers,URLSearchParams,Response} from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
    selector:'send-email',
    templateUrl:'./send-email.component.html',
    styleUrls:['./send-email.css']
})

export class SendEmailComponent implements OnInit{
    @Input() emailAddress;
    @Input() message;
    @Input() emailAddress2;
    @Input() message2;
    constructor(private http:Http){}
    ngOnInit(){
        //this.sendEmail(this.emailAddress,this.message);
        //this.sendEmail(this.emailAddress2,this.message2);
    }

  /*sendEmail(emailAddress,message)
  {
    console.log('inside send email');
    let url= 'https://us-central1-uniform-e640f.cloudfunctions.net/httpEmail/function';
    let params: URLSearchParams = new URLSearchParams(); 
    let headers = new Headers({'Content-Type':'application/json','Access-Control-Allow-Origin':'*'});
    params.set('to',emailAddress);
    params.set('from','adityagholap25@gmail.com');
    params.set('content',message);
    var param = {
        'to':'adityagholap25@gmail.com',
        'from':'adityagholap25@gmail.com',
        'subject':'test mail',
        'content':'test mail'
    }
    return this.http.post(url,param,{headers:headers}).toPromise()
    .then(res=>{
        console.log(res);
    })  
    .catch(err=>{
        console.log(err);
    })
   
  }*/
  
}
