import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import { AddCardComponent } from '../account-setttings/add-card/add-card.component';

import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SubmissionsService } from '../../../services/submissions.service';
import { AddShippingAddressComponent } from '../../dialogs/add-shipping-address/add-shipping-address.component';
import { MatTabChangeEvent } from '@angular/material';
@Component({
  selector: 'app-customer-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class CustomerAccountSettingsComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private nav: CustomerNavComponent,
    public dialog: MatDialog,
    private submService : SubmissionsService
  ) { }

  companyLogo$: AngularFireList<any[]>;

  accountSetings = {
    organization: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    logoPath: '',
    urlHandle: ''
  }
  companyInfoSetings = {
    organization: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    id:''
  }
  shipping = {
    name: '',
    address1: '',
    address2: '',
    state: '',
    zip: '',
    city: ''
  }
  customerList:any = [];
  allAddresses:any = [];
  activeTabIndex = 0;
  private loading: boolean = false;
  ngOnInit() {
 
    this.getAddress();
    // this.getImage(); 
    this.getCustomer(localStorage.getItem('emailForSignIn'));
  }
  companyData = []

  getImage(){
    let myEmail = this.nav.email.replace('.', ''); // we had to remove . from email address to save as unique key
    // to do, save customer profiles based on email address
    this.db.object(`/customer-profiles/${myEmail}`).snapshotChanges().subscribe(data => {
      console.log("GOT PROFILE")
      console.log(data);
      let object = data.payload.val()
      // this.uploadedLogoPath = object['logoPath']; 
      this.accountSetings.logoPath = object['logoPath']; 
      this.accountSetings.firstName = object['firstName']; 
      this.accountSetings.lastName = object['lastName']; 
      this.accountSetings.email = object['email']; 
      this.accountSetings.phoneNumber = object['phoneNumber']; 
      this.accountSetings.organization = object['organization']; 
      this.accountSetings.urlHandle = object['urlHandle']; 
    });
  }
  
  getCustomer(email){
    this.loading = true;
    this.submService.getAllCustomer().subscribe(res => {
   this.customerList = res.json();
   this.customerList = this.customerList.filter(element => element.emailAddress==email);
   console.log("this.customerList",this.customerList[0]);
   var firstName = this.customerList[0].fullName.split(' ').slice(0, -1).join(' ');
   var lastName = this.customerList[0].fullName.split(' ').slice(-1).join(' ');
   this.companyInfoSetings.firstName = firstName;
   this.companyInfoSetings.lastName = lastName;
   this.companyInfoSetings.email = this.customerList[0].emailAddress;
   this.companyInfoSetings.phoneNumber = this.customerList[0].phoneNumber;
   this.companyInfoSetings.organization = this.customerList[0].organization;
   this.companyInfoSetings.id = this.customerList[0]._id
   this.loading = false;
 },error =>{
   console.log("error is ",error);
 });
}
  //File Select & Upload
  uploadImage(event) {
    if (event.target.files.length != 0) {
      let file = event.target.files[0];
      // this.handleReaderLoaded(file)
    }
  }

  uploadLogo(){
    // document.getElementById("uploadFile").click();
  }

  saveProfileChanges(){
    this.loading = true;
    var obj = {
      userId:this.companyInfoSetings.id,
      emailAddress: this.companyInfoSetings.email,
      phoneNumber: this.companyInfoSetings.phoneNumber,
      organization: this.companyInfoSetings.organization,
      fullName: this.companyInfoSetings.firstName+' '+this.companyInfoSetings.lastName
   }
   this.submService.updateCustomerInformation(obj).subscribe(res => {
    this.toastr.success('Company info updated', '', { positionClass: 'toast-top-right' });
      this.loading = false;
     });
    // let emailWithoutDot = this.nav.email.replace('.', '');
    // this.db.object(`/customer-profiles/${emailWithoutDot}`).update(this.accountSetings)
    
  }

  addNewCard(): void {
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: '600px',
      height:'425px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  // uploadedLogoPath = null;
  // base64textString;
  // async	handleReaderLoaded(file) {
  //   this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
  //   let storageRef = firebase.storage().ref();
  //   let extendo = file.name.substring(file.name.indexOf("."));
  //   const fileData = await storageRef.child(`${'/uploaded-logos'}/COMPANY-LOGO${extendo}`).put(file)
  //     .then(snapshot => {
  //       return snapshot.ref.getDownloadURL();
  //     }).then(async (downloadURL) => {
  //       this.uploadedLogoPath = downloadURL;
  //       this.base64textString = downloadURL;
  //       this.accountSetings.logoPath = downloadURL;
  //       this.db.object('/company-info/myInfo').update(this.accountSetings)
  //       this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
  //     }).catch(error => {
  //       this.uploadedLogoPath = null;
  //       this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
  //     });


  // }

  getAddress(){
    this.submService.getAddresses()
       .subscribe(data => {
         this.allAddresses = data.json();
         console.log("addressss",data.json());
         console.log(JSON.parse((<any>data)._body));
        });
  }

  addNewAddress(): void {
    //this.dialogRef.close();
    const dialogRef = this.dialog.open(AddShippingAddressComponent, {
      //width: '650px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result=='update'){
        this.getAddress();
      }else{
        if(result != null){
          this.allAddresses.push(result);
        }
      }
        
    
        // if(result != null){
        //   this.allAddresses.push(result);
        // }
    
      
    });
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
      this.activeTabIndex = tabChangeEvent.index;
  }

  editAddress(addr,edit){
    //this.dialogRef.close();
    addr['edit'] = edit;
    const dialogRef = this.dialog.open(AddShippingAddressComponent, {
      //width: '650px',
      data: addr
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result=='update'){
        this.getAddress();
      }else{
        if(result != null){
          this.allAddresses.push(result);
        }

      }
     
    });
  }

  deleteAddress(address){

    console.log(address);
    var obj = {
        addressId:address._id
      }
       let a = confirm("Are you sure you want to delete this Address?")
      if (a) {
        this.loading = true;
        this.submService.deleteAddressById(obj).subscribe(res => {
          this.toastr.success("Deleted Address Successfully", '', { positionClass: 'toast-top-right' });
          this.getAddress();
          setTimeout(() => {
            this.loading = false;
          }, 2000);
         });
    }

}

  // deleteAddress(addr){
  //   this.submService.deleteAddress(addr)
  //      .subscribe(data => {
  //        console.log(JSON.parse((<any>data)._body));
  //       });
  // }
}
