import { Component, OnInit } from '@angular/core';
// import { ColorPickerService } from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
// import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
// import { FirebaseApp } from 'angularfire2';
import { Jersey, Ifile, UniformStyle, sport, FileObj, gender, reversible, uniformtypes,categoriesList } from '../models/upload';
import { cuts } from '../../models/jersey';
import { UploadService } from '../../../services/upload.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase';
import 'fabric';
import {fabric} from 'fabric';
import { ButtonsModule } from 'ngx-bootstrap';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { ViewChild } from '@angular/core'
// import { AuthService } from 'services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ShopifyProductsComponent } from '../../dialogs/shopify-products/shopify-products.component';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LayerObj, Ilayer, ViewLayerData, IViewlayer, IPlayer, PlayerObj, IUniformSubmission, UniformSubmissionObject, Icolorselected, SelectedColorObj, ILayerColorOriginal, LayerColorOriginalObj, ITexttoCanvas, TexttoCanvasObj, IGraphic, GraphicObj, ShopifyProductObj, IShopifyProduct} from "../../models/jersey";
import { SubmissionsService } from 'services/submissions.service';
import { ShopifyService } from 'services/shopify.service';
// import svgEditor from "svgedit";
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { Canvas } from 'fabric/fabric-impl';

// import { svgEditor } from '/node_modules/svgedit/editor/svg-editor.html'
// declare const fabric: any;
declare var Snap: any;

@Component({
	selector: 'add-add-new-product',
	templateUrl: './add-new-product.component.html',
	styleUrls: ['./add-new-product.component.css']
})


export class AddNewProductComponent implements OnInit {

	// @ViewChild(AngularMultiSelect, { static: false }) varietalDropdown: AngularMultiSelect;
	// @ViewChild('genderdropdownRef', { static: false }) genderdropdownRef: AngularMultiSelect;
	// @ViewChild('cutdropdownRef', { static: false }) cutdropdownRef: AngularMultiSelect;
	// @ViewChild('typedropdownRef', { static: false }) typedropdownRef: AngularMultiSelect;
	// @ViewChild('revcutdropdownRef', { static: false }) revcutdropdownRef: AngularMultiSelect;


	dropdownList = [];
	editTemplete4 = null;
	editTemplete3=null;
	dropdownList1 = [];
	varientArray = [];
	logoLocations = [];
	cutTypeArray = [];
	catList = [];
	snapSvg:any;
	private purchesBuilder : boolean = false;
	private enablePatterns : boolean = false;
	private enableGradients : boolean = false;
	selectedShopifyProduct: IShopifyProduct;
	public categoriesList = [];
	public shopifyList :any;
	private loading: boolean = false;
	private loading1: boolean = false;
	uniFormStyle = 'Reversible';
	categoryName:any;
	public cutTypes = [
		{ "id": 1, "name": "1", "itemName": "1" },
		{ "id": 2, "name": "2", "itemName": "2" },
		{ "id": 3, "name": "3", "itemName": "3" },
		{ "id": 4, "name": "4", "itemName": "4" },
		{ "id": 5, "name": "5", "itemName": "5" },
		{ "id": 6, "name": "6", "itemName": "6" }
	];
	displayedColumns: string[] = ['SKU', 'Description', 'Price'];
	isId:any;
	constructor(
		private us: UploadService,
		// private auth: AuthService,
		public router: Router,
		private af: AngularFireDatabase,
		public dialog: MatDialog,
		private submissionService: SubmissionsService,
		private shopifyService: ShopifyService,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
		this.dropdownList = [
			{ "id": 1, "itemName": "Reversible" },
			{ "id": 2, "itemName": "Home and Away" },
			{ "id": 3, "itemName": "Game Day" }
		];
		this.dropdownList1 = [
			{ "id": 1, "categoryName": "Sport Uniform" },
			{ "id": 2, "categoryName": "Shooting Shirts" },
		];
		var obj = {
			uploadImage:"",
			number:1
		}
		this.cutTypeArray.push(obj);
		// this.dropdownList2 
		this.getAllCategory();
		console.log("window.location.href.split('?shopifyId=')[1]",window.location.href.split('?shopifyId=')[1]);
		// if(window.location.href.split('?shopifyId=')[1]){
			if(window.location.href.split('?shopifyId=')[1]){
				this.isId = window.location.href.split('?shopifyId=')[1];
				// this.getShopifyProduct();getByIdProducts
				this.getProductById(this.isId);
				this.loading1 = true;
				console.log("this.loading1",this.loading1);
			}else{
				this.loading1 = false;
				this.varientArray =[];
				console.log("this.loading1 else",this.loading1);
			}	
		// }
		
			
	}
	getProductById(id){
		this.loading = true;
		this.shopifyService.getByIdProducts(id).subscribe(data => {
			// this.shopifyList = [];
			this.shopifyList = data.json().productDetails;
			console.log("this.shopifyList",this.shopifyList);
			this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList.title,'','',this.shopifyList.images[0].src,this.shopifyList.variants ? this.shopifyList.variants : [],this.shopifyList.id);
			if(this.selectedShopifyProduct.productDetails){
			  this.varientArray = this.selectedShopifyProduct.productDetails;
			}
			this.loading = false;
		},error =>{
			this.loading = false;
		})

	}

	getAllCategory() {
		this.af.list('/template-categories').snapshotChanges().subscribe(data => {
		  this.catList = [];
		  data.forEach(action => {
			const value = action.payload.val();
			let newCat = {
			  key: action.key,
			  name: value['name'],
			  nameNoSpace: value['nameNoSpace'],
			  image: value['image'],
			  order: value['order']
			}
			this.catList.push(newCat);

		  });
		  this.catList.sort((a, b) => a.order - b.order);
		  this.categoryName =this.catList[0].name;
		})
	  }

	getShopifyProduct(){
		let a = this;
		this.loading = true;
		this.shopifyService.getAllShopifyProduct().subscribe(data => {
		 this.shopifyList = [];
		   this.shopifyList = data.json();
		   this.shopifyList = this.shopifyList.filter(function( element ) {
			return element.id == a.isId;
		   })
		//    console.log("this.shopifyList ********!!!!!!!",this.shopifyList[0]);
		   this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','',this.shopifyList[0].images[0].src,this.shopifyList[0].variants ? this.shopifyList[0].variants : [],'');
		   if(this.selectedShopifyProduct.productDetails){
             this.varientArray = this.selectedShopifyProduct.productDetails;
		   }
		//    console.log("this.selectedShopifyProduct",this.selectedShopifyProduct);
		   this.loading = false;
		});
	   }
	   editTemplete = null;
	   removeThumbNails(){
		this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : [],'');
	   }
	   removeThumbNails1(){
		this.editTemplete = null;
	   }
	   fileBrowseHandler(files){
		if (files.length != 0) {
			let file = files[0];
			this.handleReaderLoaded(file)
	   }
	  }

		onFileDropped(ev){
		if (ev.target.files.length != 0) {
			let file = ev.target.files[0];
			this.handleReaderLoaded(file)
		}
		}

	   async handleReaderLoaded(file) {
		   this.loading = true;
		 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
		 let storageRef = firebase.storage().ref();
		 let extendo = file.name.substring(file.name.indexOf("."));
		 const fileData = await storageRef.child(`${'/uploaded-logos'}/ADDTEMPLATETHUMBNAILS${extendo}`).put(file)
		   .then(snapshot => {
			 return snapshot.ref.getDownloadURL();
		   }).then(async (downloadURL) => {
			//  this.uploadedLogoPath = downloadURL;
			 this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','',downloadURL,this.shopifyList[0].variants ? this.shopifyList[0].variants : [],'');
			 var pat = /^https?:\/\//i;
			 if (pat.test(downloadURL))
			 {
				 var request = new XMLHttpRequest();
				 var url = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/svg-design-images%2FReQuestImageSvg1597325059768?alt=media&token=ab98f7cd-2826-4d08-a651-a42a1548921a"
				 var txtFile = new XMLHttpRequest();
				 let svgHtmldata = null;
			 
				 txtFile.open("GET", downloadURL, true);
				 txtFile.onreadystatechange = function() {
					 if (txtFile.readyState === 4) {  // Makes sure the document is ready to parse.
					 if (txtFile.status === 200) {  // Makes sure it's found the file.
						 var allText = txtFile.responseText;
						 svgHtmldata = allText.toString();
					     console.log("svgHtmldata",svgHtmldata);
						//  document.getElementById("svgContainer").innerHTML = svgHtmldata;
					 }
					 }
				 }
				}
				 txtFile.send(null);
			 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
		   }).catch(error => {
			this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : [],'');
			 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
		   });
	 
	   }

	   fileBrowseHandler1(files,number){
		if (files.length != 0) {
			let file = files[0];
			this.handleReaderLoaded1(file,number)
	   }
	  }

		onFileDropped1(ev,number){
		if (ev.target.files.length != 0) {
			let file = ev.target.files[0];
			this.handleReaderLoaded1(file,number)
		}
		}

		
	
    //   editTemplete = null;
	//    async handleReaderLoaded1(file) {
	// 	 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
	// 	 let storageRef = firebase.storage().ref();
	// 	 let extendo = file.name.substring(file.name.indexOf("."));
	// 	 const fileData = await storageRef.child(`${'/uploaded-logos'}/templatesEditor${extendo}`).put(file)
	// 	   .then(snapshot => {
	// 		 return snapshot.ref.getDownloadURL();
	// 	   }).then(async (downloadURL) => {
	// 		 this.editTemplete = downloadURL;
	// 		 setTimeout(() => {
	// 			var canvas = new fabric.Canvas('c');
	// 				fabric.loadSVGFromURL(downloadURL,function(objects, options){
	// 			var svgData = fabric.util.groupSVGElements(objects, options);
	// 			console.log("svgData",svgData);
				
	// 			canvas.add(svgData);
	// 	    });
	// 		 }, 2000);
	// 		 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
	// 	   }).catch(error => {
	// 		this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
	// 		 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
	// 	   });
	 
	//    }
	editTemplete1 = null;
	async handleReaderLoaded1(file,number) {
		this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
		let storageRef = firebase.storage().ref();
		let extendo = file.name.substring(file.name.indexOf("."));
		const fileData = await storageRef.child(`${'/uploaded-logos'}/templatesEditor${extendo}`).put(file)
		  .then(snapshot => {
			return snapshot.ref.getDownloadURL();
		  }).then(async (downloadURL) => {
			this.editTemplete1 = downloadURL;
			setTimeout(() => {
			   this.cutTypeArray.forEach(element=>{
				   if(element.number == number.number){
					  element.uploadImage = this.editTemplete1 ;
				   }
			   })
			   console.log("this.cutTypeArray",this.cutTypeArray);
			}, 2000);
   
			document.getElementById('upload-'+number.number).style.display = 'none';
			document.getElementById('image-'+number.number).style.display = 'block';
			document.getElementById('name-'+number.number).style.display = 'none';
			document.getElementById('name1-'+number.number).style.display = 'block';
			this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
		  }).catch(error => {
		   // this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
			this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
		  });
	
	  }

	

	   addLogoLocation(){
		// var canvas = new fabric.Canvas('c');
		var canvas = <any>document.getElementById('c');
		var ctx = canvas.getContext("2d");
		var img = new Image();
		img.src = canvas.toDataURL("image/png");
		setTimeout(() => {
			var canvas1 = new fabric.Canvas('c');
			var oldCanvas = canvas;
			var rect = new fabric.Rect({
				width: 150,
				height: 150,
				strokeWidth: 2
				// left: 100,
				// top: 100,
				// fill: 'red',
				// width: 20,
				// height: 20
			  });
			  canvas1.add(oldCanvas);
			  canvas1.add(rect);
		}, 2000);
		
		
		//    console.log("consolew");
		// var canvas = new fabric.Canvas('c');
		// var canvas1 = new fabric.Rect({
		// 	width: 150,
		// 	height: 150,
		// 	strokeWidth: 2
		// });
		// canvas.add(canvas1);
		// this.logoLocations.push(this.logoLocations.length + 1);
		// this.addLogoLocation1();	
	   }


	   addLogoLocation1(){
		var location = document.getElementsByTagName("rect");
		this.snapSvg = Snap("#svg svg");
		var rect = this.snapSvg.rect(130,130,50,50);
		rect.attr({
			id: 'default-'+(this.logoLocations.length),
			style:'fill:transparent;stroke:black;stroke-width:2px'
		});
		var move = function(dx, dy, posx, posy) {
			this.attr({"x": this.ox + dx, "y": this.oy + dy});
			rect.attr({
				style:'fill:transparent;stroke:white;stroke-width:5px'
			});
		}
		var start = function(dx,dy,posx,posy) {
			this.ox = parseInt(this.attr("x"));
			this.oy = parseInt(this.attr("y"));
			rect.attr({
				style:'fill:transparent;stroke:white;stroke-width:5px'
			});
		}
		var stop = function() {	
			rect.attr({
				style:'fill:transparent;stroke:black;stroke-width:2px'
			});
		console.log('finished dragging');
		}
		rect.drag(move,start, stop );
			setTimeout(() => {
				for(var a=0;a<this.logoLocations.length;a++){
					var abc = <any>document.getElementById('location-'+this.logoLocations[a]);
					if(location[a].id){
						abc.value = location[a].id;
					}	
				}
			}, 1000);
		}
		saveLocations(){
		
			var location = document.getElementsByTagName("rect");
			if(location.length!=0){
				for(var a=0;a<location.length;a++){
						let id = <any>document.getElementById('location-'+this.logoLocations[a]);
						document.getElementById(location[a].id).setAttribute("id",id.value);
						document.getElementById(location[a].id).setAttribute("style",'fill:transparent');
				}
				}
}


fileBrowseHandler3(files){
	if (files.length == 0) {
		this.thumbnails = [];
	} else {
		this.thumbnails = [];
		this.thumbnails.push({
			file: files,
			LayoutView: 'thumbnail'
		});
	}
	if (files.length != 0) {
		let file = files[0];
		this.handleReaderLoaded3(file)
   }
  }

	onFileDropped3(ev){
		if (ev.target.files.length == 0) {
			this.thumbnails = [];
		} else {
			this.thumbnails = [];
			this.thumbnails.push({
				file: ev.target.files,
				LayoutView: 'thumbnail'
			});
		}
	if (ev.target.files.length != 0) {
		let file = ev.target.files[0];
		this.handleReaderLoaded3(file)
	}
	}

   async handleReaderLoaded3(file) {
	 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
	 let storageRef = firebase.storage().ref();
	 let extendo = file.name.substring(file.name.indexOf("."));
	 const fileData = await storageRef.child(`${'/uploaded-logos3'}/templatesEditor${extendo}`).put(file)
	   .then(snapshot => {
		 return snapshot.ref.getDownloadURL();
	   }).then(async (downloadURL) => {
		 this.editTemplete3 = downloadURL;
		 console.log("this.editTemplete3",this.editTemplete3);
		//  var pat = /^https?:\/\//i;
		//  if (pat.test(this.editTemplete))
		//  {
		// 	 var request = new XMLHttpRequest();
		// 	 var url = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/svg-design-images%2FReQuestImageSvg1597325059768?alt=media&token=ab98f7cd-2826-4d08-a651-a42a1548921a"
		// 	 var txtFile = new XMLHttpRequest();
		// 	 let svgHtmldata = null;
		 
		// 	 txtFile.open("GET", this.editTemplete3, true);
		// 	 txtFile.onreadystatechange = function() {
		// 		 if (txtFile.readyState === 4) {  // Makes sure the document is ready to parse.
		// 		 if (txtFile.status === 200) {  // Makes sure it's found the file.
		// 			 var allText = txtFile.responseText;
		// 			 svgHtmldata = allText.toString();
		// 			 console.log("svgHtmldata",svgHtmldata);
		// 			 document.getElementById("svgContainer").innerHTML = svgHtmldata;
		// 		 }
		// 		 }
		// 	 }
		// 	}
			//  txtFile.send(null);
		 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
	   }).catch(error => {
		// this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
		 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
	   });
 
   }

   fileBrowseHandler4(files,number){
	if (files.length != 0) {
		let file = files[0];
		this.handleReaderLoaded4(file,number);
   }
  }

	onFileDropped4(ev,number){
	if (ev.target.files.length != 0) {
		let file = ev.target.files[0];
		this.handleReaderLoaded4(file,number)
	}
	}

	findIndexToUpdate(newItem) { 
        return newItem.number === this;
  }
   async handleReaderLoaded4(file,number) {

	   this.loading = true;
	 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
	 let storageRef = firebase.storage().ref();
	 let extendo = file.name.substring(file.name.indexOf("."));
	 const fileData = await storageRef.child(`${'/product-templates'}/${extendo}`).put(file)
	   .then(snapshot => {
		 return snapshot.ref.getDownloadURL();
	   }).then(async (downloadURL) => {
		 this.editTemplete4 = downloadURL;
		 this.loading = false;
		 setTimeout(() => {
			this.cutTypeArray.forEach(element=>{
				// console.log("element.number",element.number)
				// console.log(".number",number)
				if(element.number == number.number){
				   let v = <any>document.getElementById('variation-name-'+number.number);
				   console.log('variation-name-'+number.number);
				   console.log("v.value",v.value);
				   element.cutTypeName = v.value;
				   element.uploadImage = this.editTemplete4;
				   
				}
			})
			console.log("this.cutTypeArray",this.cutTypeArray);
		 }, 1000);

		 document.getElementById('upload-'+number.number).style.display = 'none';
		 document.getElementById('image-'+number.number).style.display = 'block';
		 document.getElementById('name-'+number.number).style.display = 'none';
		 document.getElementById('name1-'+number.number).style.display = 'block';
		 console.log();
		console.log("this.cutTypeArray",this.cutTypeArray);
		 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
	   }).catch(error => {
		// this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
		 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
	   });
 
   }
   changeUniform(event){
	this.uniFormStyle=event.target.value
   }
   changeCategory(event){
	this.categoryName= event.target.value
   }

   importFromShopify(){
	const dialogRef = this.dialog.open(ShopifyProductsComponent, {
		width: '650px'
	  });
  
	  dialogRef.afterClosed().subscribe(result => {
		  console.log(result);
		  this.loading = true;
		this.dropdownList = [
			{ "id": 1, "itemName": "Reversible" },
			{ "id": 2, "itemName": "Home and Away" },
			{ "id": 3, "itemName": "Game Day" }
		];
		this.dropdownList1 = [
			{ "id": 1, "categoryName": "Sport Uniform" },
			{ "id": 2, "categoryName": "Shooting Shirts" },
		];
		this.getAllCategory();
		console.log(window.location.href.split('?shopifyId=')[1]);
		if(result){
			if(result){
				this.isId = result;
				this.getProductById(this.isId);
				this.loading1 = true;
			}else{
				this.loading1 = false;
				this.varientArray =[];
			}	
		}
	  });
}

changeCutypes(ev){
	console.log(ev.target.value)
	this.cutTypeArray = [];
	if(ev.target.value==1){
		var obj = {
			uploadImage:"",
			number:1,
			cutTypeName:""
		}
		this.cutTypeArray.push(obj);
	}else{
		for(var a=1;a<=ev.target.value;a++){
			var obj = {
				uploadImage:"",
				number:a,
				cutTypeName:""
			}
			this.cutTypeArray.push(obj);
		   }
	}
	
}

remove(i,number){
	this.editTemplete4 = null;
	// setTimeout(() => {
		this.cutTypeArray.forEach(element=>{
			if(element.number == number){
			   element.uploadImage = '' ;
			   document.getElementById('upload-'+number).style.display = 'block';
			   document.getElementById('image-'+number).style.display = 'none';
			   document.getElementById('name-'+number).style.display = 'block';
			   document.getElementById('name1-'+number).style.display = 'none';
			   let v = <any>document.getElementById('upload1-'+number);
			   v.value = null;
			   
			}
			
		})
	// }, 1000);
	
}
basePath: string = '/product-templates';
thumbnailFiles: any = [];
thumbnails: any = [];
thumbnails1: any = [];
newJersey: UniformStyle;
folderName:string;
saveData(){
	let files = [];
	this.cutTypeArray.forEach(element=>{
		   var obj = {
			cutType:element.cutTypeName ,
			img_url:element.uploadImage,
			layoutType: "Outside",
			uniformType: "GameDay"
		   }
		   files.push(obj);
	})
	let productName = <any>document.getElementById('productName');
	let shopifyProductId = <any>document.getElementById('shopifyProductId');

	
	// var obj = {
	// 	"productName":productName.value ? productName.value : '',
	// 	"categoryName":this.categoryName,
	// 	"uniformStyle":this.uniFormStyle,
	// 	"cuttypeArray":files,
	// 	"shopifyProductId":shopifyProductId.value,
	// 	"canPurchaseOnBuidler":this.purchesBuilder,
	// 	"enablePatterns":this.enablePatterns,
	// 	"enableGradients":this.enableGradients,
	// 	"thumbnailImage":this.selectedShopifyProduct ? this.selectedShopifyProduct.thumbnailImage : this.editTemplete3,
	// 	"productDetails":this.selectedShopifyProduct.productDetails  ? this.selectedShopifyProduct.productDetails:'',
	// }

	// category:string;
	// files: Array<Ifile>;
	// price:number;
	// name:string;
	// display_Name:string;
	// gender:string;
	// isEditable:string;
	// createdAt: Date = new Date();
	// progress:number;
	// cuts : number;
	// reversible : boolean;
	// sku_number: any;
	// jersey_sku: string;
	// shorts_sku: string;
	// thumbnail_url : string;
	var d = new Date();
	this.newJersey = new UniformStyle();
	this.newJersey.display_Name = productName.value ? productName.value : '';
	this.newJersey.category = this.categoryName;
	this.newJersey.gender = "Male";
	this.newJersey.files = files;
	this.newJersey.reversible = false;
	this.newJersey.createdAt = d;
	this.newJersey.name = productName.value ? productName.value.replace(/\s+/g, '-').toLowerCase() : '';
	this.newJersey.thumbnail_url = this.selectedShopifyProduct ? this.selectedShopifyProduct.thumbnailImage : this.editTemplete3
	this.newJersey.productDetails=this.selectedShopifyProduct.productDetails  ? this.selectedShopifyProduct.productDetails:'',
	this.newJersey.canPurchaseOnBuidler=this.purchesBuilder,
	this.newJersey.enablePatterns=this.enablePatterns,
	this.newJersey.enableGradients=this.enableGradients,
	this.newJersey.shopifyProductId=shopifyProductId.value
	// var newJersey1 = {
	// 	category:this.categoryName,
	// 	createdAt:d,
	// 	display_Name:productName.value ? productName.value : '',
	// 	files:files,
	// 	gender: "Male",
	// 	name: productName.value ? productName.value : '',
	// 	reversible: false,
	// 	thumbnail_url:this.selectedShopifyProduct ? this.selectedShopifyProduct.thumbnailImage : this.editTemplete3,
		// productDetails:this.selectedShopifyProduct.productDetails  ? this.selectedShopifyProduct.productDetails:'',
		// canPurchaseOnBuidler:this.purchesBuilder,
		// enablePatterns:this.enablePatterns,
		// enableGradients:this.enableGradients,
		// shopifyProductId:shopifyProductId.value
	// }
	// try {
	// 	var cFile = this.thumbnails[0].file.item(0);
	// 	var lFile = this.thumbnails[0].LayoutView;
	// 	let item1: Ifile = new FileObj('GameDay', 'thumbnail', lFile, cFile);
	// 	this.thumbnailFiles.push(item1);
	// } catch (error) {
	// 	// return;
	// 	console.log(error);
	// }
	console.log("this.thumbnailFiles",this.thumbnailFiles);
	console.log("this.newJersey",this.newJersey);
	this.us.saveNewStyleNew(this.newJersey);
	// addJerseyForm.reset();
	// this.thumbnailFiles = [];
	// var dbRef = this.af.object(`${this.basePath}/${obj.productName}/`);
	// dbRef.set(obj);
}

onChange(event){
	this.purchesBuilder = event.checked
}

onChange1(event){
	this.enablePatterns = event.checked
}
onChange2(event){
	this.enableGradients = event.checked
}



}
