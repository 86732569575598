import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import { MatDialog,MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';
import { NewQuotesModalComponent } from 'app/admin/dashboard/new-quotes/new-quotes.component';
import { LinkExistingQuoteModal } from '../../../dialogs/link-existing-quote-modal/link-existing-quote-modal.component';

@Component({
  selector: 'app-customer-my-design-details',
  templateUrl: './my-design-details.component.html',
  styleUrls: ['./my-design-details.component.scss']
})
export class CustomerMyDesignRequestsDetailsComponent implements OnInit {
  requestdesignList = [];
  massage = [];
  displayedColumns: string[] = ['Design', 'Date', 'Actions'];
  private loading:boolean = false;
  private  previewRequest:boolean = false;
  designId:any;
  name:any;
  arrayOfName = [];
  id:any;
  
  initails:any;
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;
  constructor(
    private toast: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private nav: CustomerNavComponent,
    private router: Router,
    private dialog: MatDialog,
    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    if(window.location.href.split('?designId=')[1]){
      this.designId = window.location.href.split('?designId=')[1];
    }
    this.name = window.localStorage.getItem('name');
    var initials = this.name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    this.initails = initials;
    
    this.getdataOfDesign();
    this.id = setInterval(()=>{
      this.getdataOfDesign();
      }, 5000);
      setTimeout(() => {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
      }, 1500);
    
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  
  addAttachment(){
    document.getElementById('attch').click();
  }

  previewReques(){
    this.previewRequest = true;
  }
  backdesignRequest(){
    this.previewRequest = false;
  }

  getdataOfDesign(){
    this.submissionService.getRequestDesignById(this.designId).subscribe(res => {
      this.requestdesignList = res.json();
      console.log("this.requestdesignList",this.requestdesignList);
    },error =>{
        console.log("error is ",error);
      });
    
  }

  sendMessage(){
    let massage = <any>document.getElementById('send');
    var messageObject = {
      requestId:this.designId,
      messageText:massage.value
    }
    this.submissionService.sendMessageAsCustomer(messageObject).subscribe(res => {
      massage.value = "";
      this.getdataOfDesign();
      document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
    },error =>{
        console.log("error is ",error);
      });
  }

  onOpenMenu(menu: any): void {
  }

  actionOrder(action,element){

  }

  goToLink(url: string){
    window.open(url, "_blank");
   }

  radioChange(event,imageUrl) {
    var obj= {
      requestId:this.designId,
      imageUrl:imageUrl
    }
    if(event.value=='approved'){
      this.submissionService.approvedDesignAsCustomer(obj).subscribe(res => {
        this.getdataOfDesign();
        this.toast.success('Approved Design Successfully', '', { positionClass: 'toast-top-right' });
      },error =>{
        this.toast.error('Error in Approved Design', '', { positionClass: 'toast-top-right' });
          console.log("error is ",error);
        });
    }else if(event.value=='rejected'){
      this.submissionService.rejectDesignAsCustomer(obj).subscribe(res => {
        this.getdataOfDesign();
        this.toast.success('Rejected Design Successfully', '', { positionClass: 'toast-top-right' });
      },error =>{
        this.toast.error('Error in Reject Design', '', { positionClass: 'toast-top-right' });
          console.log("error is ",error);
        });
    }
}

openModal(category,submissionId,designPngFile){
  console.log("id is ",submissionId);
  console.log("category is ",category);
      let dialogRef = this.dialog.open(NewQuotesModalComponent, {
          height: '750px',
          width: '900px',
          data:{
              category:category,
              submissionId:submissionId,
              designPngFile:designPngFile,
              addQuotes:undefined,
              orderDetails:undefined
          }
      });
  }
  openModalinkExitsintQuotes(url,id){
    const dialogRef = this.dialog.open(LinkExistingQuoteModal, {
      width: '450px',
      data:{
              DesignRequest:url.imageUrl,
              requestId:id
              // svgFile:'',
              // pngFile:url,

      }
    });
    // console.log("id is ",submissionId);
    //     let dialogRef = this.dialog.open(NewQuotesModalComponent, {
    //         height: '750px',
    //         width: '900px',
    //         data:{
    //             category:category
    //         }
    //     });
    }

    addFile(){
      <any>document.getElementById('uploadCustomerFile').click();
      
    }
  
  onFileChange1(event){
  if (event.target.files.length != 0) {
      for(var i=0;i<event.target.files.length;i++){
      let file = event.target.files[i];
      this.handleReaderLoaded(file)
      }
  }
  }

  uploadedImage = null;
  async handleReaderLoaded(file) {
  this.toast.warning("Plase wait while uploading image.", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
  let uniqlogo1 = 'DesignImageCustomer' + (new Date().getTime());
      let storageRef = firebase.storage().ref();
  const fileData = await storageRef.child(`${'/upload-design-customer-chat'}/${uniqlogo1}`).put(file)
      .then(snapshot => {
      return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        var messageObject = {
          requestId:this.designId,
          messageText:downloadURL
        }
        this.submissionService.sendMessageAsCustomer(messageObject).subscribe(res => {
          // massage.value = "";
          this.getdataOfDesign();
          document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
        },error =>{
            console.log("error is ",error);
          });
      // this.uploadedImage=downloadURL;
      this.toast.success('Image. sucessfully upload/ed', '', { positionClass: 'toast-top-right' });
      }).catch(error => {
      this.toast.error("Faild to upload image. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
      });
  }

  openNewTab(url: string){
    window.open(url, "_blank");
   }

   goToQuotesDetail(id){
     this.router.navigate(['/locker-room/my-quotes-details'],{ queryParams: { quotesId:id} })
   }
}
