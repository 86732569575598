import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-loader',
  templateUrl: '../../app/Loader/loader.component.html',// ../template/loading.component.html',
  styleUrls: ['../../app/Loader/loader.component.css']//   ../style/loading.component.css']
})
export class LoadingComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}