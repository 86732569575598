import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { EditorComponent } from "../editor/editor.component";
import { Ilayer, Icolorselected } from "app/models/jersey";
import { color } from "snapsvg";
// import { Color } from "fabric";
@Component({
  selector: 'pattern-selector',
  templateUrl: '../../app/pattern-implementation/pattern.component.html',
  styleUrls: ['../../app/editor/editor.component.css', '../../app/pattern-implementation/pattern.component.css']
})
export class patternComponent {
  @Input() patternsList: any[];
  @Input() layers: any;
  @Input() selected_slide: any;
  @Input('appliedColors') selectedColorsList: Icolorselected[];
  @Output() onselectcolor: EventEmitter<any> = new EventEmitter();
  @Output() onhovercolor: EventEmitter<any> = new EventEmitter();
  @Output() onleavecolor: EventEmitter<any> = new EventEmitter();
  isExpaned: boolean;
  public isSelectedcolor: any;
  public colorselected: any;
  clickedLayer: any;
  clickedColor: any;
  public patternnameselected: any;

  constructor() {
  }

  ngOnInit() {
    this.isExpaned = true;
    this.clickedLayer = null;
    this.clickedColor = null;
    try {
      if (this.layers) {
        var arr = [];
        if (this.selected_slide == 1 && this.layers.color1) {
          // arr = this.getSelectedColor('color1');
        } else if (this.selected_slide == 2 && this.layers.color2) {
          // arr = this.getSelectedColor('color2');
        }
        if (arr[0]) {
          setTimeout(() => {
            // this.sendpatterndatatoEditor(event, this.layers, arr[0]);
          }, 10);
        }
      }
    } catch (error) {

    }
  }

  getSelectedColor(key) {
    return this.patternsList.filter(d => {
      return d.colorCode == this.layers[key].colorCode || d.colorName == this.layers[key].colorName;
    });
  }

  sendpatterndatatoEditor(event, layer, pattern) {
    if (layer)
      this.checkCondition(layer, pattern);
    this.addingDefTag(layer.layerID, pattern);
    let tmpPattern = { ...pattern };
    if (this.selected_slide == 1)
      tmpPattern.colorCode = `url(#Pattern-Home-${layer.layerID})`;
    else
      tmpPattern.colorCode = `url(#Pattern-Away-${layer.layerID})`;
    layer['color' + this.selected_slide] = tmpPattern;

    this.isSelectedcolor = layer;
    this.colorselected = tmpPattern;

    this.patternnameselected = tmpPattern.patternName;
    this.onselectcolor.emit(event);

    this.onselectcolor.emit(layer);

    this.onselectcolor.emit(tmpPattern);
  }
  jerseypatternapplyonhover(e, layer) {
    this.onhovercolor.emit(e);
    this.onhovercolor.emit(layer);
  }

  displayPatternNameonHover(pattern, layers) {
    this.addingDefTag(layers.layerID, pattern);
    this.patternnameselected = pattern.colorName;
  }

  onMouseOut(colorleft, layer: Ilayer) {
    for (let i = 0; i < this.selectedColorsList.length; i++) {
      if (this.selectedColorsList[i].layer === layer.layerID) {
        this.patternnameselected = this.selectedColorsList[i].colorName;
        break;
      }
      else
        this.patternnameselected = "";
    }
    this.onleavecolor.emit({
      old: colorleft,
      layer: layer
    });
    this.onleavecolor.emit(layer);
    if (layer && this.clickedLayer && this.clickedColor)
      this.addingDefTag(this.clickedLayer.layerID, this.clickedColor);
  }

  toggleSection(l) {
    this.isExpaned = !this.isExpaned;
    if (this.isExpaned) {
      document.getElementById(l.displayName + '-pattern').style.display = "none";
    } else {
      document.getElementById(l.displayName + '-pattern').style.display = "";
    }

  }

  addingDefTag(id, patterncode) {
    let parentId=id;
    if (this.selected_slide == 1)
      id = 'Pattern-Home-' + id;
    else
      id = 'Pattern-Away-' + id;
    let innerSvg = document.getElementById('svg').children[1];
    let tag = `<pattern id="${id}" patternUnits = "userSpaceOnUse" width = "400" height = "400" >
      <image href = "" x = "0" y = "0" width = "400" height = "400" />
        </pattern>`;
    let pattern = <any>document.getElementById('svg').children[1].children;
    for (let item of pattern) {
      if (item.tagName == 'defs') {
        // item.style.height = "400";
        // item.style.width = "400";
        let conatin = <any>item.innerHTML;
        if (conatin.includes(id)) {
          let img = <any>document.getElementById(id).children[0];
          img.setAttribute("href", patterncode.colorCode);
          document.getElementById(id).setAttribute("name", patterncode.colorName);
        } else {
          item.innerHTML = item.innerHTML + tag;
          let img = <any>document.getElementById(id).children[0];
          img.setAttribute("href", patterncode.colorCode);
          document.getElementById(id).setAttribute("name", patterncode.colorName);
        }
      }
    }

  }

  backgroundImage(path) {
    return `url(${path.colorCode})`;
  }

  checkCondition(layer, color) {
    this.clickedLayer = layer;
    this.clickedColor = color;
  }

}