import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { AdminLoginComponent } from './admin-login.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
    path: 'customer/login',
    component: AdminLoginComponent
}];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes)
    ],
    
    declarations: [
        AdminLoginComponent
    ]
})

export class AdminLoginModule {}