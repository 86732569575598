
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { DatePipe }  from '@angular/common';

@Component({
    selector: 'add-product-dialog',
    templateUrl: 'add-product.component.html',
    styleUrls: ['add-product.component.scss'],
})
export class AddProductModalComponent {
    todayDate : Date = new Date();
    constructor(
        private toast: ToastrService,
        private fb: FormBuilder,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<AddProductModalComponent>,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
        this.form = this.fb.group({
            name: ['', Validators.required],
            category: ['', Validators.required],
            price: ['', Validators.required],
            sku: ['', Validators.required],
            image: ['',Validators.required],
            shippingCost:['',Validators.required]
        });
        if(data && data!='false' && data!='true'){
            console.log("data is ",data);
            this.form.controls['name'].setValue(data.name)
            this.form.controls['category'].setValue(data.category)
            this.form.controls['price'].setValue(data.price)
            this.form.controls['shippingCost'].setValue(data.shippingCost)
            this.form.controls['sku'].setValue(data.sku)
            this.form.controls['image'].setValue(data.image)
            
            this.uploadedLogoPath = data.image; 
            this.isExistingProduct = true; 
            this.existingSku = data.$key; 
        }
    }
    newCat = '';
    catList = []; 
    isExistingProduct = false; 
    existingSku = '';
    isPrudct :any;

    ngOnInit() {
        this.af.list('/template-categories').snapshotChanges().subscribe(data => {
          this.catList = [];
          data.forEach(action => {
            const value = action.payload.val();
            let newCat = {
              key: action.key,
              name: value['name'],
              nameNoSpace: value['nameNoSpace'],
            }
            this.catList.push(newCat);
          });
        })
        if(this.data=='true'){
            this.isPrudct = true;
        }else if(this.data=='false'){
            this.isPrudct = false;
        }
      }
    form: FormGroup;

    imgSrc = null;
    changeFile(event){
        console.log(event);
        this.uploadImage(event)
    }

    //File Select & Upload
	uploadImage(event) {
		if (event.target.files.length != 0) {
			let file = event.target.files[0];
			this.handleReaderLoaded(file)
		}
	}

    base64textString;
    uploadedLogoPath=null;
	async handleReaderLoaded(file) {
		this.toast.warning("Plase wait while uploading image.", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
		let uniqlogo = 'productImage' + (new Date().getTime());
        let storageRef = firebase.storage().ref();
		const fileData = await storageRef.child(`${'/product-images'}/${uniqlogo}`).put(file)
			.then(snapshot => {
				return snapshot.ref.getDownloadURL();
			}).then(async (downloadURL) => {
				this.uploadedLogoPath = downloadURL;
				this.base64textString = downloadURL;
				this.toast.success('Image. sucessfully upload/ed', '', { positionClass: 'toast-top-right' });
			}).catch(error => {
				this.toast.error("Faild to upload image. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
			});
    }
    

    save(){
        this.form.value['image'] = this.uploadedLogoPath;
        if(!this.form.value.hasOwnProperty('isPackage')){
            if(this.data == 'true'){
                this.form.value['isPackage'] = true;
            }else if(this.data == 'false'){
                this.form.value['isPackage'] = false;
            }
        }
        if(this.isExistingProduct){
            let todayData1 = this.datePipe.transform(this.todayDate, 'dd/MM/yyyy');
            this.form.value['modifiedDate'] = todayData1;
            this.af.object(`products/${this.existingSku}`).update(this.form.value)
        } else {
            let todayData1 = this.datePipe.transform(this.todayDate, 'dd/MM/yyyy');
            this.form.value['modifiedDate'] = todayData1;
            this.form.value['createdDate'] = todayData1;
            this.af.list("/products").push(this.form.value);
        }
        this.dialogRef.close();
    }

    close(){
        this.dialogRef.close();
    }

}


