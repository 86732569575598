import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import {Http, Headers} from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable()
export class LayersService {
  submission: AngularFireObject<any>;

  private allLayersArray: Observable<any>[];

  constructor(private http:Http, private db:AngularFireDatabase){
  }

  addLayer(object){
    alert(object);
  }
}
