import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';

@Injectable()
export class AuthService {
  submission: AngularFireObject<any>;
  constructor(
    public afAuth: AngularFireAuth, private db: AngularFireDatabase, private router: Router
  ) {
    console.log("Authentication service started");
    // console.log(firebase.auth());

  }

  public loginUserObservable(email, password) { //but if still want to use an observable
    // return Observable.fromPromise(<Promise<any>> this.afAuth.auth.signInWithEmailAndPassword(email, password)) // we need to cast the Promise because for some reason Angularfire returns firebase.Promise
  }

  login(email, pass) {
    console.log('admin.................', email, pass)
    this.afAuth.auth.signInWithEmailAndPassword(email, pass)
      .then(res => {
        console.log('Nice, logging you in!!!', res);
      });
  }

  signinUser(email: string, password: string) {
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Nice, logging you in!!!', res);
        window.localStorage.setItem('emailForSignIn', email);
        window.localStorage.setItem('userrole', "Admin");
        window.localStorage.setItem('uid', res.user.uid);
        this.router.navigate(['/admin/submissions']);
      })
      .catch(
      error => console.log(error)
      );
  }

  checkAuth() {
    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        console.log('user is logged in');
        return true;
      } else {
        console.log('user not logged in...redirecting to welcome..');
        this.router.navigate(['/login']);
        return false;
      }
    });
  }

  logout() {
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  currentUser() {
    let data = firebase.auth().currentUser;
    if (data) {
      localStorage.setItem('uid', data.uid);
      localStorage.setItem('emailtocheck', data.email);
      return {
        uid: data.uid,
        email: data.email,
      }
    }
  }

}
