import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AuthService } from '../../../services/auth.service';
import { SortArrayOfSubmissionsPipe } from '../dashboard/submissions.pipe'
import { AngularFireAuth } from 'angularfire2/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import * as firebase from 'firebase/app';
import $ from "jquery";
var phoneFormatter = require('phone-formatter');
import { MatTableDataSource } from "@angular/material";
import { SubmissionsService } from 'services/submissions.service';
import { ToastrService } from 'ngx-toastr';
import 'fabric';
import { AdminNavComponent } from 'app/admin/admin-nav/admin-nav.component';
declare const fabric: any;

export interface Customer{
    email: string; 
    phone: string;
    organization: Date;
    name: string,
    status: string;
 }

@Component({
    selector: 'app-admin-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})

export class CustomersComponent {

    customers: any;
    tempcustomerslist: any;
    customersSubmissions: any;
    customerFilter: any;
    customerList:any;
    private loading: boolean = false;
    private employeeInfoTableDataSource = [];
    employeeInfoTableDataSource1:any
    customerTable: Customer[] = [];
    // employeeInfoTableDataSource = new MatTableDataSource(this.customerTable);
    // employeeInfoTableDataSource1 = new MatTableDataSource(this.customerTable);
    displayedColumns: string[] = ['name', 'email', 'phone', 'organization', 'status', 'actions'];

    constructor(
        private db: AngularFireDatabase, public router: Router, private auth: AuthService,
        private afAuth: AngularFireAuth,
        private permissionsService: NgxPermissionsService,
        private adminNav: AdminNavComponent,
        private submissionService: SubmissionsService,
        private toastr: ToastrService,
    ) {
    }

    ngOnInit() {
        let email = localStorage.getItem('emailForSignIn');
        if (!email && localStorage.getItem('userrole') != 'Admin') {
            this.router.navigate(['/customer/login']);
            localStorage.clear();
        }
        this.fetchSubmissionByEmial();
        this.getAllCustomer1();
    }

    sendTextMessage() {
        prompt("What should we text this customer?");

    }

    deleteCustomer() {
        let confirmation = confirm("Are you sure you want to delete this customer?");
        if (confirmation) {
            // delete
        }
    }
    getAllCustomer1(){
        this.submissionService.getAllCustomer().subscribe(res => {
            this.customerList = [];
            this.customerList = res.json();
            this.customerList = this.customerList.sort((a, b) => Date.parse(b.createdOne) - Date.parse(a.createdOn));
            this.customerList = [
                ...this.customerList.filter(x => x.phoneNumber !== null),
                ...this.customerList.filter(x => x.phoneNumber === null)
              ]
            this.employeeInfoTableDataSource = this.customerList.filter(element => element.emailAddress!=null);
            this.employeeInfoTableDataSource1 = new MatTableDataSource(this.employeeInfoTableDataSource);
          },error =>{
            console.log("error is ",error);
          });
    }



    async fetchSubmissionByEmial() {
        let me = this;
        this.startLoadingSpinner();

        let data1 = await  this.db.list('/submissions', ref => ref.limitToFirst(100)).snapshotChanges().subscribe(data => {
            let tempCustomer = data;
                let tempcustomers = [];
                this.tempcustomerslist = [];
                this.customersSubmissions = [];
                this.customerFilter = [];
                let tempcustomerFilter = [];
                tempCustomer.forEach(action => {
                    const value = action.payload.val();
                    const $key = action.key;
                    let obj = { $key, ...value };
                    tempcustomerFilter.push(obj);
                });
                let count = 0;
                for (let item of tempcustomerFilter) {
                    let data = { active: new Date(item.date).getTime(), ...item };
                    this.customerFilter.push(data)
                    if (count == 0)
                        this.tempcustomerslist.push(data);
                    count++;
                    let preset = this.checkDublicate(data);
                    if (!preset)
                        this.tempcustomerslist.push(data);
                }
                this.customers = this.tempcustomerslist;
                console.log("Cusomters")
                console.log(this.customers)
                this.filterMultipleRecord();
            });
    }


    checkDublicate(element) {

        for (let item of this.tempcustomerslist) {
            if (item.email == element.email) {
                return true;
                break;
            }
        }
        return false;
    }

    filterMultipleRecord() {
        this.customers = [];
        for (let item of this.tempcustomerslist) {
            let filter = this.customerFilter.filter(element => item.email == element.email);
            var largest = 0;
            for (let fil of filter) {
                if (fil.active > largest) {
                    largest = fil.active;
                }
            }
            let tempdate1 = (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear();
            let tempdate2 = new Date(largest).getDate() + '/' + (new Date(largest).getMonth() + 1) + '/' + new Date(largest).getFullYear();
            let date1 = new Date(tempdate1).getTime();
            let date2 = largest;
            let Difference_In_Time = date1 - date2;
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            Difference_In_Days = Math.round(Difference_In_Days);
            let status = (Difference_In_Days == 0) ? 'Today' : (Difference_In_Days == 1 ? "1 day ago" : Difference_In_Days + ' days ago');
            this.customers.push({ status: status, ...item, dayFilter: Difference_In_Days });


        }
        // this.employeeInfoTableDataSource = this.customers; 
        this.loading = false; 

        let numArray = this.customers.sort((a, b) => a.dayFilter - b.dayFilter);
        this.loading = false;

    }


    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }


    onSubmissionSelect(submission) {
        let filter = this.customerFilter.filter(element => submission.email == element.email);
        let largest = 0;
        let record;
        for (let fil of filter) {
            if (fil.active > largest) {
                largest = fil.active;
                record = fil;
            }
        }
        // this.adminNav.router.navigateByUrl(`admin/customer/submission-detail/${submission.$key}`)
        // this.adminNav.router.navigate(['/admin/customers/details', submission.$key]);

        this.adminNav.router.navigate(['/admin/customers/details', record.$key], { queryParams: { admin: true, emailFromAdmin: record.email } });
    }


    formatNumber(phone) {
        phone = phoneFormatter.format(phone, "(NNN)-NNN-NNNN");
        return phone;
    }

    goToCustomerDetails(element){
        console.log(element.emailAddress,"element");
        this.router.navigate(['/admin/customer-details'], { queryParams: { email:element.emailAddress } });
    }

    deleteAdminUser(element) {
        var a = confirm("Are you sure you want to delete this user?")
        if (a) {
            this.loading = true;
            var obj = {
              userId: element._id
            }
            this.submissionService.deletedAdminUser(obj).subscribe(res => {
                this.getAllCustomer1();
              this.toastr.success('Deleted User Succesfully', '', { positionClass: 'toast-top-right' });
              this.loading = false;
            });
          }
      }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.employeeInfoTableDataSource1.filter = filterValue;
        this.employeeInfoTableDataSource = this.employeeInfoTableDataSource1;
        }


}
