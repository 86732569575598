import { Component, OnInit, EventEmitter, Output } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
// import * as firebase from 'firebase/app';
import { AuthService } from '../../services/auth.service';
import { Jersey, JerseyObj } from '../admin/models/upload';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import 'fabric';

declare const fabric: any;

@Component({
  selector: 'select-uniform',
  templateUrl: './select-uniform.component.html',
  styleUrls: ['./select-uniform.component.scss']
})

export class SelectUniformComponent {

  @Output() onSelect = new EventEmitter<Jersey>();
  @Output() onImageClick = new EventEmitter<Jersey>();
  sportFilter: string;
  categoriesList = [];
  constructor(
    // private afAuth: AngularFireAuth,
    private as: AuthService,
    private dbService: AngularFireDatabase,
    private router: Router) {
    // this.getCategories();
    this.dbService.list('/jersey-styles').valueChanges().subscribe(styles => {
      console.log("Alll styles", styles)
      this.uniformTemplates = styles;
      this.selectedUniformStyles = this.loadAllUniforms();
      console.log(this.selectedUniformStyles)
    });
    this.dbService.list('/template-categories').valueChanges().subscribe(cats => {
      this.categoriesList = []
      cats.forEach(action => {
        let oneCat = {
          name: action['name'],
          nameNoSpace: action['nameNoSpace']
        }
        this.categoriesList.push(action);
        this.categoriesList.sort((a, b) => a.order - b.order);

      });
    });
  }

  selectedSportID = "";
  selectedSport = "Basketball";
  selectedUniformStyles: Jersey[] = [];
  selectedGender = "Male";
  uniformTemplates: any[];
  selectedUniform: Jersey;
  selectedUniformToCanvas: Jersey;

  private lowerImages = "";

  selectSport(event: any, src: any) {
    if (event) {
      this.selectedSportID = event.target.id.replace("_", " ");
    }
    this.selectedSport = event.target.id;
    this.sportFilter = event.target.id;
    this.selectedUniformStyles = this.getSelectedJerseyStyles(this.selectedSport, this.selectedGender);
  }

  tabChange(event) {
    if (event) {
      this.selectedSportID = event.tab.textLabel.replace(" ", "");
    }
    if (event.tab.textLabel == "All") {
      return this.loadAllUniforms();
    }
    this.selectedSport = event.tab.textLabel
    let nameWithoutSpaces = event.tab.textLabel.replace(" ", "");
    this.selectedUniformStyles = this.getSelectedJerseyStyles(nameWithoutSpaces, this.selectedGender);
  }

  getSelectedJerseyStyles(sport: string, gender: string): Jersey[] {
    let filteredUniSelection: Jersey[] = [];

    if (this.uniformTemplates) {
      this.uniformTemplates.forEach(element => {
        let curJerseyStyle: Jersey = new Jersey();
        let _category = (element.category && element.category === sport);
        let _gender = (element.gender && element.gender == gender);

        if (_category && _gender) {
          let i = 0;
          curJerseyStyle.img_url = element.files[i].img_url;
          curJerseyStyle.name = element.name;
          curJerseyStyle.display_Name = element.display_Name;
          curJerseyStyle.category = element.category;
          // curJerseyStyle.cut_name=element.cut_name;
          curJerseyStyle.cut_name = element.files[i].cutType;
          curJerseyStyle.gender = element.gender;
          curJerseyStyle.hasCuts = element.hasCuts;
          // curJerseyStyle.layoutType=element.layoutType;
          curJerseyStyle.layoutType = element.files[i].layoutType;
          curJerseyStyle.thumbnail_url = element.files[0].img_url;
          if (element.thumbnail_url) {
            curJerseyStyle.thumbnail_url = element.thumbnail_url;
          }
          filteredUniSelection.push(curJerseyStyle);
        }
      });
    }
    return filteredUniSelection;
  }

  loadAllUniforms() {
    let allUnis: any[] = [];
    if (this.uniformTemplates) {
      this.uniformTemplates.forEach(element => {
        // if(element.hasOwnProperty('files')){
        let curJerseyStyle: Jersey;
        if (element.files.length > 0) {
          let thumbnail_url = element.files[0].img_url;
          if (element.thumbnail_url) {
            thumbnail_url = element.thumbnail_url;
          }
          curJerseyStyle = new JerseyObj(element.category, element.gender, element.name, element.display_Name, element.files[0].img_url, true, element.files[0].cutType, element.files[0].layoutType, thumbnail_url, element.sku_number, element.product_id, element.jersey_sku, element.shorts_sku);
        } else {
          
            curJerseyStyle = new JerseyObj(element.category, element.gender, element.name, element.display_Name, element.files[0].img_url, false, element.files[0].cutType, element.files[0].layoutType, element.thumbnail_url, element.sku_number, element.product_id, element.jersey_sku, element.shorts_sku);
          
        }

        while (curJerseyStyle.name.includes("-"))
          curJerseyStyle.name = curJerseyStyle.name.replace("-", " ");
        curJerseyStyle.name = curJerseyStyle.name.toUpperCase();

        allUnis.push(curJerseyStyle);
      // }
    });
    }
    this.selectedUniformStyles = allUnis;
    this.sportFilter = "All";
    return allUnis;
  }

  selectStyle(selStyle: Jersey) {
    selStyle.name = selStyle.name.replace(/\s+/g, '-').toLowerCase();
    this.selectedUniform = selStyle;
    // return;
    this.removeLocalStorage();
    this.localStorage();

    console.log("Selected a uniform from thumbnail. Time to populate in canvas", selStyle);
    localStorage.setItem('category', selStyle.category);
    this.onImageClick.emit(this.selectedUniform);
  }

  loadEditorWithSelectedStyle() {
    this.selectedUniformToCanvas = this.selectedUniform;
    this.onSelect.emit(this.selectedUniformToCanvas);
  }


  removeLocalStorage() {
    for (let x in localStorage) {
      if (x.includes('patSvg')) {
        localStorage.removeItem(x);
      }
    }
  }

  localStorage() {
    localStorage.setItem('markcurve1', 'markcurve1');
    localStorage.setItem('markcurve2', 'markcurve2');
    if (localStorage.getItem('typed'))
      localStorage.removeItem('typed');
    if (localStorage.getItem('typed2'))
      localStorage.removeItem('typed2');
    if (localStorage.getItem('design'))
      localStorage.removeItem('design')
    if (localStorage.getItem('circleType'))
      localStorage.removeItem('circleType');
    if (localStorage.getItem('teamname'))
      localStorage.removeItem('teamname');
    if (localStorage.getItem('playername'))
      localStorage.removeItem('playername');
    if (localStorage.getItem('circleType1'))
      localStorage.removeItem('circleType1');
    if (localStorage.getItem('curve'))
      localStorage.removeItem('curve');
    if (localStorage.getItem('slidervalue1'))
      localStorage.removeItem('slidervalue1')
    if (localStorage.getItem('slidervalue2'))
      localStorage.removeItem('slidervalue2')
    if (localStorage.getItem('emitcolor'))
      localStorage.removeItem('emitcolor')
  }


  previewImage(){
    alert("her")
  }
}
