import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import * as firebase from 'firebase/app';
import $ from "jquery";
import { ToastrService } from 'ngx-toastr';
import { gender, categoriesList } from 'app/admin/models/upload';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-manage-clip-art',
  templateUrl: './manage-clip-art.component.html',
  styleUrls: ['./manage-clip-art.component.css']
})
export class ManageClipArtComponent implements OnInit {

  @ViewChild('cutegorydropdownRef', { static: false }) cutegorydropdownRef: AngularMultiSelect;
  customers: any;
  selectedThumbnails: any
  artList = [];
  artList1 = [];
  private loading: boolean = false;
  private addCategory: boolean = false;
  clipartName: any;
  categoryName: any;
  private selectCategory: boolean = false;
  private selectName: boolean = false;
  categoriesList1 = [];
  catmodel = new categoriesList();
  public categoriesList = [
    { "id": 1, "name": "Mascots", "itemName": "Mascots" },
    { "id": 2, "name": "Flags", "itemName": "Flags" },
    { "id": 2, "name": "Animal", "itemName": "Animal" }
  ];
  genderdropdownSettings = {
    singleSelection: true,
      text: "Select",
      searchPlaceholderText: "Select",
      classes: "simpleselectbox multiselect",
      addNewButtonText:'Add New Category',
      addNewItemOnFilter : true
    
 
  };

  // public categoriesList = [{
  //   "categoryName":"Mascots"
  // },{
  //   "categoryName":"Flags"
  // }];
  constructor(
    private db: AngularFireDatabase, public router: Router, private auth: AuthService,
    private afAuth: AngularFireAuth,
    private permissionsService: NgxPermissionsService,
    private toastr: ToastrService,


  ) {
  }

  ngOnInit() {
    // this.getCategories();
    this.getClipArtCategory();
    this.getClipArt();
    this.genderdropdownSettings = {
      singleSelection: true,
      text: "Select",
      searchPlaceholderText: "Select",
      classes: "simpleselectbox multiselect",
      addNewButtonText:'Add New Category',
      addNewItemOnFilter : true
      // labelKey: 'itemName'
    }
    this.getClipArtCategory();
  }


  // getCategories() {
  //   this.startLoadingSpinner();
  //   this.categoriesList = [];
  //   this.db.list('/categories').snapshotChanges()
  //     .subscribe(data => {
  //       this.categoriesList = [];
  //       data.forEach(action => {
  //         const value = action.payload.val();
  //         const $key = action.key;
  //         this.categoriesList.push({ $key, ...value, editing: false });
  //       });
  //       this.getClipArt();
  //       console.log('categoriesList........', this.categoriesList);

  //     });
  // }

  startLoadingSpinner() {

    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  detectThumbnail(event, layoutView) {
    this.selectedThumbnails = event.target.files[0];
  }


  addNewClipArt() {
    // || this.categoryName == undefined
    if (!this.selectedThumbnails || this.categoryName == undefined){
      this.selectCategory = true;
      return;
    } 
    if (this.clipartName == '' || this.clipartName == undefined){
      this.selectName = true;
      return;
    } 
    document.getElementById('closeDialog').click();
    this.startLoadingSpinner();
    this.SaveImage(this.selectedThumbnails);
  }


  getClipArt() {
    this.artList = [];
    this.db.list('/clip-arts').snapshotChanges()
      .subscribe(data => {
        this.artList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.artList.push({ $key, ...value });
        });
        this.artList.sort((a, b) => a.order - b.order);
        this.artList1 = this.artList;
        this.loading = false;
      });


  }
  getClipArtCategory(){
    this.categoriesList1 = [];
    this.db.list('/clipArt-category').snapshotChanges()
      .subscribe(data => {
        this.categoriesList1 = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.categoriesList1.push({ $key, ...value });
        });
      });
  }



  removeClipArt(clipArt) {
    var a = confirm("Are you sure you want to delete this Clip Art?")
    if (a) {
      this.db.list("/clip-arts").remove(clipArt.$key).then(_ => {
        this.getClipArt();
      });
    }
  }


  async	SaveImage(file) {
    this.toastr.warning("Plase wait while uploading image", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
    // this.handelLoading.emit({ 'flag': true });
    let storageRef = firebase.storage().ref();
    let uniqlogo = 'uploadedlogo' + (new Date().getTime());

    const fileData = await storageRef.child(`${'/clip-art-images'}/${uniqlogo}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {

        var newColor = {
          imagePath: downloadURL,
          name: this.clipartName,
          categoryName: this.categoryName,
          order: this.categoriesList.length
        }
        document.getElementById('closeDialog').click();
        this.loading = false;
        this.db.list("/clip-arts").push(newColor);
        this.toastr.success('Image sucessfully uploaded', '', { positionClass: 'toast-top-right' });
        this.getClipArt();

      }).catch(error => {
        this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });

      });


  }

  addNew() {
    let id = <any>document.getElementById('fileFront');
    id.value = null;
    this.clipartName = undefined;
    this.categoryName = undefined;
    this.catmodel = new categoriesList();

  }

  // addNewCategory() {
  //   let id = <any>document.getElementById('fileFront');
  //   id.value = null;
  //   this.clipartName = undefined;
  //   this.category = undefined;
  // }


  addNewCategory(name) {
    if (!name) return;
    var newCategori = {
      categoryName: name,
    }
    this.db.list("/categories").push(newCategori);
    // this.getCategories();
  }

  closeDialog() {
    document.getElementById('closeDialog').click();
  }


  removeCategories(categorie) {
    var a = confirm("Are you sure you want to delete this Categorie?")
    if (a) {
      this.db.list("/categories").remove(categorie.$key).then(_ => {
        // this.getCategories();
      });
    }
  }


  saveChanges(category) {
    category.editing = false;
    alert(JSON.stringify(category.$key));
    let key = category.$key;
    delete category.$key;
    let update = this.db.object(`/categories/${key}`).update(category);
  }

  onItemSelect(event) {
    this.categoryName = event.itemName;
    this.cutegorydropdownRef.closeDropdown();
  }


  OnItemDeSelect(event) {
    this.categoryName = undefined;
    this.cutegorydropdownRef.closeDropdown();
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.artList, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.artList.forEach((clip, index) => {
        clip['order'] = index;
        let key = clip.$key;
        delete clip.$key;
        this.db.object(`/clip-arts/${key}`).update(clip);
      });
    }
  }

  changeCategory(ev){
    console.log(ev.target.value);
    let name1 = ev.target.value;
    this.artList = this.artList1;
    this.artList = this.artList.filter(function(name) {
      return name.categoryName==name1;
    });
  }

  AddCategory(){
    let category = <any>document.getElementById('categoryName');
    if (!category.value) return;
    var newCategori = {
      categoryName: category.value,
    }
    this.db.list("/clipArt-category").push(newCategori);
    category.value = ''
  }

  AddCategories(flag){
  
  }

  changeCategory1(ev){
    let name1 = ev.target.value;
    if(name1=='+ Add New Category'){
      this.addCategory = true;
    }else{
      this.addCategory = false;
      this.categoryName = name1;
    }
    console.log(name1,"1234444");
  }

  

}
