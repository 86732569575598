import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AngularFireDatabase } from 'angularfire2/database';
import { SetOrderNameModalComponent } from './set-order-name/set-order-name.component';
import { SetQuotesModalComponent } from './set-quotes/set-quotes.component';
import { MatDialog } from '@angular/material';
import { DatePipe }  from '@angular/common';
import { SubmissionsService } from 'services/submissions.service';


@Component({
  selector: 'my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss']
})
export class MyCartComponent implements OnInit {
  isAdmin: boolean;
  role: any;
  email: any;
  quantity: any;
  private CartList = [];
  private quantityList = [];
  len:any;
  todayDate : Date = new Date();
  private abc: any;
  private allTemplates: any;
  private categoryName: any;
  private loading: boolean = false;
  private totalPrice : any;
  addMore:any;
  savingDiscount:any;
  builderConfiguration:any;
  constructor(
    private af: AngularFireDatabase,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private submissionService: SubmissionsService,
    private router: Router,
  ) {

  }

  

  ngOnInit() {
    this.getMycart('');
    this.getQuantity();
    this.submissionService.getConfiguration().subscribe(res => {
      this.builderConfiguration = res.json(); 
     });
    // this.submissionService.getCart().subscribe(res => {
    //   let user = JSON.parse((<any>res)._body);
    //   console.log("res@@@@@@@@2@@@@################################",res.json());
    //   console.log("res################################",user);
      
    // });    
  }

  
  removeItemFromCart(product) {
    var a = confirm("Are you sure you want to remove this product in My Cart?")
    if (a) {
      this.af.list("/mycart").remove(product.$key).then(_ => {
        // this.getMycart('quantity');
      });
    }
  }
  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  addQuantity(template,name,i){
    if(name=='plus'){
      const $key = template.$key;
      var plus = <any>document.getElementById('number-' + i);   
      var a = parseInt(plus.value);
      var tatal = a + 1;
      plus.value = tatal;
      template['quantity'] = tatal;
      let abc = tatal * parseFloat(template.price)
      template['totalPrice'] = abc.toFixed(2);
          for (var a = 0; a < this.quantityList.length; a++) {
            if(this.quantityList[a].quantity1 <=tatal && tatal<=this.quantityList[a].quantity2){
                 template['tbulkDiscount'] = this.quantityList[a].discount / 100 * abc;
                }
              }
      delete template.$key;
      // var updatedata = {
      //   cartId:$key,
      //   cartData:template
      // }
      // this.submissionService.updateCart(updatedata).subscribe(res => {
      //   let user = JSON.parse((<any>res)._body);
      //   console.log("res@@@@@@@@2@@@@################################",res.json());
      //   console.log("res################################",user);
      
      // });
      this.af.object(`/mycart/${$key}`).update(template).then(_ => {
        this.getMycart('quantity');
      });
    
    }else{
      const $key = template.$key;
      var minus = <any>document.getElementById('number-' + i);
      if(minus.value > 1){
      var a = parseInt(minus.value);
      var tatal1 = a - 1;
      minus.value = tatal1;
      template['quantity'] = tatal1;
      let abc = tatal1 * parseFloat(template.price)
      template['totalPrice'] = abc.toFixed(2);
      for (var a = 0; a < this.quantityList.length; a++) {
        if(this.quantityList[a].quantity1 <=tatal1 && tatal1<=this.quantityList[a].quantity2){
             template['tbulkDiscount'] = this.quantityList[a].discount / 100 * abc;
            }
          }
      delete template.$key;
      this.af.object(`/mycart/${$key}`).update(template).then(_ => {
        // this.getMycart('quantity');
      });
    }
    }
  }

  getMycart(quantity) {
    this.CartList = [];
    if(quantity != 'quantity'){
    this.startLoadingSpinner();
    this.loading = true;
    }
    this.af.list('/mycart').snapshotChanges()
      .subscribe(data => {

        
        this.CartList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          if(value['email']==localStorage.getItem('emailForSignIn')){
            this.CartList.push({ $key, ...value }); 
          }
        });
        setTimeout(() => {
          this.loading = false;
        }, 1500);
          var total = 0;
          for (var i = 0; i < this.CartList.length; i++) {
                  total = total + (parseFloat(this.CartList[i].totalPrice) - parseFloat(this.CartList[i].tbulkDiscount) + (parseFloat(this.CartList[i].shippingCost)*(parseFloat(this.CartList[i].quantity))));
                }
          this.totalPrice = total.toFixed(2);

          console.log("submission id is ,",this.CartList);
         
      });
}

getQuantity() {
  this.quantityList = [];
  this.af.list('/quantity').snapshotChanges()
    .subscribe(data => {
      this.quantityList = [];
      data.forEach(action => {
        const value = action.payload.val();
        const $key = action.key;
        this.quantityList.push({ $key, ...value });
      });
      this.addMore = parseInt(this.quantityList[0].quantity2) - 1;
      this.savingDiscount = parseInt(this.quantityList[1].discount);
    });
}


  nextStep() {
    // show save name modal 
    let dialogRef = this.dialog.open(SetOrderNameModalComponent, {
      width: '500px',
      data: {
        productList : this.CartList,
        totalPrice : this.totalPrice,
        isQuotes:false
        // category:this.category
      }
    });
  }

  nextStep1() {

    var totalPrice = parseFloat(this.totalPrice);
    let dialogRef = this.dialog.open(SetQuotesModalComponent, {
      width: '500px',
      data: {
        productList : this.CartList,
        totalPrice : totalPrice 
        // category:this.category
      }
    });
  }

  sendNewRequest(){
    this.router.navigate(['/locker-room/request-design'])
  }
}