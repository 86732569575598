import { Component, OnInit, ViewChild } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SubmissionsService } from '../../../services/submissions.service';
import {ShopifyService } from '../../../services/shopify.service';
import 'fabric';
import { AuthService } from 'services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from "@angular/material";
// import { document } from 'ngx-bootstrap/utils/facade/browser';
// declare const fabric: any;

@Component({
  selector: 'shopify-settings',
  templateUrl: './shopify-settings.component.html',
  styleUrls: ['./shopify-settings.component.scss']
})

export class ShopifySettingsComponent {
  private loading: boolean = false;
  private email: boolean = false;
  adminList: any;
  errorMessage1: any;
  displayedColumns3:string[] = ['Image', 'Title', 'Variant Count', 'Price'];
  displayedColumns2:string[] = ['check', 'Name', 'Count'];
  private shopifyList = [];
  private collectionList = [];
  private collectionList1 = [];
  selected = 'none';
  collectionID = [];

  constructor(private subService: SubmissionsService,
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService,
    private toast: ToastrService,
    private shopifyService: ShopifyService,
  ) {
  }

  ngOnInit() {
    this.getShopifyProduct();
    this.getAllCollections();
  }
  getShopifyProduct(){
    this.loading = true;
    this.shopifyService.getAllShopifyProduct().subscribe(data => {
     this.shopifyList = [];
       this.shopifyList = data.json();
       console.log("this.shopifyList",this.shopifyList);
       this.loading = false;
    });
   }

   getAllCollections(){
    this.loading = true;
    this.shopifyService.getAllCollection().subscribe(data => {
      this.collectionList = [];
      this.collectionList = data.json();
      this.collectionList1 = data.json();
      this.collectionList1 = this.collectionList1.filter(element => {
        return element.isChecked == true;
      });
     
      console.log("this.collectionList1",this.collectionList1);
      this.loading = false;
    });
   }

   save(){
    this.loading = true;
    this.collectionList.forEach(element => {
      if(element.hasOwnProperty('isChecked')){
        var obj = {
          id:element.id,
          isChecked:element.isChecked
        }
        this.collectionID.push(obj);
      }
    })
     var saveObj = {
       collectionIds:this.collectionID
     }
    this.shopifyService.saveCheckCollection(saveObj).subscribe(data => {
      this.collectionList = data.json();
      this.collectionList1 = data.json();
      this.collectionList1 = this.collectionList1.filter(element => {
        return element.isChecked == true;
      });
      this.loading = false;
    });
   }

   applyFilter(ev){
     this.loading = true;
    console.log("ev",ev.value);
    if(ev.value=="none"){
      this.getShopifyProduct();
    }else{
      var obj = {
        collectionId:ev.value
        }
        this.shopifyService.filterByTitleShopify(obj).subscribe(data => {
          console.log("data.json() Filterrrrrr%%%%%",data.json());
          this.shopifyList = data.json();
          this.loading = false;
        });
    }
   }
}
