import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';


@Component({
  selector: 'app-customer-my-design-requests',
  templateUrl: './my-design-requests.component.html',
  styleUrls: ['./my-design-requests.component.scss']
})
export class AdminMyDesignRequestsComponent implements OnInit {
  requestdesignList = [];
  displayedColumns: string[] = ['Design','Date', 'Actions'];
  private loading:boolean = false;
  expiration_date: Date;
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private router: Router,
    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    this.getDesignData(); 

  }



  getDesignData(){
    this.submissionService.getAdminRequestDesign().subscribe(res => {
      this.requestdesignList = [];
      this.requestdesignList = res.json();
      this.requestdesignList = this.requestdesignList.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    },error =>{
      console.log("error is ",error);
    });
  }

  

  goToOrderDetails(element){
    this.router.navigate(['/admin/my-design-details'],{ queryParams: { designId: element._id} })
  }

  onOpenMenu(menu: any): void {
  }

  actionOrder(action,element){
  }

  deleteDesignById(id){
    console.log(id);
    this.loading = true;
    var obj = {
      designRequestId:id
    }
     let a = confirm("Are you sure you want to delete this Design?")
    if (a) {
      this.loading = true;
      this.submissionService.deletedDesignById(obj).subscribe(res => {
        console.log(res.json());
        this.toastr.success('Deleted Design Successfully', '', { positionClass: 'toast-top-right' });
        this.getDesignData();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
       });
  }
  }
}
