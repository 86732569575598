import { Component, OnInit } from '@angular/core';
// import { ColorPickerService } from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
// import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
// import { FirebaseApp } from 'angularfire2';
import { Jersey, Ifile, UniformStyle, sport, FileObj, gender, reversible, uniformtypes,categoriesList } from '../models/upload';
import { cuts } from '../../models/jersey';
import { UploadService } from '../../../services/upload.service';
import * as _ from 'lodash';
// import * as firebase from 'firebase';
import 'fabric';
import { ButtonsModule } from 'ngx-bootstrap';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { ViewChild } from '@angular/core'
// import { AuthService } from 'services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ShopifyProductsComponent } from '../../dialogs/shopify-products/shopify-products.component';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


declare const fabric: any;

@Component({
	selector: 'add-new-style-v2',
	templateUrl: './add-new-style-v2.component.html',
	styleUrls: ['./add-new-style-v2.component.css']
})


export class AddNewStyleV2Component implements OnInit {

	// @ViewChild(AngularMultiSelect, { static: false }) varietalDropdown: AngularMultiSelect;
	// @ViewChild('genderdropdownRef', { static: false }) genderdropdownRef: AngularMultiSelect;
	// @ViewChild('cutdropdownRef', { static: false }) cutdropdownRef: AngularMultiSelect;
	// @ViewChild('typedropdownRef', { static: false }) typedropdownRef: AngularMultiSelect;
	// @ViewChild('revcutdropdownRef', { static: false }) revcutdropdownRef: AngularMultiSelect;


	dropdownList = [];
	selectedItems = [];
	selectedsport = [];
	selectedsport1 = [];
	dropdownSettings = {};
	simpledropdownSettings = {};
	simpledropdownSettingsSport = {};
	cutdropdownSettings = {};
	genderdropdownSettings = {};
	styleMode: any;
	selectedThumbnails: any;
	thumbnails: any = [];
	thumbnailFiles: any = [];
	UniformTypeModel: any;
	hacutarry: any[];
	modesel: any;
	catList = [];
	
    public categoriesList = [];

	// new
	checkTemplateType: any;

	ngOnInit() {
	}

	submitted = false;
	hasSportsError = false;
	private basePath: string = "/jersey-styles";
	cutsCount: number;
	folderName: string;
	selectedFiles: FileList;
	files: any = [];
	filess: any = [];
	newJersey: UniformStyle;
	categoryArray: any = [];
	item: Ifile;
	sportmodel = new sport();
	cutsmodel = new cuts();
	gendermodel = new gender();
	reversiblemodel = new reversible();
	hasCutsError = false;
	private cutType: string;

	constructor(
		private us: UploadService,
		// private auth: AuthService,
		public router: Router,
		private af: AngularFireDatabase,
		public dialog: MatDialog
	) {
	}

	importFromShopify(){
		const dialogRef = this.dialog.open(ShopifyProductsComponent, {
			width: '650px'
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		  });
	}

	startFormScrach(){
		this.router.navigate(['/admin/add-new-product']);
	}
}
