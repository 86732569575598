import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AuthService } from '../../../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import * as firebase from 'firebase/app';
import $ from "jquery";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-patterns',
  templateUrl: './manage-patterns.component.html',
  styleUrls: ['./manage-patterns.component.css']
})
export class ManagePatternsComponent implements OnInit {




  customers: any;
  selectedThumbnails: any
  selectedThumbnailsForPre: any
  patterns: any;
  private loading: boolean = false;
  patternName: any;
  constructor(
    private db: AngularFireDatabase, public router: Router, private auth: AuthService,
    private afAuth: AngularFireAuth,
    private permissionsService: NgxPermissionsService,
    private toastr: ToastrService,


  ) {
  }

  ngOnInit() {
    this.getPatterns()
  }

  startLoadingSpinner() {

    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  detectThumbnail(event, layoutView) {
    this.selectedThumbnails = event.target.files[0];
  }

  detectThumbnailForPre(event, layoutView) {
    this.selectedThumbnailsForPre = event.target.files[0];
  }


  addNewPattern() {

    if (!this.selectedThumbnails || !this.selectedThumbnailsForPre) return;
    if (this.patternName == '' || this.patternName == undefined) return;
    document.getElementById('closeDialog').click();
    this.startLoadingSpinner();
    this.SaveImage(this.selectedThumbnails, this.selectedThumbnailsForPre);
  }


  getPatterns() {
    this.patterns = [];
    this.startLoadingSpinner();
    this.db.list('/patterns').snapshotChanges()
      .subscribe(data => {
        this.patterns = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.patterns.push({ $key, ...value });
        });
        this.loading = false;
      });


  }



  removePattern(pattern) {
    var a = confirm("Are you sure you want to delete this Pattern?")
    if (a) {
      this.db.list("/patterns").remove(pattern.$key).then(_ => {
        this.getPatterns();
      });
    }
  }


  async	SaveImage(file, previewFile) {
    this.toastr.warning("Plase wait while uploading image", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
    // this.handelLoading.emit({ 'flag': true });
    let storageRef = firebase.storage().ref();
    let uniqlogo = 'uploadedlogo' + (new Date().getTime());

    const fileData = await storageRef.child(`${'/patterns-images'}/${uniqlogo}`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      }).then(async (downloadURL) => {
        let uniqlogoForPre = 'uploadedlogo' + (new Date().getTime());
        await storageRef.child(`${'/patterns-images'}/${uniqlogoForPre}`).put(previewFile)
          .then(snapshot => {
            return snapshot.ref.getDownloadURL();
          }).then(async (downloadURLForPre) => {
            var patterns = {
              colorCode: downloadURL,
              colorCodeForPre: downloadURLForPre,
              colorName: this.patternName
            }
            document.getElementById('closeDialog').click();
            this.loading = false;
            this.db.list("/patterns").push(patterns);
            this.toastr.success('Image sucessfully uploaded', '', { positionClass: 'toast-top-right' });
            this.getPatterns();
          }).catch(error => {
            this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });

          });

        // var patterns = {
        //   colorCode: downloadURL,
        //   colorName: this.patternName
        // }
        // document.getElementById('closeDialog').click();
        // this.loading = false;
        // this.db.list("/patterns").push(patterns);
        // this.toastr.success('Image sucessfully uploaded', '', { positionClass: 'toast-top-right' });
        // this.getPatterns();

      }).catch(error => {
        this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });

      });


  }

  addNew() {
    let id = <any>document.getElementById('fileFront');
    id.value = null;
    this.patternName = undefined;
  }

  closeDialog() {
    document.getElementById('closeDialog').click();
  }







}
