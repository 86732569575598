import { Component, OnInit, Inject } from '@angular/core';
import {MatListModule} from '@angular/material/list'; 
import {MatRadioModule} from '@angular/material'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
import { AddShippingAddressComponent } from '../../dialogs/add-shipping-address/add-shipping-address.component';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SubmissionsService } from '../../../services/submissions.service';


import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
@Component({
  selector: 'app-edit-shipping',
  templateUrl: './edit-shipping.component.html',
  styleUrls: ['./edit-shipping.component.css']
})
export class EditShippingComponent implements OnInit {
  allAddresses = [
				 ];
 selectedIndex:any;
 selectedAddress:any;
 selectedAddressType:any;
 private loading: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  				public dialog: MatDialog,
  				private toastr: ToastrService,
          private submService : SubmissionsService) {
    console.log(data);
            if(data){
              if(data.addr){
                this.selectedAddress = data.addr._id;
              }
              this.selectedAddressType = data.type;
            }
            this.getAddress();
           }

  ngOnInit() {
    // this.addressData = {
    //     'orderId' : this.orderId,
    //     'billingAddress' : addressForm.value
    //   };
  }

  handleAddressChange(evt){
  	this.selectedAddress = evt.value;
    this.selectedIndex = this.allAddresses.findIndex(x => x._id == this.selectedAddress);
  }

  addNewAddress(): void {
    //this.dialogRef.close();
    const dialogRef = this.dialog.open(AddShippingAddressComponent, {
      //width: '650px',
      data: {type : this.selectedAddressType}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        //console.log(result);
        this.allAddresses.push(result);
        console.log(this.allAddresses);
      }
    });
  }

  selectAddress(){
  	if(this.selectedAddress != undefined){
  		this.toastr.success('Success!', 'Address selected successfully!');
  	}else{
  		this.toastr.error('Error!', 'Please select an address!');
      return false;
  	}
  }

  setAddressOnOrderDetails(){
    return this.allAddresses[this.selectedIndex];
  }

  
  getAddress(){
    this.loading = true;
    this.submService.getAddresses()
       .subscribe(data => {
         this.allAddresses = JSON.parse((<any>data)._body);
         console.log(JSON.parse((<any>data)._body));
          this.loading = false;
    });
  }
}
