import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';

@Component({
  selector: 'app-customer-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {

  displayedColumns1: string[] = ['Design Number', 'Design Name', 'Placed', 'Due', 'Status', 'Total', 'Action'];
  displayedColumns2: string[] = ['Order Number', 'Order Name', 'Total', 'Action'];
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private nav: CustomerNavComponent
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() { 
  }

}
