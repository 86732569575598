import { Injectable, Pipe, PipeTransform } from '@angular/core';

//Pipe for Sorting Submission on Date -Latest First
@Pipe({name: 'SortArrayOfSubmissionsPipe'})

@Injectable()
export class SortArrayOfSubmissionsPipe implements PipeTransform {

  transform(submissions: any[]): any[]{
    if(submissions != null){
        return submissions.sort((a, b) => {
           if (new Date(a.date) > new Date(b.date)) {
                return -1;
           }
           if (new Date(a.date) < new Date(b.date)) {
                return 1;
           }
                return 0;
          });
   }
  }
}
