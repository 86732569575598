import { Component, OnInit, Inject } from '@angular/core';
import {MatListModule} from '@angular/material/list'; 
import {MatRadioModule} from '@angular/material'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
// import { LinkExistingQuoteModal } from '../../dialogs/link-existing-quote-modal/link-existing-quote-modal.component';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SubmissionsService } from '../../../services/submissions.service';

import { Router } from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
@Component({
  selector: 'app-link-existing-quote-modal',
  templateUrl: './link-existing-quote-modal.component.html',
  styleUrls: ['./link-existing-quote-modal.component.css']
})
export class LinkExistingQuoteModal implements OnInit {
  allAddresses = [
				 ];
 selectedIndex:any;
 selectedAddress:any;
 selectedAddressType:any;
 orderList:any;
 quotesId:any;
 quotesData:any;
 private loading: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
          public dialog: MatDialog,
          public dialogRef: MatDialogRef<LinkExistingQuoteModal>,
          private toastr: ToastrService,
          private router: Router,
          private submService : SubmissionsService) {
    
            // if(data){
            //   if(data.addr){
            //     this.selectedAddress = data.addr._id;
            //   }
            //   this.selectedAddressType = data.type;
            // }
            
           }

  ngOnInit() {
    this.getQuotes();
  }



  addNewAddress(): void {
    
  }

  

  setAddressOnOrderDetails(){
  }

  
  getQuotes(){
    this.submService.getCustomersQuotes().subscribe(res => {
      this.orderList = [];
      this.orderList = JSON.parse((<any>res)._body);

      this.orderList = this.orderList.filter(function( element ) {
       return element.data !== undefined;
      });


      setTimeout(() => {
       this.orderList = this.orderList.filter(function( element ) {
         return element.data.quotesName !== undefined;
        });
        this.loading = false;
        console.log("this.orderList !!!!!!!!",this.orderList);
      }, 1000);
      console.log("this.orderList !!!!!!!!",this.orderList);

  
});
}

linkedQuotes(quotes){
  this.quotesId = quotes._id;
  this.quotesData = quotes;  
}

selectQuotes(){
  this.loading = true;
  // console.log("DesignRequest",this.data.DesignRequest);
  // console.log("requestId",this.data.requestId);
  // console.log("this.quotesId !!!!!!!",this.quotesId);
  // console.log("this.quotesData @@@@@@@@@",this.quotesData);
  if(this.data.submissionId){
    var obj = {
      submissionId:this.data.submissionId,
      quoteId:this.quotesId
    }
    this.submService.updateQuoteWithSubmission(obj).subscribe(res => {
      this.loading = false;
      console.log("res is ",res);
      this.dialogRef.close(this.data.submissionId);
    },error =>{
      this.loading = false;
    });
  }else{
    var obj1={
      requestId: this.data.requestId,
      imageUrl:this.data.DesignRequest,
      quoteId: this.quotesId,
      quoteName: this.quotesData.data.quotesName
   }
   this.submService.linkExistingQuotes(obj1).subscribe(res => {
       console.log("res",res.json());
         this.loading = false;
         this.dialogRef.close();
       // this.router.navigate(['/locker-room/my-quotes-details'],{ queryParams: { quotesId:this.quotesId} })
     }); 

  }
  

  // for(var i=0;i<this.quotesData.data.productList.length;i++){
  //   var obj = {
  //     svgFile:this.data.svgFile ? this.data.svgFile : '',
  //     pngFile:this.data.pngFile ? this.data.pngFile : ''
  //   }
  //   this.quotesData.data.productList[i]['design'] = obj;
  // }
    // var obj1 = null;
    // obj1 = {
    // quoteId: this.quotesId,
    // quoteData:this.quotesData
    // }
    // console.log("obj1",obj1);
    // this.submService.updateQuotes(obj1).subscribe(res => {
    //   console.log("res",res.json());
    //   this.dialogRef.close();
    //   this.loading = false;
    //   this.router.navigate(['/locker-room/my-quotes-details'],{ queryParams: { quotesId:this.quotesId} })
    // }); 
}
}
