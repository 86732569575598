import { Component, OnInit } from '@angular/core';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { MatTableDataSource } from "@angular/material";

@Component({
  selector: 'app-customer-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerCatalogComponent implements OnInit {
  columnsToDisplay = ['SKU', 'Name', 'Category', 'Created', 'Modified'];
  expandedElement: null;
  dataSource :any;
  dataSource1:any;
  private quantityList = [];
  private productList = [];
  private packageList = [];
  private loading: boolean = false;
  constructor(private nav: CustomerNavComponent,
    private af: AngularFireDatabase,
    ) { }
  
    startLoadingSpinner() {
      this.loading = true;
      setTimeout(function () {
      }.bind(this), 1500);
    }
  ngOnInit() {
    this.getProducts();
    this.getQuantity();
    } 
  getQuantity() {
      this.quantityList = [];
      this.startLoadingSpinner();
      this.loading = true;
      this.af.list('/quantity').snapshotChanges()
        .subscribe(data => {
          this.quantityList = [];
          data.forEach(action => {
            const value = action.payload.val();
            const $key = action.key;
            this.quantityList.push({ $key, ...value });
          });
          this.loading = false;
        });
    }

    getProducts() {
      this.productList = [];
      this.packageList = [];
      this.startLoadingSpinner();
      this.loading = true;
      this.af.list('/products').snapshotChanges()
        .subscribe(data => {
          this.productList = [];
          this.packageList = [];
          data.forEach(action => {
            const value = action.payload.val();
            const $key = action.key;
            if(value['isPackage']==undefined || value['isPackage']=='undefined' || value['isPackage']=='false' || value['isPackage']==false){
              this.productList.push({ $key, ...value });
            }else{
              this.packageList.push({ $key, ...value });
            }
            this.loading = false;
          });
          this.dataSource = new MatTableDataSource(this.productList);
          this.dataSource1 = new MatTableDataSource(this.packageList);
        });
    }

    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      this.productList = this.dataSource;
    }
    applyFilterForPakage(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource1.filter = filterValue;
      this.packageList = this.dataSource1;
    }
   
  }

  


