import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
    path: 'homepage',
    component: HomePageComponent
}];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    
    declarations: [
        HomePageComponent
    ]
})

export class HomePageModule {}