import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';
import { STATUS } from 'app/customer-admin/order/statusColor';

@Component({
  selector: 'app-admin-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class AdminOrderComponent implements OnInit {
  orderList = [];
  order_status:any;
  dataSource1:any;
  arrayStatus :any;
  allOrderStatus:any;
  displayedColumns: string[] = ['Order', 'Status', 'Price','Date',  'Actions'];
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private router: Router,
    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    this.order_status = '-- None --';
    this.arrayStatus = STATUS;
    this.getOrderData(); 
    this.submissionService.getOrderStatus().subscribe(res => {
      this.allOrderStatus = JSON.parse((<any>res)._body);
       console.log(JSON.parse((<any>res)._body));
       
       
     });

  }

  getOrderData(){
    this.submissionService.getOrderListAsAdmin().subscribe(res => {
      this.orderList = [];
      this.orderList = JSON.parse((<any>res)._body);
      console.log("this.orderList !!!!!!!",this.orderList);
      this.orderList = this.orderList.sort((a, b) => Date.parse(b.data.dateCreated) - Date.parse(a.data.dateCreated));
      this.dataSource1 = new MatTableDataSource(this.orderList);
     });
  }

  applyFilter(ev) {
    let filterValue = ev.value;
    this.order_status = ev.value;
    this.dataSource1.filter = filterValue;
    this.orderList = this.dataSource1;
  }

  goToOrderDetails(element){
    // alert("Order details see goToOrderDetails function call")
    // TODO Vishal pass order id as param 
    this.router.navigate(['/admin/order-details'],{ queryParams: { orderId: element._id} })
  }

  onOpenMenu(menu: any): void {
  }

  actionOrder(action,element){
   if(action=='view'){
    this.router.navigate(['/admin/order-details'],{ queryParams: { orderId: element.orderNumber} })
   }else if(action=='delete'){
    var a = confirm("Are you sure you want to remove this Order?")
    if (a) {
      this.af.list("/order").remove(element.$key).then(_ => {
      });
    }
   }
  }

  getBackgroundColor(status){
    let color;
    if(status=='IN PROCESS' || status=='IN_PROCESS'){
    color = this.arrayStatus['IN_PROCESS'].color
    }else if(status=='INCOMPLETE'){
      color = this.arrayStatus['INCOMPLETE'].color
    }else if(status=='ORDER_RECIEVED' || status=='ORDER RECIEVED'){
      color = this.arrayStatus['ORDER_RECIEVED'].color
    }else if(status=='COMPLETE'){
      color = this.arrayStatus['COMPLETE'].color
    }else{
      color = this.arrayStatus[status].color
    }
    return color;
  }
}
