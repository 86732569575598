import { Component, OnInit } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import {NgForm} from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
// import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
import {SubmissionsService} from '../../services/submissions.service';
import FontFaceObserver from 'fontfaceobserver';
import * as jsPDF from 'jspdf';

declare var Snap: any;
declare var mina: any;


@Component({
  selector: 'app-viewdesign',
  templateUrl: './viewdesign.component.html',
  styleUrls: ['./viewdesign.component.css']
})
export class ViewdesignComponent implements OnInit {
  snapSvg: any;
  // route: any;
  tabledata: any[];
  rows: any[];
  key: any;
  loading: boolean;

  constructor(private subService: SubmissionsService, 
    // private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router){
    this.loading=true;
  }
  ngOnInit() {

      // this.snapSvg = Snap("#svgElement");
      // const g = this.snapSvg.g();

      this.route.params.subscribe((param: any) => {
        var that=this;
        that.tabledata=[];
        that.rows=[];
        this.key = param['id'];
        // this.af.object(`/submissions/${this.key}`).subscribe(sub => {
        // // this.af.object(`/design/${this.key}`).subscribe(sub => {
        //   console.log("submission........", sub);
        //   if(sub.svgFileHtml){
        //     document.getElementById("svgOuterElement").innerHTML= sub.svgFileHtml;
        //   }else if(sub.base64_OutSide) {
        //     var img1 = document.createElement("img");
        //     img1.src = sub.base64_OutSide;
        //     img1.style.minHeight = "500px";
        //     img1.style.width = "760px";
        //     document.getElementById("svgOuterElement").style.display = "flex";
        //     document.getElementById("svgOuterElement").style.justifyContent = "center";
        //     document.getElementById("svgOuterElement").innerHTML= img1.outerHTML;
        //   }
      
        // });
      });


  }

}
