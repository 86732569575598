import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import * as firebase from 'firebase/app';
import $ from "jquery";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.css']
})
export class AddCategoriesComponent implements OnInit {

  private categoriesList = [];

  private loading: boolean = false;
  patternName: any;
  constructor(
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService
  ) {
  }

  ngOnInit() {
    this.getCategories();
  }

  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }




  addNewCategory(name,value) {
    if (!name && !value) return;
    var newCategori = {
      categoryName: name,
      value: value,
    }
    this.af.list("/categories").push(newCategori);
    // this.getCategories();
  }


  removeCategories(categorie) {
    var a = confirm("Are you sure you want to delete this Categorie?")
    if (a) {
      this.af.list("/categories").remove(categorie.$key).then(_ => {
        // this.getCategories();
      });
    }
  }

  getCategories(){
    this.startLoadingSpinner();
    this.categoriesList = [];
    this.af.list('/categories').snapshotChanges()
      .subscribe(data => {
        this.categoriesList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.categoriesList.push({ $key, ...value,editing:false });
        });
        this.loading = false;
      });
  }


  toggleEditing(color) {
   
  }


  saveChanges(category) {
    category.editing = false;
    alert(JSON.stringify(category.$key));
    let key = category.$key;
    delete category.$key;
    let update = this.af.object(`/categories/${key}`).update(category);
  }



}
