import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AngularFireDatabase } from 'angularfire2/database';


@Component({
  selector: 'customer-wallet',
  templateUrl: './customer-wallet.component.html',
  styleUrls: ['./customer-wallet.component.scss']
})
export class CustomerWalletComponent implements OnInit {
  isAdmin: boolean;
  role: any;
  email:any;
  constructor() {
  }

  ngOnInit(){
    
  }


 

}
