import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material";
import { EditShippingComponent } from '../../../dialogs/edit-shipping/edit-shipping.component';
import { ImportRosterComponent } from '../../../dialogs/import-roster/import-roster.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { SubmissionsService } from '../../../../services/submissions.service';
import { Component, OnInit, ViewChild } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SelectDesignComponent } from '../../../dialogs/select-design/select-design.component';
import { NewQuotesModalComponent } from 'app/admin/dashboard/new-quotes/new-quotes.component';
import 'fabric';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
declare const fabric: any;

@Component({
  selector: 'quotes-details',
  templateUrl: './my-quotes-details.component.html',
  styleUrls: ['./my-quotes-details.component.scss']
})
export class AdminMyQuotesDetailsComponent implements OnInit {
  orderDetails: any;
  updateDetails = [];
  customerList = [];
  orderId: any;
  productName: any;
  displayedColumns: string[] = ['Order', 'Status', 'Price', 'Actions'];
  dataSource: MatTableDataSource<{ orderName: string, orderNumber: number, orderTotal: number, dateCreated: Date, products: any }>;
  allQuotesStatus: string[] = ['NEW', 'PENDING_DESIGN', 'DONE'];
  rosterTableDATA = [
    { Item: { img: 'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name: 'Duffle Bags' }, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit: 'Edit Roster' },
    { Item: { img: 'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name: 'Duffle Bags' }, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit: 'Edit Roster' },
    { Item: { img: 'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name: 'Duffle Bags' }, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit: 'Edit Roster' },
    { Item: { img: 'https://trello-attachments.s3.amazonaws.com/5cd137b3fc913c6cc4263934/5f02c693e4b1098d629eac80/b441973f2b24b04987aa81c762aed11d/image.png', name: 'Duffle Bags' }, Design: 'No design added', Roster: 'No roster added', Select: 'Select Design', Edit: 'Edit Roster' }
  ];
  rosterTableColumns: string[] = ['Item', 'Design', 'Roster', 'Select', 'Edit'];
  rosterTableSource = this.rosterTableDATA;
  order_status: any
  editRoster: boolean = false;
  isEdit: boolean = false;
  private loading: boolean = false;
  private quantityList = [];
  addMore: any;
  savingDiscount: any;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    if (window.location.href.split('?quotesId=')[1]) {
      this.orderId = window.location.href.split('?quotesId=')[1];
    }
    this.order_status = "none";
    this.loading = true;
    this.getAllQuotesData();
    this.getQuantity();
    
  }
  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  getCustomer(email) {
    this.submissionService.getAllCustomer().subscribe(res => {
      this.customerList = [];
      this.customerList = res.json();
      this.customerList = this.customerList.filter(element => element.emailAddress == email);
      // console.log("this.employeeInfoTableDataSource",this.customerList[0].itemsSold.name);
      this.loading = false;
    }, error => {
      console.log("error is ", error);
    });
  }

  getAllQuotesData() {
    this.loading = true;
    this.submissionService.getDeTailsQuotes(this.orderId).subscribe(res => {
      this.orderDetails = [];
      this.orderDetails = res.json();
      if (this.orderDetails.data && this.orderDetails.data.email) {
        this.getCustomer(this.orderDetails.data.email);
      }else{
        this.getCustomer(this.orderDetails.data.productList[0].email);
      }
      if(this.orderDetails.status=='NEW'){
        this.isEdit = true;
      }
      this.loading = false;


    });

  }

  goToOrderDetails() {
    alert("Order details")
  }

  goToLink(url: string) {
    if (url == '') {
      window.open(url, "_blank");
    } else {
      window.open(url, "_blank");
    }

  }

  selectDesign(template): void {
    // this.orderDetails['productId'] = template.key;
    const dialogRef = this.dialog.open(SelectDesignComponent, {
      width: '650px',
      data: this.orderDetails

    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllQuotesData();
    });
  }

  editQuotesQuantity() {
    this.isEdit = true;
  }
  saveQuotesQuantity(array) {
    var total = 0;
    this.loading = true;
    var v = null;
    this.updateDetails = [];
    array.forEach(element => {
      console.log("")
      var totalCost = (element.costPerProduct * Number(element.quantity));
      for (var a = 0; a < this.quantityList.length; a++) {
        if (this.quantityList[a].quantity1 <= Number(element.quantity) && Number(element.quantity) <= this.quantityList[a].quantity2) {
          console.log("this.quantityList[a].discount", this.quantityList[a].discount);
          v = (this.quantityList[a].discount / 100) * totalCost;
        }
      }
      var obj = {
        bulkdiscount: v.toFixed(2),
        category: element.category,
        costPerProduct: element.costPerProduct,
        email: element.email,
        key: element.key,
        productImg: element.productImg,
        productName: element.productName,
        quantity: Number(element.quantity),
        shippingCost: element.shippingCost,
        sku: element.sku,
        submissionId: element.submissionId,
        totalPrice: (element.costPerProduct * Number(element.quantity)).toFixed(2)
      }
      this.updateDetails.push(obj);
    });

    for (var i = 0; i < this.updateDetails.length; i++) {
      total = total + (parseFloat(this.updateDetails[i].totalPrice) - this.updateDetails[i].bulkdiscount + (parseFloat(this.updateDetails[i].shippingCost) * this.updateDetails[i].quantity));
    }

    var newQuotes = {
      date: this.orderDetails.data.date,
      quotesName: this.orderDetails.data.quotesName,
      customerName: this.orderDetails.data.customerName,
      productList: this.updateDetails,
      totalQuoteCost: total.toFixed(2),
      LinkedDesigns: this.orderDetails.data.LinkedDesigns
    }
    var UpdatedQuotes = {
      _id: this.orderDetails._id,
      data: newQuotes,
    }
    var objQuotes = {
      quoteId: this.orderDetails._id,
      quoteData: UpdatedQuotes
    }
    this.submissionService.updateQuotes(objQuotes).subscribe(res => {
      this.orderId = res.json()._id;
      this.isEdit = false;
      this.getAllQuotesData();
      this.toastr.success('Quantity Updated Succesfully', '', { positionClass: 'toast-top-right' });
      this.loading = false;
    });
  }

  getQuantity() {
    this.quantityList = [];
    this.af.list('/quantity').snapshotChanges()
      .subscribe(data => {
        this.quantityList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.quantityList.push({ $key, ...value });
        });
        this.addMore = parseInt(this.quantityList[0].quantity2) - 1;
        this.savingDiscount = parseInt(this.quantityList[1].discount);
      });
  }

  addProduct(category, submissionId, designPngFile) {
    let dialogRef = this.dialog.open(NewQuotesModalComponent, {
      height: '750px',
      width: '900px',
      data: {
        category: undefined,
        submissionId: undefined,
        designPngFile: undefined,
        addQuotes: true,
        orderDetails: this.orderDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderId = result.json()._id;
        this.getAllQuotesData();
        this.toastr.success('Product Added Succesfully', '', { positionClass: 'toast-top-right' });
        this.loading = false;
      }
    });
  }

  deleteLinkDesign(template, i) {
    this.loading = true;
    console.log("template", template);
    console.log("template !!!!!!!!!!", i);
    if (i > -1) {
      this.orderDetails.data.LinkedDesigns.splice(i, 1);
    }
    var objQuotes = {
      quoteId: this.orderDetails._id,
      quoteData: this.orderDetails
    }
    this.submissionService.updateQuotes(objQuotes).subscribe(res => {
      this.orderId = res.json()._id;
      this.isEdit = false;
      this.getAllQuotesData();
      this.toastr.success('Deleted Linked Design Succesfully', '', { positionClass: 'toast-top-right' });
      this.loading = false;
    });



  }

  deleteProduct(a) {
    this.loading = true;
    if (a > -1) {
      this.orderDetails.data.productList.splice(a, 1);
    }
    var total = 0;
    for (var i = 0; i < this.orderDetails.data.productList.length; i++) {
      total = total + (parseFloat(this.orderDetails.data.productList[i].totalPrice) - this.orderDetails.data.productList[i].bulkdiscount + (parseFloat(this.orderDetails.data.productList[i].shippingCost) * this.orderDetails.data.productList[i].quantity));
    }
    this.orderDetails.data['totalQuoteCost'] = total.toFixed(2);
    var objQuotes = {
      quoteId: this.orderDetails._id,
      quoteData: this.orderDetails
    }
    this.submissionService.updateQuotes(objQuotes).subscribe(res => {
      this.orderId = res.json()._id;
      this.isEdit = false;
      this.getAllQuotesData();
      this.toastr.success('Deleted Product Succesfully', '', { positionClass: 'toast-top-right' });
      this.loading = false;
    });
  }

  applyFilter(ev) {
    this.loading = true;
    var statusObj = {
      quoteId: this.orderDetails._id,
      quoteStatus: ev.value
    }
    this.submissionService.changeQuoteStatus(statusObj).subscribe(res => {
      this.getAllQuotesData();
      this.loading = false;
    });
    // this.toastr.success('Deleted Product Succesfully', '', { positionClass: 'toast-top-right' });
  }



}
