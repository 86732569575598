import { Component, OnInit } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import {NgForm} from '@angular/forms';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs/Observable';
import {Router } from '@angular/router';


import 'fabric';
declare const fabric: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  email: any;
  password: any;
  user: any;
  constructor(
    private afAuth: AngularFireAuth, 
    private as: AuthService,  private router: Router){
  }

  public login(loginForm: NgForm){
    this.as.login(loginForm.value.email, loginForm.value.pass);    
  }  

  signupUser(email: string, password: string) {
    this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    .then(res => {
      if(res){
        // res.updateProfile({
        //   displayName: "ADMIN"
        //   //  photoURL: // some photo url
        // }).then(
        //   (s)=> {
        //     console.log('updated', s);
        //     // this.router.navigate(['/admin']);     
        //   }
        // )
      }
    }).catch(
     error => console.log(error)
    );
  }
   
  signinUser(email: string, password: string) {
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
    .then(res => {
      this.router.navigate(['/admin/submissions']);
    })
    .catch(
     error => console.log(error)
    );
  }
   
  logoutUser() {
     this.afAuth.auth.signOut();
     this.user = null;
  }

  onSignin(signInForm: NgForm) {
     this.email = signInForm.value.email;
     this.password = signInForm.value.pass;
    //  this.signinUser(this.email, this.password);
     this.as.signinUser(this.email, this.password); 
  }
   
   onSignup(signUpForm: NgForm) {
     this.email = signUpForm.value.email;
     this.password = signUpForm.value.pass;
     this.signupUser(this.email, this.password);
   }


}
