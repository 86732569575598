
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe }  from '@angular/common';
import { SubmissionsService } from 'services/submissions.service';
declare var Snap: any;

@Component({
    selector: 'set-quotes-dialog',
    templateUrl: 'set-quotes.component.html',
    styleUrls: ['set-quotes.component.scss'],
})
export class SetQuotesModalComponent {
    private loading: boolean = false;
    private abc:any;
    private allTemplates:any;
    private categoryName:any;
    todayDate : Date = new Date();
    myCartUser:any;
    orderProduct = [];
    linkDesigns = [];
    submission_Id:any;
 

    constructor(
        private router: Router,
        private toast: ToastrService,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<SetQuotesModalComponent>,
        private datePipe: DatePipe,
        private submissionService: SubmissionsService,
        @Inject(MAT_DIALOG_DATA) public data: any

        
    ) {
        this.af.list('/products').snapshotChanges().subscribe(styles => { 
            this.allTemplates = [];
            styles.forEach(element => {
             this.allTemplates.push(element.payload.val());
        
         });
         let current = this;
         this.categoryName = this.data.category;
         this.abc = this.allTemplates.filter(element => element.category==current.data.category); 
        });
        
    }
    ngOnInit(){
        if(this.data.customerData){

        }else{
        this.data.productList.forEach(element => {
            var orderProduct = {
                key: element.$key,
                quantity:element.quantity,
                productName:element.name,
                costPerProduct:element.price,
                totalPrice:element.totalPrice,
                productImg:element.image,
                bulkdiscount:element.tbulkDiscount,
                category:element.category,
                email:element.email,
                submissionId:element.submission_id,
                sku:element.sku,
                shippingCost:element.shippingCost,
                // design:element.design
            } 
            
            let date = this.datePipe.transform(this.todayDate, 'medium')
            if(element.design){

                if(element.design.svgfile!=undefined){
                    var linkDesigns  = {
                        type:element.design.svgfile ? 'Builder Design' :'Design Request',
                        name:'',
                        date:date,
                        svgFile:element.design.svgfile ? element.design.svgfile:'',
                        pngFile:element.design.pngFile ? element.design.pngFile:''
                    }
                    this.linkDesigns.push(linkDesigns);  
                }else{
                    var linkDesigns1  = {
                        type:'Design Request',
                        name:'',
                        date:date,
                        svgFile:'',
                        pngFile:element.design.pngFile ? element.design.pngFile:''
                    }
                    this.linkDesigns.push(linkDesigns1);  
                }

            }
            this.orderProduct.push(orderProduct);
        })
    }
    }

    addCardProduct(productName){
        this.dialogRef.close();
        productName['email'] = localStorage.getItem('emailForSignIn');
        this.af.list("/mycart").push(productName);
        this.router.navigate(['/locker-room/my-cart']); 
    }
    
    close() {
        this.dialogRef.close();
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

    createQuotes(){
        let current = this;
        var OrderName = <any>document.getElementById('orderName');
        let todayData1 = this.datePipe.transform(this.todayDate, 'medium');
        if(this.data.customerData){
            var quotesData = {
                date: todayData1,
                quotesName:OrderName.value,
                productList:[],
                totalQuoteCost:"",
                customerName: this.data.customerData.fullName,
                LinkedDesigns:[],
                email:this.data.customerData.emailAddress
            }
           var obj= {
                customerEmail: this.data.customerData.emailAddress,
                quoteData: quotesData
               }
        this.submissionService.createQuoteCustomer(obj).subscribe(res => {
        this.dialogRef.close();
        this.loading = false;
        this.router.navigate(['/admin/my-quotes-details'],{ queryParams: { quotesId: res.json()._id} })
        });
        }else{
            this.submission_Id = this.orderProduct[0].submissionId;
            let totalPrice = parseFloat(this.data.totalPrice).toFixed(2);
            var newQuotes = {
               date: todayData1,
               quotesName:OrderName.value,
               productList:this.orderProduct,
               totalQuoteCost:totalPrice,
               customerName: window.localStorage.getItem('name'),
               LinkedDesigns:this.linkDesigns
              }
              console.log("newQuotes",newQuotes);
              this.loading = true;
              this.dialogRef.close();
              this.data.productList.forEach(element => {
                this.af.list("/mycart").remove(element.$key).then(_ => {
                  });
              })
              this.submissionService.saveQuotes(newQuotes).subscribe(res => {
                  let user = JSON.parse((<any>res)._body);
                  console.log("res *****",res.json());
                  console.log("user._id",user._id);
                  var obj={
                    submissionId:this.submission_Id,
                    quoteId:user._id
                  }
                  this.submissionService.updateQuoteWithSubmission(obj).subscribe(res => {
                    this.loading = false;
                    this.router.navigate(['/locker-room/my-quotes-details'],{ queryParams: { quotesId: user._id} })
                  },error =>{
                      console.log("obj is ",obj);
                    this.loading = false;
                    this.router.navigate(['/locker-room/my-quotes-details'],{ queryParams: { quotesId: user._id} })
                  });
                }); 
        }   
    }
}


