export const INCH_SIZE_IN_PX = {
  3: "36",
  4: "50",
  6: "70",
  8: "90",
  10: "160",
  12: "180",
};

export const INCH_SIZE_IN_PX_For_NAME = {
  3:"20",
  4:"30"
  };

// Football is 10 and 12in
// Everything else is 3 and 4in

// TO DO, Vishal - need to map frontNumberSizes and backNumberSizes to red size buttons based on category selected 

export const FONT_SIZE = {
  Basketball: {
    frontNumber: INCH_SIZE_IN_PX[3],  //default Front Number Size
    backNumber: INCH_SIZE_IN_PX[4],  //default Back Number Size
    frontNumberSizes: [3,4],  // Ex.3in 4in
    backNumberSizes: [6,8],   // Ex.3in 4in
    teamName : INCH_SIZE_IN_PX_For_NAME[3],    //default Team Name Size
    playerName : INCH_SIZE_IN_PX_For_NAME[3],  //default Player Name Size
    frontTeamNameSizes:[3,4],   // Ex.3in 4in for Team Name Size
    frontPlayerNameSizes:[3,4]  // Ex.3in 4in for Player Name Size
  },
  Baseball:{
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[4],
    frontNumberSizes: [3,4],
    backNumberSizes: [6,8],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  BaseballJerseys:{
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[4],
    frontNumberSizes: [3,4],
    backNumberSizes: [6,8],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  ShootingShirts: {
    frontNumber: false,
    backNumber: INCH_SIZE_IN_PX[4],
    frontNumberSizes: [3,4],
    backNumberSizes: [4,8],
    teamName :INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  Soccer: {
    frontNumber: INCH_SIZE_IN_PX[6],
    backNumber: INCH_SIZE_IN_PX[12],
    frontNumberSizes: [4,6],
    backNumberSizes: [8,12],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  Football: {
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[4],
    frontNumberSizes: [3,4],
    backNumberSizes: [3,4],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  SidelineSuits:{
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[6],
    frontNumberSizes: [3,4],
    backNumberSizes: [6,8],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  Bags:{
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[3],
    frontNumberSizes: [3,4],
    backNumberSizes: [3,4],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  Wrestling:{
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[6],
    frontNumberSizes: [3,4],
    backNumberSizes: [6,8],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName :INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  },
  DeafultValues: {
    frontNumber: INCH_SIZE_IN_PX[3],
    backNumber: INCH_SIZE_IN_PX[6],
    frontNumberSizes: [3,4],
    backNumberSizes: [6,8],
    teamName : INCH_SIZE_IN_PX_For_NAME[3],
    playerName : INCH_SIZE_IN_PX_For_NAME[3],
    frontTeamNameSizes:[3,4],
    frontPlayerNameSizes:[3,4]
  }
};
