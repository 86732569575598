
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';
declare var Snap: any;

@Component({
    selector: 'new-quotes-dialog',
    templateUrl: 'new-quotes.component.html',
    styleUrls: ['new-quotes.component.scss'],
})
export class NewQuotesModalComponent {
    private loading: boolean = false;
    private abc:any;
    private allTemplates:any;
    private categoryName:any;
    newObjForSaveQuotes:any;
    myCartUser:any;
    loggedEmail: any;
    submissions1: any = [];

    constructor(
        private router: Router,
        private toast: ToastrService,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<NewQuotesModalComponent>,
        private submissionService: SubmissionsService,
        @Inject(MAT_DIALOG_DATA) public data: any

        
    ) {
        this.af.list('/products').snapshotChanges().subscribe(styles => { 
            this.allTemplates = [];
            styles.forEach(element => {
             this.allTemplates.push(element.payload.val());
         });
         let current = this;
         if(!this.data.category){
            this.categoryName = "All";
            this.abc = this.allTemplates;
         }else{
             if(this.data.category=='ShootingShirts'){
                this.categoryName = "Shooting Shirts";
             }else if(this.data.category=='SidelineSuits'){
                this.categoryName = "Sideline Suits";
             }else{
                this.categoryName = this.data.category;
             }
            this.abc = this.allTemplates.filter(element => element.category==current.categoryName);
         }
        });
        
    }
    ngOnInit(){
        

        console.log("this.abc",this.abc);
        // this.loggedEmail = window.localStorage.getItem('emailForSignIn');
        // this.submissionService.getAllCustomers( this.loggedEmail.trim()).subscribe(res => {
        //     this.submissions1 = [];
        //     this.submissions1 = res.json();
        //     this.submissions1 = this.submissions1.sort((a, b) => b.dateToShow - a.dateToShow);
        //     for(let item of this.submissions1){
        //         if(item._id){
        //             item.sortby =+ (item._id);
        //         }  
        //     }
        //     this.submissions1=this.submissions1.sort((a,b) => b.sortby - a.sortby);
        //   });
    }
    addCardProduct(productName){
        this.loading = true;
        if(this.data.addQuotes && this.data.addQuotes==true){
            var total = 0;
            if(this.data.orderDetails.data.email){
                productName['email'] = this.data.orderDetails.data.email
            }else if(this.data.orderDetails.data.productList){
                productName['email'] = this.data.orderDetails.data.productList[0].email
            }
            
            // productName['email'] = localStorage.getItem('emailForSignIn');
            productName['quantity'] = 1;
            let abc = 1 * parseFloat(productName.price);
            productName['totalPrice'] = abc.toFixed(2);
            productName['tbulkDiscount'] = "0.00";
            var orderProduct = {
                quantity:productName.quantity,
                productName:productName.name,
                costPerProduct:productName.price,
                totalPrice:productName.totalPrice,
                productImg:productName.image,
                bulkdiscount:productName.tbulkDiscount,
                category:productName.category,
                email:productName.email,
                sku:productName.sku,
                shippingCost:productName.shippingCost,
            }
            this.newObjForSaveQuotes = this.data.orderDetails;
            if(this.newObjForSaveQuotes.data.hasOwnProperty('productList')){
                this.newObjForSaveQuotes.data['productList'].push(orderProduct);
            }else{
                this.newObjForSaveQuotes.data['productList'].push(orderProduct);
            }
            // this.newObjForSaveQuotes.data['productList'].push(orderProduct);
            for (var i = 0; i < this.newObjForSaveQuotes.data.productList.length; i++) {
                total = total + (parseFloat(this.newObjForSaveQuotes.data.productList[i].totalPrice) - this.newObjForSaveQuotes.data.productList[i].bulkdiscount + (parseFloat(this.newObjForSaveQuotes.data.productList[i].shippingCost)*this.newObjForSaveQuotes.data.productList[i].quantity));
              }
            if(this.newObjForSaveQuotes.data.hasOwnProperty('totalQuoteCost')){
                this.newObjForSaveQuotes.data['totalQuoteCost'] = total.toFixed(2);
            }else{
                this.newObjForSaveQuotes.data['totalQuoteCost'] = total.toFixed(2);
            }
            
            var objQuotes = {
                quoteId: this.newObjForSaveQuotes._id,
                quoteData: this.newObjForSaveQuotes
              }
              this.submissionService.updateQuotes(objQuotes).subscribe(res => { 
                this.dialogRef.close(res);
              });
            

        }else{
            this.dialogRef.close();
            productName['email'] = localStorage.getItem('emailForSignIn');
            productName['quantity'] = 1;
            let abc = 1 * parseFloat(productName.price);
            productName['totalPrice'] = abc.toFixed(2);
            productName['tbulkDiscount'] = 0.00;
            if(this.data.submissionId){
                productName['submission_id'] =  this.data.submissionId;
            }
            if(this.data.designPngFile){
                var obj = null;
                    obj = {
                        svgfile:'',
                        pngFile:this.data.designPngFile
                    }
                    productName['design'] = obj;
                    console.log("productName ************************",productName);
                    this.af.list("/mycart").push(productName);
                    this.router.navigate(['/locker-room/my-cart']); 
            }else{
                console.log("this.data.submissionId",this.data.submissionId);
                if(this.data.submissionId){
                    this.submissionService.getSubmissionByID(this.data.submissionId).subscribe(res => {
                        console.log("res is ",res.json());
                        let designUrl = res.json().submission;
                        var obj = null;
                        obj = {
                            svgfile:designUrl.svgFile,
                            pngFile:designUrl.pngFile
                        }
                        productName['design'] = obj;
                        console.log("productName #########################",productName);
                        this.af.list("/mycart").push(productName);
                        this.router.navigate(['/locker-room/my-cart']); 
                      },error =>{
                        console.log("error is ",error);
                      });
                }else{
                    this.af.list("/mycart").push(productName);
                    this.router.navigate(['/locker-room/my-cart']); 
                }

        }
        
            
        }
        // this.submissionService.saveMyCart(obj).subscribe(res => {
        //     console.log("res is ",res.json())
        //   },error =>{
        //     console.log("error is ",error);
        //   });
        
        // console.log("productName !!!!!!",productName);
        // var obj  = {
        //     cartData:{
        //         anyData:productName
        //     }
        // }
        // this.submissionService.saveMyCart(obj).subscribe(res => {
        //     console.log("res is ",res.json())
        //   },error =>{
        //     console.log("error is ",error);
        //   });
        
    }
    
    close() {
        this.dialogRef.close();
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }
    

}


