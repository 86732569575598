import {Component, OnInit,EventEmitter,Output,Input} from "@angular/core";
import {EditorComponent} from "../editor/editor.component";
import { MatDialog } from "@angular/material";
import { SignInModalComponent } from "app/modals/sign-in/sign-in.component";
import { SubmissionsService } from 'services/submissions.service';
@Component({
  selector: 'home',
  templateUrl: '../../app/dashboard/dashboard.component.html',
  styleUrls: ['../../app/editor/editor.component.css','../../app/dashboard/dashboard.component.scss']  
})
export class HomeComponent 
{
  // @Input() colorsList : any[];
  @Input() uniform : any;
  @Input() myLogo : any;

  @Output() onclicksutemplate :EventEmitter<any> = new EventEmitter(); 
  // isExpaned: boolean;
  builderConfiguration:any;
  constructor(private dialog: MatDialog,
    private submissionService: SubmissionsService) {
    
  }

  ngOnInit() {
      // this.isExpaned = true; 
      this.submissionService.getConfiguration().subscribe(res => {
        this.builderConfiguration = res.json();
        //  console.log(this.builderConfiguration[0]);
     
         
         
       });  
  }

  toggleUniformSelect(){
    this.onclicksutemplate.emit(true);
  }

  signInForUpload(redirected){
    this.dialog.open(SignInModalComponent, {
       data: {
         redirected:redirected
     }
  })
  }

  myLocker(){
    alert("Navigate to my locker")
  }

  // getConfiguration

  

}