import { AfterViewChecked, ElementRef, ViewChild,Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SetDesignNameModalComponent } from './set-design/set-design.component';


@Component({
  selector: 'app-customer-my-design-details',
  templateUrl: './my-design-details.component.html',
  styleUrls: ['./my-design-details.component.scss']
})

export class AdminMyDesignRequestsDetailsComponent implements OnInit {
  
  requestdesignList = [];
  colorArray = [];
  private loading1:boolean = false;
  displayedColumns: string[] = ['Design', 'Date', 'Actions'];
  private loading:boolean = false;
  private  previewRequest:boolean = false;
  designId:any;
  name:any;
  arrayOfName = [];
  initails:any;
  file:any;
  id:any;
  
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private router: Router,
    public dialog: MatDialog,

    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    if(window.location.href.split('?designId=')[1]){
      this.designId = window.location.href.split('?designId=')[1];
    }
    this.name = window.localStorage.getItem('name');
    var initials = this.name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    this.initails = initials;
    this.file = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/upload-design-chat%2FDesignImage1596621882037?alt=media&token=5c6d5642-2459-41fd-9bd2-3157c6e0485e"
   
    this.id = setInterval(()=>{
      this.getdataOfDesign();
      }, 5000);
    this.getdataOfDesign();
    setTimeout(() => {
      document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
    }, 1500);
    
  
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  
  addAttachment(){
    document.getElementById('attch').click();
  }

  previewReques(){
    this.previewRequest = true;
  }
  backdesignRequest(){
    this.previewRequest = false;
  }

  getdataOfDesign(){

    this.submissionService.getRequestDesignById(this.designId).subscribe(res => {
      this.requestdesignList = res.json();
      console.log("this.requestdesignList",this.requestdesignList);
    },error =>{
        console.log("error is ",error);
      });
  }

  onOpenMenu(menu: any): void {
  }

  actionOrder(action,element){

  }

  sendMessage(){
    let massage = <any>document.getElementById('send');
    var messageObject = {
      requestId:this.designId,
      messageText:massage.value
    }
    this.submissionService.sendMessageAsAdmin(messageObject).subscribe(res => {
      massage.value = "";
      this.getdataOfDesign();
      document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
      // document.getElementsByTagName('body')[0].setAttribute('style','overflow: hidden');
    },error =>{
        console.log("error is ",error);
      });
  }

 

  uploadDesign(){
    let dialogRef = this.dialog.open(SetDesignNameModalComponent, {
      width: '500px',
      data: {
          requestID:this.designId
        // category:this.category
      }
    });
    dialogRef.afterClosed().subscribe(result => { // New line
      document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0,document.getElementsByTagName('mat-sidenav-content')[0].scrollHeight);
      // console.log("result is @@@@@",result);
      // this.colorArray = [];
      // this.colorArray.push(result);
      // console.log("this.colorArray",this.colorArray);
      // setTimeout(() => {
      //   this.loading1 = true;
      // }, 1500);
     
      this.colorArray = result; // New line. Need to declare answerFromModel in class
    });
  }

  goToLink(url: string){
    window.open(url, "_blank");
   }

   openNewTab(url: string){
    window.open(url, "_blank");
   }

   radioChange(event) {
     
    // console.log("111111",event.value);
}

goToQuotesDetail(id){
  this.router.navigate(['/admin/my-quotes-details'],{ queryParams: { quotesId:id} })
}
}
