import { Component, OnInit } from '@angular/core';
import {MatListModule} from '@angular/material/list'; 
import {MatRadioModule} from '@angular/material'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//var stripe = Stripe('pk_test_nLrrHcSkMdSrYuShrWjKnrh300ojpzD');
// import { NgXCreditCardsModule } from 'ngx-credit-cards';
@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css']
})
export class AddCardComponent implements OnInit {
  allAddresses = [
  					{
  						"id" : 1,
  						"name" : "Mike Smith",
  						"address" : "1515 Center St San Jose, Ca, 95121 United States"
  					},
  					{
  						"id" : 2,
  						"name" : "Gabe Rosa",
  						"address" : "123 Main St San Jose, Ca, 95111 United States",
  					}
				 ];
 selectedAddress;
  constructor(
  				public dialogRef: MatDialogRef<AddCardComponent>,
  				public dialog: MatDialog,
  				private toastr: ToastrService) { }

  ngOnInit() {
  }

  // handleAddressChange(evt){
  // 	var target = evt.value;
  // 	this.selectedAddress = target;
  // 	console.log(target);
  // }

  // addNewAddress(): void {
  //   this.dialogRef.close();
  //   const dialogRef = this.dialog.open(AddShippingAddressComponent, {
  //     //width: '650px',
  //     data: {}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });
	// }
	
	close(){
		this.dialogRef.close();
	}

  selectAddress(){
  	if(this.selectedAddress != undefined){
  		this.toastr.success('Hello world!', 'Toastr fun!');
  	}else{
  		this.toastr.error('Hello world!', 'Toastr fun!');
  	}
  }
}
