import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { SubmissionsService } from 'services/submissions.service';
import { ShopifyService } from 'services/shopify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AddProductModalComponent } from 'app/admin/manage-product/add-product/add-product-component';

export interface Submission{
  name: string;
  category: Date;
  price: string;
}

@Component({
  selector: 'app-manage-product',
  templateUrl: './manage-product.component.html',
  styleUrls: ['./manage-product.component.scss']
})
export class ManageProductComponent implements OnInit {

  private productList = [];
  private packageList = [];
  private shopifyList = [];
  private Category = [];
  private fontList = [];
  private editing: boolean = false;
  private loading: boolean = false;
  catList = [];
  dataSource1:any;
  dataSource2:any;
  dataSource3:any;
  order_status:any;
  pkgStatus:any

  submissionsTable: Submission[] = [];
  employeeInfoTableDataSource = new MatTableDataSource(this.submissionsTable);
  displayedColumns: string[] = ['SKU', 'Name', 'Category', 'Created', 'Modified','Actions'];
  displayedColumns1:string[] = ['SKU', 'Name', 'Category', 'Created', 'Modified','Actions'];
  displayedColumns3:string[] = ['Image', 'Title', 'Variant Count', 'Price'];
  constructor(private subService: SubmissionsService,
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService,
    private dialog: MatDialog,
    private shopifyService: ShopifyService,
  ) {
  }

  ngOnInit() {
    this.auth.currentUser();
    let email = localStorage.getItem('emailForSignIn');
    if (!email) {
      localStorage.clear();
      if (localStorage.getItem('userrole') == 'Admin')
        this.router.navigate(['/admin/login']);
      else
        this.router.navigate(['/customer/login']);
    }
    this.startLoadingSpinner();
    this.getProducts();
    this.getAllCategory();
   this.getShopifyProduct();
  }
  getAllCategory(){
    this.af.list('/template-categories').snapshotChanges().subscribe(data => {
      this.catList = [];
      data.forEach(action => {
        const value = action.payload.val();
        console.log('value',value);
        // ShootingShirts
        // if(value['name']=='Shooting Shirts'){
        //   let newCat = {
        //     key: action.key,
        //     name: 'ShootingShirts',
        //     nameNoSpace: value['nameNoSpace'],
        //     image:value['image']
        //   }
        //   this.catList.push(newCat);
        // }else{
          let newCat = {
            key: action.key,
            name: value['name'],
            nameNoSpace: value['nameNoSpace'],
            image:value['image']
          }
          this.catList.push(newCat);
        // }
      });
      console.log(this.catList);
    })
  }
  getProducts() {
    this.productList = [];
    this.packageList = [];
    this.af.list('/products').snapshotChanges()
      .subscribe(data => {
        this.productList = [];
        this.packageList = [];
        this.Category = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          if(value['isPackage']==undefined || value['isPackage']=='undefined' || value['isPackage']=='false' || value['isPackage']==false){
            this.productList.push({ $key, ...value });
          }else{
            this.packageList.push({ $key, ...value });
          }
          
        });
        this.employeeInfoTableDataSource.data = this.productList;
        this.productList.forEach(element => {
          this.Category.push(element.category);
        });
        this.Category = this.Category.filter( this.onlyUnique );
        this.loading = false;
        this.dataSource1 = new MatTableDataSource(this.productList);
        this.dataSource2 = new MatTableDataSource(this.packageList);
        console.log("this.productList",this.productList);
      });


  }
onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}
  

  add(flag){
    let dialogRef = this.dialog.open(AddProductModalComponent, {
      height: '400px',
      width: '600px',
      data: flag
    });
  }


 
  addNewProduct(sku, name, category) {
    if (!sku || !name || !category) return;
    var newProduct = {
      sku: sku,
      name: name,
      category: category,
      createdAt:new Date().getTime(),
      updatedAt:new Date().getTime(),
    }
    this.af.list("/products").push(newProduct);
    this.getProducts();
  }
  removeProduct(product) {
    var a = confirm("Are you sure you want to remove this product?")
    if (a) {
      this.af.list("/products").remove(product.$key).then(_ => {
        this.getProducts();
      });
    }
  }
  toggleEditing(product) {
    if (product.editing) {
      product.editing = false;
    } else {
      product.editing = true;
    }
  }
  saveChanges(product) {
    product.editing = false;
    alert(JSON.stringify(product.$key));
    let key = product.$key;
    delete product.$key;
    product.updatedAt=new Date().getTime();
    let update = this.af.object(`/products/${key}`).update(product);
    this.getProducts();
  }

  edit(product){
    let dialogRef = this.dialog.open(AddProductModalComponent, {
      height: '400px',
      width: '600px',
      data: product
    });
  }
  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }

  createdate(date){
    if(date){
      return new Date(date);
    }
  }
  applyFilter(ev) {
    let filterValue = ev.value;
    this.order_status = ev.value;
    this.dataSource1.filter = filterValue;
    this.productList = this.dataSource1;
  }

  applyFilter1(ev){
    let filterValue = ev.value;
    this.pkgStatus = ev.value;
    this.dataSource2.filter = filterValue;
    this.packageList = this.dataSource2;
  }
  
  getShopifyProduct(){
   this.shopifyService.getAllShopifyProduct().subscribe(data => {
    this.shopifyList = [];
      this.shopifyList = data.json();
   });
  }
  
}
