import { Injectable } from '@angular/core';
// import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
// import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import {Http, Headers,URLSearchParams,Response,RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { env } from 'process';

@Injectable()
export class ShopifyService {
  // BASE_URL = process.env.NODE_API_URL;
  BASE_URL = "https://uniform-lab-node.herokuapp.com/";
  public data: any;
  constructor(private http:Http){
  }
  //Call Shopify Product
  getAllShopifyProduct(){
    let headers = new Headers({ 'Accept': 'application/xml','Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}shopify/products`,{headers:headers});
  }
  //get All Collections
  getAllCollection(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}shopify/collections`,{headers:headers});
  }

  //get All Collections
  saveCheckCollection(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}shopify/collections/save`,obj,{headers:headers});
  }

  //change drop down for shopify products
  filterByTitleShopify(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}shopify/products/collection`,obj,{headers:headers});
  }

  //get by Id shopify product
  getByIdProducts(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}shopify/products/${id}`,{headers:headers});
  }
 
}
