import { Component, OnInit } from '@angular/core';
import { CustomerNavComponent } from 'app/customer-admin/customer-nav/customer-nav.component';

@Component({
  selector: 'app-customer-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class CustomerSupportComponent implements OnInit {

  constructor(private nav: CustomerNavComponent) { }

  myEmail = ''
  pointOfContact = ''
  myPhoneNumber = ''
  ngOnInit() {
    if(this.nav.businessInfoRef != null){
      this.myEmail = this.nav.businessInfoRef['csEmail'];
      this.pointOfContact = `${this.nav.businessInfoRef['firstName']} ${this.nav.businessInfoRef['lastName']}`
      this.myPhoneNumber = this.nav.businessInfoRef['csPhoneNumber']
    } 
    this.nav.businessInfo.subscribe(res => {
        this.myEmail = res['csEmail'];
        this.myPhoneNumber = res['csPhoneNumber']
        this.pointOfContact = `${res['firstName']} ${res['lastName']}`
    })
  }

}
