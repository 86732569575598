import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
// import { ColorPickerService } from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
// import { FirebaseApp } from 'angularfire2';
import { AngularFireDatabase } from 'angularfire2/database';
import { LayerObj, Ilayer, ViewLayerData, IViewlayer } from '../../models/jersey';
import { SubmissionsService } from '../../../services/submissions.service';
import { UniformStyle, Jersey, JerseyObj, reversible } from '../../admin/models/upload'
import { Observable } from "rxjs/Rx"
import { LayersService } from '../../../services/layers.service';
import { styleService } from 'services/style.service';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';


import 'fabric';
import { last } from 'rxjs/operator/last';
// import { RadioControlValueAccessor } from '@angular/forms/src/directives/radio_control_value_accessor';
import { debounce } from 'rxjs/operator/debounce';
import { visitValue } from '@angular/compiler/src/util';
import { AuthService } from 'services/auth.service';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { async } from '@angular/core/testing';

declare const fabric: any;

@Component({
  selector: 'style-detail',
  templateUrl: './style-detail.component.html',
  styleUrls: ['./style-detail.component.css']
})

export class StyleDetailComponent {

  private isChecked = true;
  private viewlayers: ViewLayerData;
  private canvas: any;
  private lowerImages = "";
  private selectedImage = "";
  private selected: any;
  styleNameToShow:any;
  private props: any = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };
  editRosterMode: boolean = false;
  selectedThumbnails: any

  public typeList = [
    { "id": 1 },
    { "id": 2 }
  ];

  private size: any = {
    width: 0,
    height: 0
  };

  private json: any;
  private globalEditor: boolean = false;
  private textEditor: boolean = false;
  private imageEditor: boolean = false;
  private figureEditor: boolean = false;

  private selectedJersycut: Jersey;
  private hasCuts: boolean;
  private editableButton: boolean = false;
  private selectedStylecut: Jersey;
  private selectedJersyStyleCuts: Jersey[] = [];
  private selectedStyle: Jersey;
  private selectedCutsList: Jersey[] = [];
  private selectedskuList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private selectedsku: any;
  skuListToShow: any;
  selectedSkuFromList: any;
  private name: string;
  private category: string;
  private gender: string;

  private price: number;
  private jerseyStyleList: any;
  private currentCut: string = "";

  private allLayersArray: Observable<any>[];
  private layerIdList: LayerObj[];

  private colorsSelected: boolean = false;
  private textSelected: boolean = false;
  private logoSelected: boolean = false;
  private introSelected: boolean = true;
  private selStyle: UniformStyle
  private ddselectedCutType: Jersey;
  public selectedCutType: any;
  public selectskunumber: any;
  private loading: boolean = false;
  stylename: any;
  public reversible: reversible[] = [
    { "id": 1, "name": "Outside" },
    { "id": 2, "name": "Inside" }
  ];

  styleSkuList = [];
  listToDiffeName = [];

  currentSelectedStyle:any; 
  cutStyle:any;
  selectedDropdown:any = 0;
  styles:any;

  public constructor(private route: ActivatedRoute, private router: Router,
    private db: AngularFireDatabase,
    private _styleService: styleService, private auth: AuthService,
    private toastr: ToastrService,

  ) {
  }
  catList = []
  ngOnInit() {
    this.getData();
    this.db.list('/template-categories').snapshotChanges().subscribe(data => {
      this.catList = [];
      data.forEach(action => {
        const value = action.payload.val();
        let newCat = {
          key: action.key,
          name: value['name'],
          nameNoSpace: value['nameNoSpace'],
        }
        this.catList.push(newCat);
      });
    })
    
  }

  async getData() {
    this.auth.currentUser();
    let email = localStorage.getItem('emailForSignIn');
    // let emailtocheck = localStorage.getItem('emailtocheck');
    // if (email != emailtocheck) {
      if(!email){
      if (localStorage.getItem('userrole') == 'Admin')
        this.router.navigate(['/admin/login']);
      else
        this.router.navigate(['/customer/login']);
      localStorage.clear();
      // }
    }
    this._styleService.selStyle$.subscribe(
      async (data) => {
        if (data === null)
          this.router.navigate(['admin/manage-templates']);
        else
          this.selStyle = data;
        this.stylename = this.selStyle.name;
        this.styleNameToShow=this.capitalletter(this.selStyle.name);
        await this.db.object('/jersey-styles/' + this.selStyle.name).valueChanges().subscribe(stytempla => {
          let data = <any>stytempla;
          this.currentSelectedStyle = data;
          this.styles=this.currentSelectedStyle.files[0];
          if (!this.currentSelectedStyle.skuList)
            this.currentSelectedStyle.skuList = [];
          this.selectedSkuFromList = this.currentSelectedStyle.skuList[0];
          if (data.skuList)
            this.skuListToShow = data.skuList;
          else
            this.skuListToShow = undefined;
          this.editRosterMode = false;
          this.allData();
        });

      });

  }

  isEditingCat = false; 
  editCat(){
    this.isEditingCat = !this.isEditingCat; 
  }

  copyToClipbord(){
    var Url = document.getElementById("templateUrl") as HTMLInputElement;
    Url.select();
    document.execCommand("Copy");
    }



  capitalletter(data){
    return data.charAt(0).toUpperCase() + data.slice(1);
  }
  allData() {
    this.canvas = new fabric.Canvas('canvasElement', {
      hoverCursor: 'pointer',
      selection: true,
      selectionBorderColor: 'blue'
    });
    this.canvas.on({
      'object:moving': (e) => { },
      'object:modified': (e) => { },
      'object:selected': (e) => {

        let selectedObject = e.target;
        this.selected = selectedObject;
        selectedObject.hasRotatingPoint = true;
        selectedObject.transparentCorners = false;
        this.resetPanels();

        if (selectedObject.type !== 'group' && selectedObject) {

          this.getId();
          this.getOpacity();

          switch (selectedObject.type) {
            case 'rect':
            case 'circle':
            case 'triangle':
              this.figureEditor = true;
              this.getFill();
              break;
            case 'i-text':
              this.textEditor = true;
              this.getLineHeight();
              this.getCharSpacing();
              this.getBold();
              this.getFontStyle();
              this.getFill();
              this.getTextDecoration();
              this.getTextAlign();
              this.getFontFamily();
              this.colorsSelected = false;
              this.textSelected = true;
              this.logoSelected = false;
              this.introSelected = false;
              break;
            case 'image':
              console.log('image');
              break;
            case 'path-group':
              break;
          }
        }
      },
      'selection:cleared': (e) => {
        this.selected = null;
        this.resetPanels();
      }
    });

    this.size.width = document.getElementById('canvasOuter1').clientWidth;
    this.size.height = document.getElementById('canvasOuter1').clientHeight - 50;
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);


    //Fire base Calling
    this.startLoadingSpinner();
    this.db.list('/jersey-styles').valueChanges().subscribe(stytempla => {
      this.jerseyStyleList = stytempla;
      this.listToDiffeName=[];
      this.selectedCutsList = this.getSelectedStyleCuts();
      this.selectedStyle = this.selectedCutsList[0];
      this.selectedCutType = this.selectedCutsList[0];
      this.loading = false;
      this.bindCanvas();
      if (this.selectedJersycut) {
        this.addSvgJersey('', this.selectedJersycut.img_url);
      }
    });

  }


  getActiveProp(name) {
    var object = this.canvas.getActiveObject();
    if (!object) return '';
    return object[name] || '';
  }
  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }
  getFontFamily() {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }
  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration', null);
  }
  getFontStyle() {
    this.props.fontStyle = this.getActiveStyle('fontStyle', null);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }
  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight', null);
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  setId() {
    let val = this.props.id;
    let complete = this.canvas.getActiveObject().toObject();
    this.canvas.getActiveObject().toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  setActiveStyle(styleName, value, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    }
    else {
      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle('opacity', null) * 100;
  }

  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.props.opacity) / 100, null);
  }

  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }

  setFill() {
    this.setActiveStyle('fill', this.props.fill, null);
  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }

  setLineHeight() {
    this.setActiveStyle('lineHeight', parseFloat(this.props.lineHeight), null);
  }


  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
  }

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return '';

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  // Returns All Cut Types of Selected Jersey from Manage - Styles
  getSelectedStyleCuts(): Jersey[] {
    let filJersyStyles: Jersey[] = [];
    let self = this;
    this.jerseyStyleList.forEach(element => {
      if (element.category === this.selStyle.category && element.name == this.selStyle.name && element.gender == this.selStyle.gender) {
        if (element.files.length >= 1) {
          self.hasCuts = true;
        }
        for (let count = 0; count < element.files.length; count++) {
          let curJerseyStyle: Jersey = new JerseyObj(element.files[count].cutType + "-" + element.files[count].layoutType, element.gender, element.name, element.display_Name, element.files[count].img_url, null, element.files[count].cutType, element.files[count].layoutType, element.thumbnail_url, element.sku_number, element.product_id, element.jersey_sku, element.shorts_sku);
          filJersyStyles.push(curJerseyStyle);
          this.listToDiffeName.push(element.files[count].uniformType);
        }
      }
    })
    return filJersyStyles;
  }


  bindCanvas() {
    this.selectedJersycut = this.selectedStyle;
  }

  onChangeCutType(newObj: Jersey) {
    let index = this.selectedCutsList.findIndex(item => item.category === newObj.category);
    this.selectedDropdown = index;
    if(this.selectedDropdown){
      this.styles = this.currentSelectedStyle.files[this.selectedDropdown];
    }else{
      this.styles = this.currentSelectedStyle.files[0];
    } 
    this.ddselectedCutType = newObj;
    this.selectedJersycut = this.ddselectedCutType;
    this.selectedStyle = this.ddselectedCutType;
    this.addSvgJersey('', this.selectedJersycut.img_url);
  }



  // Add Jersey Svg
  addSvgJersey(event: any, src: any) {
    let __temp: any;
    let can = this.canvas;
    let that = this;
    this.layerIdList = this.getEditableLayers(this.selectedJersycut);
    let el;
    if (src) {
      el = src;
    }

    this.canvas.clear();
    var group = [];
    fabric.loadSVGFromURL(el, function (objects, options) {
      var loadedObjects = new fabric.util.groupSVGElements(objects, options);
      let h = loadedObjects.height;
      let w = loadedObjects.width;
      let r = (h > w) ? (w / h) : (h / w);
      let nh = (h > w) ? 700 : (700 * r);
      let nw = (h > w) ? (800 * r) : 800;
      loadedObjects.set({
        top: can.height / 2,
        left: can.width / 2,
        scaleX: 0.69,
        scaleY: 0.69,
        originX: 'center',
        originY: 'center',
        class: 'svg',
        src: el,
        selectable: false,
      });

      can.add(loadedObjects);
      loadedObjects.setCoords();
      can.renderAll();

    }, function (item, object) {
      object.set('id', item.getAttribute('id'));

      group.push(object);
      this.allLayersArray = group;

      if (this.allLayersArray) {
        this._self = this.allLayersArray;
        callgetLayerList(this._self);

      }//array of layer objects, need to show in ngFor in front end
    }, callgetLayerList);

    let _t = this;
    function callgetLayerList(curLayer) {
      _t.getLayerList(curLayer);
    }
  }

  getLayerList(Obj: any) {
    if ((Obj[Obj.length - 1].id)) {
      if (this.layerIdList.filter(function (e) { return e.layerID == Obj[Obj.length - 1].id }).length == 0) {
        var layer = new LayerObj(Obj[Obj.length - 1].id, "", false);
        this.layerIdList.push(layer);
      }
    }
  }

  svgLoad(item){
    console.log("item is ",item);
    if(item){
      if(item.includes('.svg')){
        var oContentArea = document.getElementById("contentarea");
        if(oContentArea != null ){
          oContentArea.setAttribute('data', item);
        }
        return true;
      }else{
        return false;
      }
    }
  }

  //check for availability of Layer Data of selected view - for two way binding
  getEditableLayers(jerseyStyle: Jersey) {
    let curViewLayers = [];
    this.jerseyStyleList.forEach(element => {
      let _jerseystyle = (element.name && element.name === jerseyStyle.name);
      let layerinfo: string = "Layerinfo";

      if (_jerseystyle && element.Layers) {
        var layername = jerseyStyle.category;
        var layersObj = element.Layers[layername];
        if (layersObj) {
          var layerlist = layersObj.Layerinfo.layerdata;

          if (layerlist) {
            layerlist.forEach(layer => {
              if (layer.IsEditable)
                curViewLayers.push(layer);
            });
            return curViewLayers;     //retuns Layers immediately after storing particualr view of Jersey Style and ignored remaining jersey-styles
          }
          else
            return [];
        }
      }
      else
        return [];
    });
    return curViewLayers;
  }

  newCategory: string;
  
  changedCat(value){
    alert(value)
  }
  removeSkuNumber(index) {
    this.currentSelectedStyle.skuList.splice(index, 1);
  }

  addSkuNumber() {
    let skuNum = (document.getElementById("SkuNumber") as HTMLInputElement).value;
    if (skuNum != '' && skuNum != undefined) {
      this.currentSelectedStyle.skuList.push(skuNum);
      let v=<any>document.getElementById("SkuNumber");
      v.value='';
    }
  }

  // saves basic product info
  saveChanges(){ 
    if(this.newCategory != undefined)
      this.currentSelectedStyle['category'] = this.newCategory;
    const jerseyList = this.db.list('/jersey-styles');
    this.isEditingCat = false;
    jerseyList.update(this.currentSelectedStyle.name, this.currentSelectedStyle);
    this.toastr.success('Updated template details', 'Success', { timeOut: 7000,positionClass : 'toast-top-right' });
  }

  //Save Layer Editable Data in Fire base
  saveLayerEditableData() {
    this.viewlayers = new ViewLayerData(this.layerIdList, this.selectedJersycut.category);
    const jerseyList = this.db.list('/jersey-styles');
    jerseyList.update(this.selectedJersycut.name + '/Layers/' + this.selectedJersycut.category, {
      Layerinfo: this.viewlayers
    });
  }


  startLoadingSpinner() {

    this.loading = true;
    setTimeout(function () {
      // this.loading = false;
    }.bind(this), 1500);
  }


  cancelSkuBox() {
    this.editRosterMode = false;

    this.selectedsku = undefined;
    // this.selectedskuList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let file = <any>document.getElementById('jerseystyle11');
    file.value=null;
  }

  async  submitTeamRosterFormdata(data) {

    let skuNumbers = [];
    data._directives.forEach(element => {
      skuNumbers.push(element.model)
    });

    const jerseyList = await this.db.list('/jersey-styles');
    let upload = await jerseyList.update('/' + this.stylename, {
      skuList: skuNumbers
    });
    this.getData();
    this.editRosterMode = false;
    let file = <any>document.getElementById('jerseystyle11');
    file.value=null;
  }


  editRosterDetail() {
    this.editRosterMode = true;
    this.selectedsku = [];
    this.skuListToShow.forEach(element => {
      this.selectedsku.push({
        name: element
      });
    });

  }


  deleteSkuNumber(index) {
    if (confirm("Are you sure you want to delete this SKU Number?")) {
      this.selectedsku.splice(index, 1);
    }
  }


  onChangeskuType(number) {
    this.editRosterMode = true;
    let selectedskuList1 = [
      { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }];
    this.selectedsku = [];
    if (this.skuListToShow && this.skuListToShow.length > 0) {
      this.skuListToShow.forEach(element => {
        this.selectedsku.push({
          name: element
        });
      });
      let tempArray = selectedskuList1.splice(0, number);
      this.selectedsku = this.selectedsku.concat(tempArray)
    } else {
      this.selectedsku = selectedskuList1.splice(0, number);
    }
  }


  detectThumbnail(event, layoutView) {
    this.selectedThumbnails = event.target.files[0];
  }

  uploadFile() {
    if (this.selectedThumbnails) {
      // this.uploadLogo(this.selectedThumbnails);
    }
    else
      this.selectedThumbnails = undefined;
  }

  inputFile(){
    document.getElementById('uploadFile').click();
  }
  inputFile1(){
    document.getElementById('uploadFile1').click();
  }
 
  async uploadImage1(event){
    let file = event.target.files[0];
    let storageRef = firebase.storage().ref();
    let ref = storageRef.child(`${this.basePath}/${this.currentSelectedStyle.name}`)
    var dbRef = this.db.object(`${this.basePath}/${this.currentSelectedStyle.name}/`);
    this.startLoadingSpinner();
      storageRef.child(`${'/jersey-styles'}/${this.currentSelectedStyle.name}/${file.name}`).put(file)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
        }).then(downloadURL => {
          var downloadPath = downloadURL;
          this.loading = false;
          console.log("this.selectedDropdown is ",this.selectedDropdown);
          // let a =(document.getElementById("jerseystyle") as HTMLInputElement).value.split(':');
          delete this.currentSelectedStyle.files[this.selectedDropdown].img_url;
          if(this.currentSelectedStyle.files[this.selectedDropdown].hasOwnProperty('img_url')){
            this.currentSelectedStyle.files[this.selectedDropdown].img_url = downloadPath;
          }else{
            this.currentSelectedStyle.files[this.selectedDropdown]['img_url'] = downloadPath;
          }
          dbRef.set(this.currentSelectedStyle);
          this.toastr.success('Cut Style Image updated successfully', '', { timeOut: 2000 ,positionClass : 'toast-top-right'});
          let file = <any>document.getElementById('uploadFile1');
          file.value = null;
          // this.selectedDropdown = null;
        })
        .catch(error => {
          // Use to signal error if something goes wrong.
          this.loading = false;
          console.log(`Failed to upload file and get link - ${error}`);
          this.toastr.error('Failed to upload file', '', { timeOut: 7000 ,positionClass : 'toast-top-right'});
        });
  }

  async uploadImage(event) {
    let file = event.target.files[0];
    console.log(file);
    let storageRef = firebase.storage().ref();
    this.startLoadingSpinner();
    storageRef.child(`${'/jersey-styles'}/${this.stylename}/thumbnail_url`).put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
      }).then(downloadURL => {
        this.selectedThumbnails = undefined;
        this.currentSelectedStyle.thumbnail_url = downloadURL
        this.saveChanges();
        this.loading = false;
        this.toastr.success('Thumbnail Image updated successfully', '', { timeOut: 2000 ,positionClass : 'toast-top-right'});
        let file = <any>document.getElementById('fileFront');
        file.value = null;
      })
      .catch(error => {
        this.selectedThumbnails = undefined;
        this.loading = false;
        console.log(`Failed to upload file and get link - ${error}`);
        this.toastr.error('Failed to upload file. Please try again', '', { timeOut: 4000,positionClass : 'toast-top-right' });

      });

  }

  basePath: string = '/jersey-styles';

}
