
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AngularFireDatabase } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { DatePipe }  from '@angular/common';
declare var Snap: any;

@Component({
    selector: 'set-color-redesign-dialog',
    templateUrl: 'set-color-redesign.component.html',
    styleUrls: ['set-color-redesign.component.scss'],
})
export class SetColorReqDesignModalComponent {
    private loading: boolean = false;
    private loading1: boolean = false;
    private abc:any;
    private allTemplates:any;
    private categoryName:any;
    todayDate : Date = new Date();
    myCartUser:any;
    orderProduct = [];
    colorsList = [];
    selectedColors = [];
    abc1  = [];

    constructor(
        private router: Router,
        private toast: ToastrService,
        private af: AngularFireDatabase,
        public dialogRef: MatDialogRef<SetColorReqDesignModalComponent>,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any

        
    ) {
        
    }
    ngOnInit(){
        // getColor() {
            this.selectedColors = [];
            // this.selectedColors = this.data.data;
            this.colorsList = [];
            this.af.list('/colors').snapshotChanges()
              .subscribe(data => {
                this.colorsList = [];
                data.forEach(action => {
                  const value = action.payload.val();
                  const $key = action.key;
                  this.colorsList.push({ $key, ...value });
         
                });
                this.colorsList.sort((a, b) => a.order - b.order);
                this.loading = false;
                

                setTimeout(() => {
                    for(var i=0;i<=this.data.color.length;i++){
                        for( var j=0;j<=this.colorsList.length;j++){
                            if(this.data.color[i] && this.colorsList[j]){
                             if(this.data.color[i].colorName==this.colorsList[j].colorName)
                             {
                                document.getElementById('numberIcon-'+j).style.display  = "block";
                                this.selectedColors  = [];
                                this.selectedColors = this.data.color;
                             }
                            }
                        }
                     }
                }, 500);
              });

              
           

        
        
        //   }
    }

    
    close() {
        this.dialogRef.close();
    }

    startLoadingSpinner() {
        this.loading = true;
        setTimeout(function () {
        }.bind(this), 1500);
    }

    saveColors(color,i){
        let a= <any>document.getElementById('numberIcon-'+i);
        if(a.style.display=="none"){
            a.style.display= "block";
            this.loading1 = true;
            this.selectedColors.push(color);
        }else{
            a.style.display="none";
            this.selectedColors.splice(this.selectedColors.findIndex(item => item.colorName === color.colorName), 1)
        }  
    }
    
    saveColorData(){
        let abc = <any>document.getElementById('selectColor');
        abc.style.display = "none";
        let abc1 = <any>document.getElementById('editColor');
        abc1.style.display = "block";
        this.dialogRef.close(this.selectedColors);
        // this.dialogRef.close({ this.selectedColors });
        // const queryParams: any = {};
        // queryParams.myArray = JSON.stringify(this.selectedColors);
        // const navigationExtras: NavigationExtras = {
        //     queryParams
        //   };
        // this.router.navigate(['/locker-room/request-design'],navigationExtras);
    }

    createOrder(){
        let current = this;
        var OrderName = <any>document.getElementById('orderName');
        let todayData1 = this.datePipe.transform(this.todayDate, 'medium');
        var newOrder = {
            orderName: OrderName.value,
            orderNumber: Math.floor(100000 + Math.random() * 900000),
            orderTotal: current.data.totalPrice,
            dateCreated : todayData1,
            products:this.orderProduct
          }
        this.af.list("/order").push(newOrder);
        this.dialogRef.close();
        this.router.navigate(['/locker-room/order-details'],{ queryParams: { orderId: newOrder.orderNumber} })
        // this.router.navigate(['/locker-room/order']);
        // create an order object, save to database, then navigate to order details view 
    }
    

}


